import "./style.css";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import backArrow from "../../assets/images/back-arrow.svg";
import profileImg from "../../assets/images/UserCirclePlus.png";
import modalCloseImg from "../../assets/images/modal-close.svg";
import progressbarImg from "../../assets/images/progressbar.svg";
import orengeRightArrowImg from "../../assets/images/orengeRightArrow.svg";
import familyImg from "../../assets/images/family.svg";
import greyRightArrowImg from "../../assets/images/greyRightArrow.svg";
import yourBookedPujasImg from "../../assets/images/your-booked-pujas.svg";
import watchRecordingsImg from "../../assets/images/watch-recordings.svg";
import omImg from "../../assets/images/om.svg";
import addressBookImg from "../../assets/images/address-book.svg";
import sevaKartaImg from "../../assets/images/seva-karta.svg";
import ancestorDetailsImg from "../../assets/images/ancestor-details.svg";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import { getStringAsync } from "../../lib/storage";
import fireBaseUtil from "../../utils/fireBaseUtil";
import useAppDispatch from "../../hooks/useAppDispatch";
import { GET_PROFILE_SUCCESS } from "./profile.actionType";
import { useAppAccessor } from "../../hooks";
import { getProfile } from "./profile.action";
import { toast } from "react-toastify";
import Footer from "../Footer";
import Dropzone, { useDropzone } from "react-dropzone";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { NakshatraData, rashiData } from "../../constants/dataConstants";
import { calculateProfileCompleteness } from "../../utils/GlobalFunctions";

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { getProfileProps } = useAppAccessor();
  const { profileData } = getProfileProps();

  const [isShowEmailSupport, setIsShowEmailSupport] = useState(false);
  const [isShowEditProfile, setIsShowEditProfile] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [mobile, setMobile] = useState("");
  const [gotra, setGotra] = useState("");
  const [birthday, setBirthday] = useState("");
  const [timeOfBirth, setTimeOfBirth] = useState("");
  const [birthLocation, setBirthLocation] = useState("");
  const [email, setEmail] = useState("");
  const [selectedNakshatram, setSelectedNakshatram] = useState("");
  const [selectedRashi, setSelectedRashi] = useState("");
  const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [profileCompletedNess, setProfileCompletedNess] = useState<number>(0);

  useEffect(() => {
    if (profileData) {
      setProfileCompletedNess(calculateProfileCompleteness(profileData));
    }
  }, [profileData]);

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    const data: any = await getStringAsync("userDatas");

    await fireBaseUtil
      ?.getDevoteeById(`/devotees/${JSON.parse(data)?.phoneNumber}`)
      .then((data) => {
        fillEditProfileData(data);
        dispatch({ type: GET_PROFILE_SUCCESS, payload: data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fillEditProfileData = (data: any) => {
    setFirstName(data?.first_name || "");
    setLastName(data?.last_name || "");
    setMobile(data?.mobile || "");
    setEmail(data?.email || "");
    setBirthday(data?.birth_day || "");
    setTimeOfBirth(data?.birth_time || "");
    setGotra(data?.gotra || "");
    setBirthLocation(data?.birth_location || "");
    setSelectedRashi(data?.rashi || "");
    setSelectedNakshatram(data?.nakshatra || "");
  };

  const onClickSaveBtn = async () => {
    if (firstName == "") {
      toast.error("Please enter the first name");
    } else if (lastName == "") {
      toast.error("Please enter the last name");
    } else {
      const data: any = await getStringAsync("userDatas");

      let requestData = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        birth_day: birthday,
        birth_time: timeOfBirth,
        gotra: gotra,
        birth_location: birthLocation,
        rashi: selectedRashi,
        nakshatra: selectedNakshatram,
      };

      await fireBaseUtil.updateProfileData(
        JSON.parse(data)?.phoneNumber,
        requestData
      );
      dispatch(getProfile());
      toast.success("Profile update successfully");
      setIsShowEditProfile(false);
    }
  };

  const handleTakePhoto = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.capture = "camera";
    input.addEventListener("change", async (event: any) => {
      const imageFile = event?.target?.files[0];

      setSelectedImageFile(imageFile);
      setSelectedImage(URL.createObjectURL(imageFile));

      await fireBaseUtil.uploadProfilePhoto(imageFile);
      getUserDetails();
      // closeModal();
    });
    input.click();
  };

  return (
    <div>
      <Header />
      <div className="profile mb-5">
        <div className="container">
          <div className="row">
            {/*----- Card -----*/}
            <div className="col-xs-12 col-md-7 col-sm-7">
              <div className="profile-card">
                <div className="row">
                  <div
                    className="col-xs-12 col-md-3 col-sm-3 pr-inherit ta-center"
                    onClick={handleTakePhoto}
                  >
                    <img
                      style={{ cursor: "pointer", borderRadius: 50 }}
                      className="profile-img"
                      id="backButton"
                      src={
                        selectedImage ||
                        profileData?.profile_photo ||
                        profileImg
                      }
                      alt="Back"
                    />
                  </div>
                  <div className="col-xs-12 col-md-6 col-sm-6 pl-inherit">
                    <p className="sanjana-banerjee">{`${
                      profileData?.first_name || ""
                    } ${profileData?.last_name || ""}`}</p>
                    <p className="contact-no">{profileData?.mobile || ""}</p>
                    <span
                      className="d-flex"
                      style={{ cursor: "pointer" }}
                      onClick={() => setIsShowEditProfile(true)}
                    >
                      <p className="edit-details">{"Edit personal details"}</p>
                      <img
                        className="right-arrow-img"
                        id="backButton"
                        src={orengeRightArrowImg}
                        alt="Back"
                      />
                    </span>
                  </div>
                  <div className="col-xs-12 col-md-3 col-sm-3 d-flex-jc-center">
                    <div className="c-progressbar">
                      <CircularProgressbar
                        value={Math.round(profileCompletedNess || 0)}
                        text={`${Math.round(profileCompletedNess || 0)}%`}
                        strokeWidth={12}
                        styles={buildStyles({
                          textSize: "14px",
                          textColor: "#000000",
                          pathTransition: "none",
                          pathColor: "#049B22",
                          trailColor: "#E1FCDE",
                        })}
                      />
                    </div>
                  </div>

                  {/*----- Bottom Section -----*/}
                  <div
                    className="col-xs-12 col-md-12 col-sm-12"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/familymembers")}
                  >
                    <div className="row bottom-bg">
                      <div className="col-10 col-md-10 d-flex ac-center">
                        <img
                          className="family-img"
                          id="backButton"
                          src={familyImg}
                          alt="Back"
                        />
                        <p className="address-book">
                          {"Add Your Family Members"}
                        </p>
                      </div>
                      <div className="col-2 col-md-2 ac-center ta-end">
                        <img
                          className="grey-arrow"
                          id="backButton"
                          src={greyRightArrowImg}
                          alt="Back"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-md-3 col-sm-3">
              <div
                className="puja-profile-card"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/yourbookedpuja")}
              >
                <img
                  className="hw-44-img"
                  id="backButton"
                  src={yourBookedPujasImg}
                  alt="Back"
                />
                <p className="your-booked-puja-txt">{"Your Booked Pujas"}</p>
              </div>
              <div
                className="puja-profile-card mt-25"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/recordingpuja")}
              >
                <img
                  className="hw-44-img"
                  id="backButton"
                  src={watchRecordingsImg}
                  alt="Back"
                />
                <p className="your-booked-puja-txt">
                  {"Watch recordings of past pujas"}
                </p>
              </div>
            </div>

            <div className="col-xs-12 col-md-2 col-sm-2 ac-center ta-center">
              <img className="om-img" id="backButton" src={omImg} alt="Back" />
            </div>

            {/*----- Address Book -----*/}
            <div
              className="col-xs-12 col-md-6 col-sm-6 mt-35"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/addressBook")}
            >
              <div className="puja-profile-card">
                <div className="row">
                  <div className="col-10 col-xs-12 col-md-10 col-sm-10 d-flex ac-center">
                    <img
                      className="family-img"
                      id="backButton"
                      src={addressBookImg}
                      alt="Back"
                    />
                    <p className="address-book">{"Address Book"}</p>
                  </div>
                  <div className="col-2 col-xs-12 col-md-2 col-sm-2 ta-end ac-center pl-inherit">
                    <img
                      className="grey-arrow"
                      id="backButton"
                      src={greyRightArrowImg}
                      alt="Back"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/*----- Seva Karta Details -----*/}
            <div
              className="col-xs-12 col-md-6 col-sm-6 mt-35"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/sevaKartaDetails")}
            >
              <div className="puja-profile-card">
                <div className="row">
                  <div className="col-10 col-xs-12 col-md-10 col-sm-10 d-flex ac-center">
                    <img
                      className="family-img"
                      id="backButton"
                      src={sevaKartaImg}
                      alt="Back"
                    />
                    <p className="address-book">{"Seva Karta Details"}</p>
                  </div>
                  <div className="col-2 col-xs-12 col-md-2 col-sm-2 ta-end ac-center pl-inherit">
                    <img
                      className="grey-arrow"
                      id="backButton"
                      src={greyRightArrowImg}
                      alt="Back"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/*----- Ancestor Details -----*/}
            <div
              className="col-xs-12 col-md-6 col-sm-6 mt-20"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/ancestorDetails")}
            >
              <div className="puja-profile-card">
                <div className="row">
                  <div className="col-10 col-xs-12 col-md-10 col-sm-10 d-flex ac-center">
                    <img
                      className="family-img"
                      id="backButton"
                      src={ancestorDetailsImg}
                      alt="Back"
                    />
                    <p className="address-book">{"Ancestor Details"}</p>
                  </div>
                  <div className="col-2 col-xs-12 col-md-2 col-sm-2 ta-end ac-center pl-inherit">
                    <img
                      className="grey-arrow"
                      id="backButton"
                      src={greyRightArrowImg}
                      alt="Back"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/*----- Karta Ancestor Details -----*/}
            <div
              className="col-xs-12 col-md-6 col-sm-6 mt-20"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/kartaAncestorDetails")}
            >
              <div className="puja-profile-card">
                <div className="row">
                  <div className="col-10 col-xs-12 col-md-10 col-sm-10 d-flex ac-center">
                    <img
                      className="family-img"
                      id="backButton"
                      src={ancestorDetailsImg}
                      alt="Back"
                    />
                    <p className="address-book">{"Karta Ancestor Details"}</p>
                  </div>
                  <div className="col-2 col-xs-12 col-md-2 col-sm-2 ta-end ac-center pl-inherit">
                    <img
                      className="grey-arrow"
                      id="backButton"
                      src={greyRightArrowImg}
                      alt="Back"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xs-12 col-md-6 col-sm-6 mt-20"
              style={{ cursor: "pointer" }}
              // onClick={() => navigate("/kartaAncestorDetails")}
            >
              <div className="puja-profile-card">
                <div
                  className="row"
                  onClick={() => setIsShowEmailSupport(true)}
                >
                  <div className="col-10 col-xs-12 col-md-10 col-sm-10 d-flex ac-center">
                    <img
                      className="family-img"
                      id="backButton"
                      src={ancestorDetailsImg}
                      alt="Back"
                    />
                    <p className="address-book">{"Account Termination"}</p>
                  </div>
                  <div className="col-2 col-xs-12 col-md-2 col-sm-2 ta-end ac-center pl-inherit">
                    <img
                      className="grey-arrow"
                      id="backButton"
                      src={greyRightArrowImg}
                      alt="Back"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*----- Edit Profile Modal -----*/}
        {isShowEditProfile && (
          <div className="popup">
            <div className="popup-inner container edit-modal">
              <div className="row">
                <div className="col-xs-12 col-md-6 col-sm-6">
                  <div className="flex">
                    <img
                      onClick={() => setIsShowEditProfile(false)}
                      className="back-arrow-icon"
                      id="backButton"
                      src={backArrow}
                      alt="Back"
                    />
                    <span className="edit-personal">
                      {"Edit Personal Details"}
                    </span>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-sm-6"></div>

                {/*----- Message -----*/}
                <div className="col-xs-12 col-md-6 col-sm-6"></div>
                <div className="col-xs-12 col-md-6 col-sm-6 Profilepl-0">
                  <p className="edit-message">
                    {
                      "Below fields are optional. Why answer all this? They help us provide accurate information curated for you."
                    }
                  </p>
                </div>
                <div className="pl-3 row">
                  {/*----- Name & Gotra -----*/}
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <input
                        value={firstName}
                        type="text"
                        className="form-control"
                        id="username"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <label className="form-label">First Name*</label>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <input
                        value={gotra || ""}
                        type="text"
                        className="form-control"
                        id="gotra"
                        onChange={(e) => setGotra(e.target.value)}
                      />
                      <label className="form-label">Gotra</label>
                    </div>
                  </div>

                  {/*----- Phone Number & Select Rashi -----*/}
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <input
                        value={mobile}
                        type="text"
                        className="form-control"
                        id="phoneno"
                        disabled={true}
                      />
                      <label className="form-label">Phone Number *</label>
                    </div>
                  </div>

                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <select
                        id="rashiDropdown"
                        className="form-control with-arrow"
                      >
                        {rashiData?.map((i: any) => {
                          return <option value={i}>{i}</option>;
                        })}
                      </select>
                      <label className="form-label">Select Rashi</label>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-6 col-sm-6"></div>
                  {/*----- Birthday -----*/}

                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <input
                        value={birthday || ""}
                        type="text"
                        className="form-control"
                        id="birthday"
                        onChange={(e) => setBirthday(e.target.value)}
                      />
                      <label className="form-label">Birthday</label>
                    </div>
                  </div>

                  {/*----- Time of Birth -----*/}
                  <div className="col-xs-12 col-md-6 col-sm-6"></div>
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <input
                        value={timeOfBirth || ""}
                        type="text"
                        className="form-control"
                        id="timeofbirth"
                        onChange={(e) => setTimeOfBirth(e.target.value)}
                      />
                      <label className="form-label">Time of Birth</label>
                    </div>
                  </div>

                  {/*----- Birth Location -----*/}
                  <div className="col-xs-12 col-md-6 col-sm-6"></div>
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <input
                        value={birthLocation || ""}
                        type="text"
                        className="form-control"
                        id="birthlocation"
                        onChange={(e) => setBirthLocation(e.target.value)}
                      />
                      <label className="form-label">Birth Location</label>
                    </div>
                  </div>

                  {/*----- Select Nakshatram -----*/}
                  <div className="col-xs-12 col-md-6 col-sm-6"></div>
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <select
                        id="nakshatramDropdown"
                        className="form-control with-arrow"
                      >
                        {NakshatraData?.map((i: any) => {
                          return <option value={i}>{i}</option>;
                        })}
                      </select>
                      <label className="form-label">Select Nakshatram</label>
                    </div>
                  </div>

                  {/*----- Email Id -----*/}
                  <div className="col-xs-12 col-md-6 col-sm-6"></div>
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <input
                        value={email || ""}
                        type="text"
                        className="form-control"
                        id="emailid"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <label className="form-label">Email Id</label>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-sm-6"></div>
                <div className="col-xs-12 col-md-6 col-sm-6">
                  {/* <div className="row">
                <div className="col-xs-12 col-md-6 col-sm-6">
                  <div className="form-group">
                    <input
                      value="pincode"
                      type="text"
                      className="form-control"
                    />
                    <label className="form-label">Pincode</label>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-sm-6">
                  <div className="form-group">
                    <input
                      value="city"
                      type="text"
                      className="form-control"
                     
                    />
                    <label className="form-label">City</label>
                  </div>
                </div>
                </div> */}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-xs-12 col-md-6 col-sm-6"></div>
                <div className="col-xs-12 col-md-6 col-sm-6">
                  <div className="text-center my-3">
                    <button
                      onClick={onClickSaveBtn}
                      className="btn btn-primary pd-8-40"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/*----- Email Support Modal -----*/}
        {isShowEmailSupport && (
          <div className="email-support-popup">
            <div className="email-support-inner container">
              <div className="row">
                <div className="col-xs-12 col-md-12 col-sm-12">
                  <div className="close-icon-modal">
                    <img
                      onClick={() => setIsShowEmailSupport(false)}
                      className="close-icon"
                      id="backButton"
                      src={modalCloseImg}
                      alt="Back"
                    />
                  </div>
                  <div>
                    <span className="email-support-msg">
                      {
                        "If you want to terminate your account please email our support at"
                      }
                      <a className="email-support">
                        {" pujas@jyotirgamaya.online"}
                      </a>
                    </span>
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-xs-12 col-md-12 col-sm-12">
                  <div className="text-center">
                    <button
                      onClick={() => setIsShowEmailSupport(false)}
                      className="btn btn-primary pd-8-40"
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/*----- Footer -----*/}
      <Footer />
    </div>
  );
};

export default Profile;
