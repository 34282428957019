import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import PujaImg from "../../../assets/images/Temple.svg"; // Ensure this path is correct
import RightArrow from "../../../assets/images/rightArrow.svg";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { useNavigate, useParams } from "react-router-dom";
import { setSelectedPujaObj, setSelectedTempleObj } from "../home.actions";

export default function Temple({ data }: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onPressCard = (item: any) => {
    dispatch(setSelectedTempleObj(item));

    navigate(`/temple/${item?.id}`);
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-between address section-padding">
        <p className="temp-title-main">Temples</p>
        <img src={RightArrow} alt="Puja" style={{ cursor: "pointer" }} />
      </div>
      <div id="scroll" className="scroll">
        {data?.map((item: any) => {
          return (
            <div onClick={() => onPressCard(item)} className="card-item-temple">
              <img
                src={item?.image_main || ""}
                alt="Puja"
                style={{ height: "300px", objectFit: "cover" }}
              />
              <div className=" p-2">
                <p className="card-title-temple font-medium">
                  {item?.name || ""}
                </p>
                <div className="text-section" style={{ marginTop: "20px" }}>
                  <p className="disc-text font-reg">
                    {`${item?.city} , ${item?.state}`}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
