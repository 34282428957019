import { useSelector } from "react-redux";
import { GlobalState } from "../definitions/GlobalState";

const useAppAccessor = () => {
  const getHomeProps = useSelector((state: GlobalState) => {
    return state.home;
  });
  const getAsgroRocoProps = useSelector((state: GlobalState) => {
    return state.astroRecoReducer;
  });

  const getBookingProps = useSelector((state: GlobalState) => {
    return state.bookingReducer;
  });

  const getProfileProps = useSelector((state: GlobalState) => {
    return state.profileReducer;
  });

  return {
    getHomeProps: () => getHomeProps,
    getAsgroRocoProps: () => getAsgroRocoProps,
    getBookingProps: () => getBookingProps,
    getProfileProps: () => getProfileProps,
  };
};

export default useAppAccessor;
