// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.temple-page {
  padding-top: 20px;
}

.temple-page .img-w-100 {
  width: 100%;
}

.temple-page .temple-img-h {
  max-height: 655px;
  object-fit: cover;
  border-radius: 15px;
}

.temple-page .sub-temple-img-h {
  height: 320px;
  object-fit: cover;
  border-radius: 15px;
}

.temple-page .mt-15 {
  margin-top: 15px;
}
.imageTop img:last-child{
  margin-top: 14px;
}
.bigImg {
  padding-right: 0px;
}


@media only screen and (max-width: 480px) {
  .bigImg {
    padding-right: 13px !important;
}
}`, "",{"version":3,"sources":["webpack://./src/modules/TemplePage/Banner/style.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,kBAAkB;AACpB;;;AAGA;EACE;IACE,8BAA8B;AAClC;AACA","sourcesContent":[".temple-page {\n  padding-top: 20px;\n}\n\n.temple-page .img-w-100 {\n  width: 100%;\n}\n\n.temple-page .temple-img-h {\n  max-height: 655px;\n  object-fit: cover;\n  border-radius: 15px;\n}\n\n.temple-page .sub-temple-img-h {\n  height: 320px;\n  object-fit: cover;\n  border-radius: 15px;\n}\n\n.temple-page .mt-15 {\n  margin-top: 15px;\n}\n.imageTop img:last-child{\n  margin-top: 14px;\n}\n.bigImg {\n  padding-right: 0px;\n}\n\n\n@media only screen and (max-width: 480px) {\n  .bigImg {\n    padding-right: 13px !important;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
