import { Dispatch } from "redux";
import {
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  GET_FAMILY_REQUEST,
  GET_FAMILY_SUCCESS,
  GET_FAMILY_FAILURE,
  GET_SEVA_KARTA_REQUEST,
  GET_SEVA_KARTA_SUCCESS,
  GET_SEVA_KARTA_FAILURE,
  GET_KARTA_ANCESTOR_REQUEST,
  GET_KARTA_ANCESTOR_SUCCESS,
  GET_KARTA_ANCESTOR_FAILURE,
  GET_ADD_ANCESTOR_REQUEST,
  GET_ADD_ANCESTOR_SUCCESS,
  GET_ADD_ANCESTOR_FAILURE,
  GET_ADDRESS_LIST_SUCCESS,
  GET_ADDRESS_LIST_REQUEST,
  GET_ADDRESS_LIST_FAILURE,
} from "./profile.actionType";
// Assuming you have a Firebase utility file named fireBaseUtil

import fireBaseUtil from "../../utils/fireBaseUtil";
import { getStringAsync } from "../../lib/storage";

export const getProfile = () => async (dispatch: Dispatch) => {
  const data: any = await getStringAsync("userDatas");
  dispatch({ type: GET_PROFILE_REQUEST });
  try {
    // Retrieve mobile number from AsyncStorage or any other source
    const mobileNumber = JSON.parse(data)?.phoneNumber; // Adjust the key according to how you stored the mobile number

    if (!mobileNumber) {
      console.log("Mobile number not found");
    }

    // Call Firebase utility function to get devotee data
    await fireBaseUtil
      ?.getDevoteeById(`/devotees/${mobileNumber}`)
      .then((data) => {
        dispatch({ type: GET_PROFILE_SUCCESS, payload: data });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error: any) {
    dispatch({ type: GET_PROFILE_FAILURE, payload: error.message });
  }
};

export const getFamilyList = () => async (dispatch: Dispatch) => {
  const data: any = await getStringAsync("userDatas");
  dispatch({ type: GET_FAMILY_REQUEST });
  try {
    // Retrieve mobile number from AsyncStorage or any other source
    const mobileNumber = JSON.parse(data)?.phoneNumber; // Adjust the key according to how you stored the mobile number

    if (!mobileNumber) {
      console.log("Mobile number not found");
    }

    // Call Firebase utility function to get devotee data
    await fireBaseUtil
      ?.getDevoteeById(`/family_list/${mobileNumber}`)
      .then((data) => {

        dispatch({ type: GET_FAMILY_SUCCESS, payload: data });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error: any) {
    dispatch({ type: GET_FAMILY_FAILURE, payload: error.message });
  }
};

export const getKartaList = () => async (dispatch: Dispatch) => {
  const data: any = await getStringAsync("userDatas");
  dispatch({ type: GET_SEVA_KARTA_REQUEST });
  try {
    // Retrieve mobile number from AsyncStorage or any other source
    const mobileNumber = JSON.parse(data)?.phoneNumber; // Adjust the key according to how you stored the mobile number

    if (!mobileNumber) {
      console.log("Mobile number not found");
    }

    // Call Firebase utility function to get devotee data
    await fireBaseUtil
      ?.getDevoteeById(`/seva_karta_list/${mobileNumber}`)
      .then((data) => {
        dispatch({ type: GET_SEVA_KARTA_SUCCESS, payload: data });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error: any) {
    dispatch({ type: GET_SEVA_KARTA_FAILURE, payload: error.message });
  }
};

export const getKartaAncestorList = () => async (dispatch: Dispatch) => {
  const data: any = await getStringAsync("userDatas");
  dispatch({ type: GET_KARTA_ANCESTOR_REQUEST });
  try {
    // Retrieve mobile number from AsyncStorage or any other source
    const mobileNumber = JSON.parse(data)?.phoneNumber; // Adjust the key according to how you stored the mobile number

    if (!mobileNumber) {
      console.log("Mobile number not found");
    }

    // Call Firebase utility function to get devotee data
    await fireBaseUtil
      ?.getDevoteeById(`/karta_ancestor_list/${mobileNumber}`)
      .then((data) => {
        dispatch({ type: GET_KARTA_ANCESTOR_SUCCESS, payload: data });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error: any) {
    dispatch({ type: GET_KARTA_ANCESTOR_FAILURE, payload: error.message });
  }
};

export const getAddAncestorList = () => async (dispatch: Dispatch) => {
  const data: any = await getStringAsync("userDatas");
  dispatch({ type: GET_ADD_ANCESTOR_REQUEST });
  try {
    // Retrieve mobile number from AsyncStorage or any other source
    const mobileNumber = JSON.parse(data)?.phoneNumber; // Adjust the key according to how you stored the mobile number

    if (!mobileNumber) {
      console.log("Mobile number not found");
    }

    // Call Firebase utility function to get devotee data
    await fireBaseUtil
      ?.getDevoteeById(`/ancestor_list/${mobileNumber}`)
      .then((data) => {
        dispatch({ type: GET_ADD_ANCESTOR_SUCCESS, payload: data });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error: any) {
    dispatch({ type: GET_ADD_ANCESTOR_FAILURE, payload: error.message });
  }
};

export const getAddressBookList = () => async (dispatch: Dispatch) => {
  const data: any = await getStringAsync("userDatas");
  dispatch({ type: GET_ADDRESS_LIST_REQUEST });
  try {
    // Retrieve mobile number from AsyncStorage or any other source
    const mobileNumber = JSON.parse(data)?.phoneNumber; // Adjust the key according to how you stored the mobile number

    if (!mobileNumber) {
      console.log("Mobile number not found");
    }

    // Call Firebase utility function to get devotee data
    await fireBaseUtil
      ?.getDevoteeById(`/address_book_list/${mobileNumber}`)
      .then((data) => {
        dispatch({ type: GET_ADDRESS_LIST_SUCCESS, payload: data });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error: any) {
    dispatch({ type: GET_ADDRESS_LIST_FAILURE, payload: error.message });
  }
};
