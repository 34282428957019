import React, { useEffect, useState } from "react";
import Header from "../Header";
import likeVideo from "../../assets/images/like.png";
import youtubeImage from "../../assets/images/Group 26086868.png";
import DesclikeVideo from "../../assets/images/deslike.png";
import Share from "../../assets/images/share.png";
import VerticalLine from "../../assets/images/vertical-line.png";
import useAppDispatch from "../../hooks/useAppDispatch";
import "./style.css";
import Rashi from "../Puja/Rashi";
import AboutPuja from "./AboutPuja";
import Video from "./Video";
import { useAppAccessor } from "../../hooks";
import {
  clearVedicSignDataList,
  setAllPujasDataList,
  setAllTempleDataList,
  setVedicSingleObj,
} from "../Home/home.actions";
import fireBaseUtil from "../../utils/fireBaseUtil";
import Footer from "../Footer";
import { toast } from "react-toastify";
import { getStringAsync } from "../../lib/storage";
import { setPaidBookedAllPujasList } from "../MakePayment/booking.actions";

const LiveStram = ({ route }: any) => {
  const dispatch = useAppDispatch();

  const { getHomeProps, getBookingProps } = useAppAccessor();

  const pujaId = route?.params?.pujaId;

  const { allPujaData, vedicSignData } = getHomeProps();
  const { paidBookedPujas } = getBookingProps();

  const [displayData, setDisplayData] = useState<any>({});

  useEffect(() => {
    getAllPujas();
    getAllTemples();
    getPaidBookedPujasByuser();
  }, []);

  const getPaidBookedPujasByuser = async () => {
    const mobileNum: any = await getStringAsync("userDatas");

    await fireBaseUtil
      .getBookedPujaById(
        `/paid_booked_pujas/${JSON.parse(mobileNum)?.phoneNumber}`
      )
      .then((data: any) => {
        dispatch(setPaidBookedAllPujasList(data?.booked_puja_data || []));
      })
      .catch((err: any) => {});
  };

  const getAllTemples = async () => {
    await fireBaseUtil
      .getTemplsData()
      .then((data: any) => {
        dispatch(setAllTempleDataList(data || []));
      })
      .catch((err) => {});
  };

  const getAllPujas = async () => {
    await fireBaseUtil?.getPujasData().then((data: any) => {
      getData(data);
      dispatch(setAllPujasDataList(data));
    });
  };

  const getData = (pujaData: any) => {
    dispatch(clearVedicSignDataList([]));

    if (pujaId) {
      let data =
        pujaData.filter((i: any) => i?.id?.toString() == pujaId)?.[0] || {};
      data?.vedic_significance?.map((i: any) => {
        getVedicSignObj(i?.path || "");
      });
      setDisplayData(data);
    } else {
      let data =
        pujaData?.filter(
          (item: any) =>
            item.daysRemaining > 0 ||
            (item.daysRemaining == 0 &&
              new Date(
                item?.live_puja_endTime?.seconds * 1000 +
                  item?.live_puja_endTime?.nanoseconds / 1000000
              ).getTime() >= new Date().getTime())
        )?.[0] || {};

      setDisplayData(data);

      data?.vedic_significance?.map((i: any) => {
        getVedicSignObj(i?.path || "");
      });
    }
  };

  const getVedicSignObj = async (vedic_sing_Id: any) => {
    await fireBaseUtil
      ?.getTempleByID(vedic_sing_Id)
      .then((data) => {
        dispatch(setVedicSingleObj(data));
      })
      .catch((err) => {
        console.log("templeError===>", err);
      });
  };

  let live_stream_URL =
    displayData?.live_stream_URL?.split("watch?v=")?.[
      displayData?.live_stream_URL?.split("watch?v=")?.length - 1
    ];


  const isPaidPujas = paidBookedPujas?.filter(
    (i: any) => i?.main_puja_id == `/pujas/${displayData?.id}`
  );


  return (
    <div>
      <Header />

      {/*----- Banner -----*/}
      {displayData?.is_live_now && isPaidPujas?.length > 0 ? (
        <Video data={displayData} />
      ) : (
        <img
          onClick={() => {
            if (isPaidPujas?.length === 0) {
              toast.warn(
                "Live-atreaming will be activated few minutes before the start of puja for seva kartas who have purchased the seva."
              );
            } else {
              toast.error("Puja will start! Comming Soon..!");
            }
          }}
          className="youtube-icon"
          src={youtubeImage}
          alt="logo"
        />
      )}

      {/*----- Sankashthahara Chaturti Maha Puja -----*/}
      <div className="container">
        <div className="row">
          <p className="title-main font-Bold pl-pr-80 pt-20">
            {displayData?.name || ""}
          </p>
        </div>
        {/*----- Date & Venue Pujas -----*/}
        {/* <div className="container">
          <div className="row date-venue">
            <p className="like-deslike">
              <a href="">
                <img className="like-icon" src={likeVideo} alt="logo" />
              </a>{" "}
              Like{" "}
              <span>
                <img src={VerticalLine} alt="logo" />
              </span>{" "}
              <a href="">
                <img className="deslike-icon" src={DesclikeVideo} alt="logo" />
              </a>
            </p>
            <a href="" className="like-deslike">
              <img className="like-icon" src={Share} alt="logo" /> Share
            </a>
          </div>
        </div> */}
      </div>

      {/*----- About Puja & Rashi -----*/}
      <div className="container mb-5">
        <div className="row">
          <div className="col-12 col-lg-7 col-xl-7 col-md-12 col-sm-7">
            <AboutPuja data={displayData} />
          </div>
          <div className="col-12 col-lg-5 col-xl-5 col-md-12 col-sm-5 topSpace">
            <Rashi data={vedicSignData} />
          </div>
        </div>
      </div>
      {/*----- Puja List -----*/}
      <Footer />
    </div>
  );
};

export default LiveStram;
