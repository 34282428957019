import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  updateDoc,
  getDocs,
  getDoc,
  doc,
  deleteDoc,
  query,
  orderBy,
  setDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";

import { getStorage } from "firebase/storage";

// const firebaseConfig = {
//   apiKey: "AIzaSyCFxDVUa2DDQmIcdyDT8yLBJQvBNtLIlgo",
//   authDomain: "jyotirgamaya-e31fc.firebaseapp.com",
//   projectId: "jyotirgamaya-e31fc",
//   storageBucket: "jyotirgamaya-e31fc.appspot.com",
//   messagingSenderId: "27842951619",
//   appId: "1:27842951619:android:6a8aa3a41e8fb696c42576",
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyCJOl-fVu6nINcS81fDDs5YNY3i-8BiZ5Q",
//   authDomain: "jyotirgamaya-e31fc.firebaseapp.com",
//   projectId: "jmaya-2024",
//   storageBucket: "jmaya-2024.appspot.com",
//   messagingSenderId: "307955014932",
//   appId: "1:307955014932:android:28146d06fe6c19b09ff601",
// };

const firebaseConfig = {
  apiKey: "AIzaSyDiFtGJb0s2QY7O3nqZY8d2RjiwI29nwjo",
  authDomain: "jmaya-2024.firebaseapp.com",
  projectId: "jmaya-2024",
  storageBucket: "jmaya-2024.appspot.com",
  messagingSenderId: "307955014932",
  appId: "1:307955014932:web:8b8f0c875e36ee749ff601",
  measurementId: "G-VSJMDSJPC5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export {
  collection,
  addDoc,
  getDocs,
  getDoc,
  db,
  updateDoc,
  doc,
  deleteDoc,
  query,
  orderBy,
  setDoc,
  arrayUnion,
  arrayRemove,
  storage,
};
