import { Link, useLocation, useNavigate } from "react-router-dom";
import backArrowWhite from "../../assets/images/back-arrow-white.svg";
import geminiIcon from "../../assets/images/geminiIcon.svg";
import astrolgyIconGrid from "../../assets/images/astrolgyIconGrid.png";
import messageIcon from "../../assets/images/message.svg";
import whatsappIcon from "../../assets/images/whatApp.svg";
import React, { useEffect, useState } from "react";
import FullBackground from '../../assets/images/rashi.gif';
import Header from "../Header";
import "./style.css";
import { useAppAccessor } from "../../hooks";

document.documentElement.style.setProperty(
  "--bg-image",
  `url(${FullBackground})`
);
const AstroRecommendation = () => {
  const navigate = useNavigate();

  const { getAsgroRocoProps } = useAppAccessor();
  const { selectedRashiObj, loading, rashiData } = getAsgroRocoProps();

  const [selectedRashi, setSelectedRashi] = useState("");
  const [selectedRashiData, setSelectedRashiData] = useState<any>({});
  const currentDate = new Date();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonthNumber = currentDate.getMonth();
  const currentMonthName = selectedRashiObj?.isYearlySelect
    ? currentDate.getFullYear()
    : monthNames[currentMonthNumber];

  const [tabs, setTabs] = useState([
    { name: "General", active: true },
    { name: "Work & Finance", active: false },
    { name: "Health", active: false },
    { name: "Family & Relationship", active: false },
  ]);

  const index = rashiData.labelArray.indexOf(selectedRashiObj?.rashi?.value);

  useEffect(() => {
    setSelectedRashi(selectedRashiObj?.rashi?.label);
    if (index !== -1) {
      setSelectedRashiData(rashiData?.rashiPhalaArray[index]);
    }
  }, [selectedRashiObj?.rashi]);

  const handleTabChange = (tabIndex: number) => {
    const updatedTabs = tabs.map((tab, index) => ({
      ...tab,
      active: index === tabIndex,
    }));
    setTabs(updatedTabs);
  };

  const findObjActiveTab = tabs && tabs?.findIndex((tab) => tab.active);
  const onPressBack = () => navigate("/home");

  return (
    <div>
      <Header />
      <div className="pageBackground">
        <div className="back-qrrow-strip">
          <div className="container">
            {/*----- Back Arrow & Title -----*/}
            <div className="row">
              <img
                onClick={onPressBack}
                className="col-1 back-arrow-icon"
                id="backButton"
                style={{ cursor: "pointer", height: 40, width: 40 }}
                src={backArrowWhite}
                alt="Back"
              />
              <label className="col-10 back-qrrow-title">
                {selectedRashi || ""}
                <img
                  className="gemini-icon"
                  id="backButton"
                  style={{
                    cursor: "pointer",
                    height: 40,
                    width: 40,
                    marginLeft: 20,
                  }}
                  src={geminiIcon}
                  alt="Back"
                />
              </label>
            </div>

            {/*----- Tab Bar -----*/}
            <div className="row tab-btn mt-4 mb-4">
              <div className="col-12">
                {tabs?.map((i, index) => {
                  return (
                    <button
                      onClick={() => handleTabChange(index)}
                      className={
                        i.active
                          ? "active-tab-general"
                          : "deactivate-tab-general"
                      }
                    >
                      {i?.name || ""}
                    </button>
                  );
                })}
              </div>
            </div>

            {/*----- Top Description -----*/}
            <div className="row">
              <div className="col-12">
                <div className="desc-radius">
                  <p className="special-rituals-here">
                    {selectedRashiData &&
                      (findObjActiveTab == 0
                        ? selectedRashiData?.general
                        : findObjActiveTab == 1
                          ? selectedRashiData?.work
                          : findObjActiveTab == 2
                            ? selectedRashiData?.health
                            : selectedRashiData?.family)}
                  </p>
                </div>
              </div>
            </div>

            {/*----- Remedies -----*/}
            <div className="row mt-4 mb-4">
              <div className="col-12">
                <div className="desc-radius-remedies">
                  <p className="special-rituals-here">Remedies</p>
                  <ol>
                    {selectedRashiData?.remedies?.map(
                      (i: string, index: number) => {
                        return <li>{i || ""}</li>;
                      }
                    )}
                  </ol>
                </div>
              </div>
            </div>

            {/*----- Above predictions are not 100% accurate -----*/}
            <div className="desc-radius-yellow">
              <div className="row mb-4 ">
                <div className="col-10">
                  <p className="special-rituals-here">
                    *Above predictions are not 100% accurate.
                  </p>
                  <p className="bold-tip">
                    For accurate readings & predictions, we suggest taking a 1:1
                    consultation with our astrology expert.
                  </p>
                  <p className="normal-tip">
                    For inquiries, please contact us at
                    astro@jyotirgamaya.online
                  </p>
                </div>
                <div className="col-2">
                  <div className="astrolgyIconGrid">
                    <img
                      className="astrolgyIcon"
                      id="backButton"
                      src={astrolgyIconGrid}
                      alt="Back"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AstroRecommendation;
