import {
  Clear_Multiple_VEIDC_OBJECT,
  SET_ALL_PUJAS_DATA,
  SET_ALL_TEMPLE_DATA,
  SET_Multiple_VEIDC_OBJECT,
  SET_SELECTED_PUJA_OBJ,
  SET_SELECTED_TEMPLE_OBJ,
} from "./home.actionsType";

const initialState = {
  allPujaData: [],
  allTempleData: [],
  vedicSignData: [],
  selectedPujaObj: {},
  selectedTempleObj: {},
};

export default function home(state = initialState, action: any) {
  switch (action.type) {
    case SET_ALL_PUJAS_DATA:
      return {
        ...state,
        allPujaData: action?.payload,
      };
    case SET_ALL_TEMPLE_DATA:
      return {
        ...state,
        allTempleData: action?.payload,
      };
    case Clear_Multiple_VEIDC_OBJECT:
      return {
        ...state,
        vedicSignData: [],
      };
    case SET_SELECTED_PUJA_OBJ:
      return {
        ...state,
        selectedPujaObj: action?.payload,
      };
    case SET_SELECTED_TEMPLE_OBJ:
      return {
        ...state,
        selectedTempleObj: action?.payload,
      };
    case SET_Multiple_VEIDC_OBJECT:
      const mapVedic: any = new Map(
        state.vedicSignData.concat(action?.payload)?.map((c: any) => [c?.id, c])
      );
      const uniqueVedic = [...mapVedic?.values()];

      return {
        ...state,
        vedicSignData: uniqueVedic,
      };
    default:
      return state;
  }
}
