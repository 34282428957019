import "./style.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import backArrow from "../../assets/images/back-arrow.svg";
import editIcon from "../../assets/images/editPencile.svg";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
import useAppDispatch from "../../hooks/useAppDispatch";
import { useAppAccessor } from "../../hooks";
import { NakshatraData, rashiData } from "../../constants/dataConstants";
import { getKartaAncestorList } from "../Profile/profile.action";
import { generateUniqueId } from "../../utils/GlobalFunctions";
import { toast } from "react-toastify";
import { getStringAsync } from "../../lib/storage";
import fireBaseUtil from "../../utils/fireBaseUtil";
import { GET_KARTA_ANCESTOR_SUCCESS } from "../Profile/profile.actionType";

const KartaAncestorDetails = () => {
  const dispatch = useAppDispatch();

  const { getProfileProps } = useAppAccessor();
  const { kartaAncestorData } = getProfileProps();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isShowEditProfile, setIsShowEditProfile] = useState(false);
  const [name, setName] = useState("");
  const [additionalDetails, setAdditionalDetails] = useState("");
  const [mobile, setMobile] = useState("");
  const [gotra, setGotra] = useState("");
  const [birthday, setBirthday] = useState("");
  const [timeOfBirth, setTimeOfBirth] = useState("");
  const [birthLocation, setBirthLocation] = useState("");
  const [email, setEmail] = useState("");
  const [selectedNakshatram, setSelectedNakshatram] = useState("");
  const [selectedRashi, setSelectedRashi] = useState("");
  const [oldData, setOldData] = useState<any>({});
  const [gender, setGender] = useState("");

  useEffect(() => {
    dispatch(getKartaAncestorList());
  }, []);

  const onClickSave = () => {
    if (name == "") {
      toast.error("Please enter the name");
    } else if (gotra == "") {
      toast.error("Please enter the gotra");
    } else if (gotra == "") {
      toast.error("Please enter the gotra");
    } else {
      let userData: any = {
        name: name || "",
        additionalDetails: additionalDetails || "",
        gotra: gotra || "",
        rashi: selectedRashi || "",
        birth_day: birthday || "",
        birth_time: timeOfBirth || "",
        birth_location: birthLocation || "",
        nakshatra: selectedNakshatram || "",
        mobile: mobile || "",
      };
      setIsLoading(true);

      const isAlreadyData = kartaAncestorData?.kartaAncestorList?.filter(
        (i: any) => oldData?.uniqueId && i?.uniqueId == oldData?.uniqueId
      );

      if (isEmpty(isAlreadyData)) {
        userData.uniqueId = generateUniqueId();
        let oldData: any = kartaAncestorData?.kartaAncestorList || [];
        oldData.push(userData);

        checkIfUserExist(oldData);
        setIsShowEditProfile(false);
      } else {
        let finalData = kartaAncestorData?.kartaAncestorList?.map(
          (item: any) => {
            if (item?.uniqueId == oldData?.uniqueId)
              return { ...oldData, ...userData };
            else return item;
          }
        );

        checkIfUserExist(finalData);
        setIsShowEditProfile(false);
      }
    }
  };

  const updateUserData = async (userData: any) => {
    const data: any = await getStringAsync("userDatas");

    await fireBaseUtil.updateFamilyData(
      JSON.parse(data)?.phoneNumber,
      "karta_ancestor_list",
      "kartaAncestorList",
      userData
    );
  };

  const createUserData = async (userData: any) => {
    const data: any = await getStringAsync("userDatas");

    await fireBaseUtil.addNewFamilyUserRecord(
      JSON.parse(data)?.phoneNumber,
      "karta_ancestor_list",
      "kartaAncestorList",
      userData
    );
  };

  const checkIfUserExist = async (payload: any) => {
    const data: any = await getStringAsync("userDatas");

    const isUserExist = await fireBaseUtil.checkIfUserExistsOrNot(
      `/karta_ancestor_list/${JSON.parse(data)?.phoneNumber}`
    );

    if (isUserExist) {
      updateUserData(payload);
      dispatch({
        type: GET_KARTA_ANCESTOR_SUCCESS,
        payload: { kartaAncestorList: payload },
      });

      setIsLoading(false);
    } else {
      createUserData(payload);
      dispatch({
        type: GET_KARTA_ANCESTOR_SUCCESS,
        payload: { kartaAncestorList: payload },
      });
      setIsLoading(false);
    }
  };

  const onEditPress = (data: any) => {
    setOldData(data);
    setAdditionalDetails(data.addit || "");
    setName(data?.name || "");
    setGender(data?.gender || "");
    setMobile(data?.mobile || "");
    setGotra(data?.gotra || "");
    setBirthday(data?.birth_day || "");
    setTimeOfBirth(data?.birth_time || "");
    setBirthLocation(data?.birth_location || "");
    setSelectedNakshatram(data?.nakshatra || "");
    setSelectedRashi(data?.rashi || "");
    setIsShowEditProfile(true);
  };

  const onPressAddSevaKarta = () => {
    setOldData({});
    setGender("");
    setMobile("");
    setName("");
    setAdditionalDetails("");
    setGotra("");
    setBirthday("");
    setTimeOfBirth("");
    setBirthLocation("");
    setSelectedNakshatram("");
    setSelectedRashi("");
    setIsShowEditProfile(true);
  };

  return (
    <div>
      <Header />
      <div className="kerta-ancestor-details">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 col-xl-8 col-md-8 col-sm-8">
              <div className="flex">
                <img
                  onClick={() => navigate(-1)}
                  className="back-arrow-icon"
                  id="backButton"
                  src={backArrow}
                  alt="Back"
                />
                <span className="our-offerings">Karta Ancestor Details</span>
              </div>
            </div>
            <div
              className="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-4"
              style={{ cursor: "pointer" }}
              onClick={onPressAddSevaKarta}
            >
              <div className="d-flex flex-row-reverse">
                <button className="btn btn-primary book-puja-w">
                  + Add Karta Ancestor
                </button>
              </div>
            </div>
          </div>

          {/* list */}
          <div className="container">
            <div className="row mb-5 mt-3">
              {/*----- Card 1 -----*/}
              {kartaAncestorData?.kartaAncestorList?.map((item: any) => {
                return (
                  <div className="col-12 col-lg-3 col-xl-3 col-md-3 col-sm-3 pl-inherit">
                    <div className="puja-card">
                      <div className="row">
                        <div className="col-10">
                          <p className="puja-title"> {item?.name || ""}</p>
                          <p className="puja-description">
                            {item?.gotra || ""}
                          </p>
                        </div>
                        <div
                          className="col-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => onEditPress(item)}
                        >
                          <p>
                            {" "}
                            <img
                              className="editIconCoursor"
                              src={editIcon}
                              alt="edit"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {isShowEditProfile && (
          <div className="popup">
            <div className="popup-inner container edit-modal">
              <div className="row">
                <div className="col-xs-12 col-md-6 col-sm-6">
                  <div className="flex">
                    <img
                      onClick={() => setIsShowEditProfile(false)}
                      className="back-arrow-icon"
                      id="backButton"
                      src={backArrow}
                      alt="Back"
                    />
                    <span className="edit-personal">
                      {"Add Karta Ancestor Details"}
                    </span>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-sm-6"></div>

                <div className="col-xs-12 col-md-6 col-sm-6"></div>
                <div className="col-xs-12 col-md-6 col-sm-6 Profilepl-0">
                  <p className="edit-message">
                    {
                      "Below fields are optional. Why answer all this? They help us provide accurate information curated for you."
                    }
                  </p>
                </div>
                <div className="pl-3 row">
                  {/*----- Name & Gotra -----*/}
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <input
                        value={name}
                        type="text"
                        className="form-control"
                        id="username"
                        onChange={(e) => setName(e.target.value)}
                      />
                      <label className="form-label">Name*</label>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <input
                        value={birthday || ""}
                        type="date"
                        className="form-control"
                        id="birthday"
                        onChange={(e) => setBirthday(e.target.value)}
                      />
                      <label className="form-label">Birthday</label>
                    </div>
                  </div>
                  {/*----- Phone Number & Select Rashi -----*/}
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <input
                        value={gotra || ""}
                        type="text"
                        className="form-control"
                        id="gotra"
                        onChange={(e) => setGotra(e.target.value)}
                      />
                      <label className="form-label">Gotra*</label>
                    </div>
                    <p className="grotraNO">
                      ( If you don't know your Gotra, please add 'Do not know'
                      ) 
                    </p>
                  </div>
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <input
                        value={timeOfBirth || ""}
                        type="time"
                        className="form-control"
                        id="timeofbirth"
                        onChange={(e) => setTimeOfBirth(e.target.value)}
                      />
                      <label className="form-label">Time of Birth</label>
                    </div>
                  </div>

                  {/*----- Time of Birth -----*/}
                  <div className="col-xs-12 col-md-6 col-sm-6"></div>
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <input
                        value={birthLocation || ""}
                        type="text"
                        className="form-control"
                        id="birthlocation"
                        onChange={(e) => setBirthLocation(e.target.value)}
                      />
                      <label className="form-label">Birth Location</label>
                    </div>
                  </div>

                  {/*----- Birth Location -----*/}
                  <div className="col-xs-12 col-md-6 col-sm-6"></div>
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <input
                        value={additionalDetails || ""}
                        type="text"
                        className="form-control"
                        id="birthlocation"
                        onChange={(e) => setAdditionalDetails(e.target.value)}
                      />
                      <label className="form-label">Additional Details</label>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-sm-6"></div>
                <div className="col-xs-12 col-md-6 col-sm-6">
                  {/* <div className="row">
                <div className="col-xs-12 col-md-6 col-sm-6">
                  <div className="form-group">
                    <input
                      value="pincode"
                      type="text"
                      className="form-control"
                    />
                    <label className="form-label">Pincode</label>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-sm-6">
                  <div className="form-group">
                    <input
                      value="city"
                      type="text"
                      className="form-control"
                     
                    />
                    <label className="form-label">City</label>
                  </div>
                </div>
                </div> */}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-xs-12 col-md-6 col-sm-6"></div>
                <div className="col-xs-12 col-md-6 col-sm-6">
                  <div className="text-center my-3">
                    <button
                      onClick={onClickSave}
                      className="btn btn-primary pd-8-40"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div style={{ paddingTop: 250 }}>
        <Footer />
      </div>
    </div>
  );
};

export default KartaAncestorDetails;
