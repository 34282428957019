import "./App.css";
import { Provider } from "react-redux";

import { AppRoutes } from "./navigator";
import { AppProvider } from "./providers/app";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import store from "./lib/createStore";

function App() {
  return (
    <AppProvider>
      <Provider store={store}>
        <AppRoutes />
        <ToastContainer />
      </Provider>
    </AppProvider>
  );
}

export default App;
