import React, { useState } from "react";
import "./style.css";
import Location from "../../../assets/images/location.svg";
import GodLakshmiAndVishnu from "../../../assets/images/god-lakshmi-and-vishnu-statue.svg";

const NellaiapparTemple = ({ data }: any) => {

  return (
    <div className="nellaiappar-temple">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-12 col-sm-12">
            <p className="title-main font-Bold mb-inherit">
              {data?.name || ""}
            </p>
            <span>
              <img className="location-icon" src={Location} alt="Location" />
              <a className="location-label">
                {data?.city || ""}, {data?.state || ""}
              </a>
            </span>
            <p className="temple-description">{data?.about || ""}</p>
          </div>

          {/*----- Special Rituals here -----*/}
          <div className="col-xs-6 col-xl-6 col-md-12 col-sm-12">
            <div className="bullet-list-bg">
              <p className="special-rituals-here">{"Special Rituals here"}</p>
              {data?.special_rituals?.map((i: any) => {
                return (
                  <ul>
                    <li>{i || ""}</li>
                  </ul>
                );
              })}
            </div>
          </div>
          <div className="col-xs-6 col-xl-6 col-md-12 col-sm-12 ta-center">
            <img
              className="lakshmi-vishnu-img"
              src={GodLakshmiAndVishnu}
              alt="GodLakshmiAndVishnu"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NellaiapparTemple;
