// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.puja-detail-banner .banner-text-section {
  text-align: center;
}

.puja-detail-banner .banner-title {
  font-size: 33px;
  color: #FFFFFF;
  font-weight: 400;
  line-height: 58px;
}

.puja-detail-banner .banner-image {
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
  height: 490px;
  width: 100%;
}

@media only screen and (max-width: 480px) {
  .puja-detail-banner .banner-image {
    height: 260px;
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/modules/Puja/Banner/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,4BAA4B;EAC5B,yBAAyB;EACzB,sBAAsB;EACtB,aAAa;EACb,WAAW;AACb;;AAEA;EACE;IACE,aAAa;IACb,WAAW;EACb;AACF","sourcesContent":[".puja-detail-banner .banner-text-section {\n  text-align: center;\n}\n\n.puja-detail-banner .banner-title {\n  font-size: 33px;\n  color: #FFFFFF;\n  font-weight: 400;\n  line-height: 58px;\n}\n\n.puja-detail-banner .banner-image {\n  background-repeat: no-repeat;\n  background-position: 100%;\n  background-size: cover;\n  height: 490px;\n  width: 100%;\n}\n\n@media only screen and (max-width: 480px) {\n  .puja-detail-banner .banner-image {\n    height: 260px;\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
