import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDiFtGJb0s2QY7O3nqZY8d2RjiwI29nwjo",
    authDomain: "jmaya-2024.firebaseapp.com",
    projectId: "jmaya-2024",
    storageBucket: "jmaya-2024.appspot.com",
    messagingSenderId: "307955014932",
    appId: "1:307955014932:web:8b8f0c875e36ee749ff601",
    measurementId: "G-VSJMDSJPC5",
 
};

const firebase = initializeApp(firebaseConfig);
export default firebase;
export const auth = getAuth(firebase);
