import "./style.css";
import footerLeftTop from "../../assets/images/footer-left-top.svg";
import googlePlay from "../../assets/images/g.svg";
import appStore from "../../assets/images/a.svg";
import footerGif from "../../assets/images/footerGif.gif";
import rightBottom from "../../assets/images/right-bottom.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="footer">
      <div className="container-fluid position-relative">
      <img
                src={footerLeftTop}
                className="let-top"
                alt="Left Top Decoration"
              />
              <img
                src={rightBottom}
                className="right-bottom"
                alt="Right Bottom Decoration"
              />
              <div className="container">
        <div className="content-wrapper">
          <div className="row">
            {/*----- Footer Left Side -----*/}
            <div className="col-md-6">
              
              <p className="footer-title f-35 pd-35">
                Perform pujas from the <br />
                comfort of your home with<br /> Jyotirgamaya!
              </p>
              <h5 style={{color:'#fff', marginBottom:30}}>Jyotirgamaya.online is a venture of Divine Deeds Pvt Ltd</h5>
              <div>
                <p className="download-text">Download the app now</p>
                <div className="download-btn">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.divinedeeds"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={googlePlay} alt="Google Play Store" />
                  </a>
                  <img src={appStore} alt="Apple App Store" />
                </div>
              </div>
            </div>

            {/*----- Footer Right Side  -----*/}
            <div className="col-md-6">
              <div className="gif-div">
                <img src={footerGif} className="footer-gif" alt="Footer Gif" />
              </div>
              
            </div>
          </div>
          <div className="bottom-link-section">
            <Link to={"/home/aboutus"} className="bottom-link"
              style={{ borderBottom: location.pathname == "/home/aboutus" ? "2px solid #fff" : '' }}
            >
              About Us
            </Link>
            {"|"}
            <Link
              to={"/home/privacypolicy"}
              style={{ borderBottom: location.pathname == "/home/privacypolicy" ? "2px solid #fff" : '' }}
              className="bottom-link"
            >
              Privacy Policy
            </Link>
            {"|"}
            <Link to={"/home/termsconditions"} className="bottom-link"
              style={{ borderBottom: location.pathname == "/home/termsconditions" ? "2px solid #fff" : '' }}
            >
            Terms & Conditions
            </Link>
            {"|"}
            <Link to="/home/refundPolicy" className="bottom-link"
              style={{ borderBottom: location.pathname == "/home/refundPolicy" ? "2px solid #fff" : '' }}
            >
              Refund Policy
            </Link>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
