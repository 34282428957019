import "./style.css";
import React from "react";
import { Link } from "react-router-dom";
import backArrow from "../../assets/images/back-arrow.svg";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";

const RefundPolicy = () => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <Header />

        <div>
          <div className="container">
            <div className="header-section-about">
            <div className="flex">
              <img
                className="back-arrow-icon"
                id="backButton"
                style={{ cursor: "pointer"}}
                src={backArrow}
                alt="Back"
                onClick={() => navigate("/")}
              />
              <label className="our-offerings">Refund Policy</label>
            </div>
            </div>
            <div className="custom-container">
              <p className="title-bold">Introduction</p>
              <p className="disc-tex">
                We at JyotirGamaya.online (part of Jyotirgamaya Pvt Ltd) strive
                to provide exceptional puja services and ensure your
                satisfaction with every aspect of your experience. However, we
                understand that circumstances may arise where you might need to
                cancel or request a refund for your booked puja service. To
                facilitate such situations, we have established the following
                refund policy:
              </p>
              <p className="title-bold">
                1. Cancellation and Refund Eligibility:
              </p>
              <p className="disc-tex">
                <ul className="li-list">
                  <li>
                    Requests for cancellation and refund must be made in writing
                    via email to pujas@jyotirgamaya.online
                  </li>
                  <li>
                    Refunds will be considered eligible under the following
                    circumstance/s for Services booked
                    <ul className="li-list">
                      <li>
                        In cases where we are unable to fulfill the booked puja
                        service due to unforeseen circumstances or unavoidable
                        reasons
                      </li>
                    </ul>
                  </li>
                  <li>
                    Refunds will be considered eligible under the following
                    circumstance/s for Products ordered
                    <ul className="li-list">
                      <li>
                        In cases where we are unable to fulfill the order of
                        physical product due to unforeseen circumstances or
                        unavoidable reasons.
                      </li>
                      <li>
                        In case of returns where customer is dissatisfied with
                        the product delivered by us. Please refer to the
                        reimbursement guidelines as below
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
              <p className="title-bold">
                We will make reimbursements for returns without undue delay, and
                not later than:
              </p>
              <p className="disc-tex">
                <ul className="li-list" style={{ listStyleType: "auto" }}>
                  <li>
                    30 days after the day we received back from you any goods
                    supplied; or
                  </li>
                  <li>
                    (if earlier) 30 days after the day you provide evidence that
                    you have returned the goods; or
                  </li>
                  <li>
                    If there were no goods supplied, 30 days after the day on
                    which we are informed about your decision to cancel this
                    contract. We will make the reimbursement using the same
                    means of payment as you used for the initial transaction
                    unless you have expressly agreed otherwise; in any event,
                    you will not incur any fees as a result of the
                    reimbursement.
                  </li>
                </ul>
                <p>
                  Refunds on product ordered will not be issued for
                  cancellations if the same crosses 15 days over and the
                  delivery date of the product.
                </p>
              </p>
              <p className="title-bold">2. Refund Process:</p>
              <ul className="li-list">
                <li>
                  Upon receiving a valid refund request, we will review it
                  promptly and notify you of the approval or rejection of your
                  refund.
                </li>
                <li>
                  If approved, the refund will be processed within 30 business
                  days to the original method of payment used for the booking.
                </li>
                <li>
                  Please note that processing times for refunds may vary
                  depending on your payment provider.
                </li>
              </ul>
              <p className="title-bold">3. Contact Us:</p>
              <p className="disc-tex">
                <ul className="li-list">
                  <li>
                    If you have any questions or concerns regarding our refund
                    policy or need assistance with a refund request, please
                    don't hesitate to contact our customer support team at
                    pujas@jyotirgamaya.online. We're here to help!
                  </li>
                </ul>
              </p>
              <p className="title-bold">4. Policy Updates:</p>
              <p className="disc-tex">
                <ul className="li-list">
                  <li>
                    JyotirGamaya.online (part of Jyotirgamaya Pvt Ltd) reserves
                    the right to update or modify this refund policy at any time
                    without prior notice. Any changes to the policy will be
                    effective immediately upon posting on our website.
                  </li>
                </ul>
              </p>
              <p className="disc-tex mb-5">
                By booking a puja service with us OR purchasing product from us,
                you acknowledge and agree to abide by the terms and conditions
                outlined in this refund policy.
              </p>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default RefundPolicy;
