// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/puja-banner.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.puja-banner {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
  height: 490px;
  margin-top: -67px;
}

.puja-banner .banner-text-section {
  padding-top: 185px;
  text-align: center;
}

.puja-banner .banner-title {
  font-size: 33px;
  color: #FFFFFF;
  font-weight: 400;
  line-height: 58px;
}`, "",{"version":3,"sources":["webpack://./src/modules/LiveStream/Video/style.css"],"names":[],"mappings":"AAAA;EACE,yDAA+D;EAC/D,4BAA4B;EAC5B,yBAAyB;EACzB,sBAAsB;EACtB,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".puja-banner {\n  background-image: url(\"../../../assets/images/puja-banner.svg\");\n  background-repeat: no-repeat;\n  background-position: 100%;\n  background-size: cover;\n  height: 490px;\n  margin-top: -67px;\n}\n\n.puja-banner .banner-text-section {\n  padding-top: 185px;\n  text-align: center;\n}\n\n.puja-banner .banner-title {\n  font-size: 33px;\n  color: #FFFFFF;\n  font-weight: 400;\n  line-height: 58px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
