

import './style.css';
import Accordion from 'react-bootstrap/Accordion';

const FAQ=()=>{
    return(
      <div className="container faqSection">
      <h1 className="banner-title1 text-center" style={{ color: '#0D0101' }}>
        Frequently Asked Questions
      </h1>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Can we book a puja?</Accordion.Header>
          <Accordion.Body>
            Yes, you can easily book a puja by visiting and signing up into our app Jyotirgamaya. online or website www.Jyotirgamaya.online
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>What payment methods are accepted?</Accordion.Header>
          <Accordion.Body>
            You can make payments using all debit and credit cards/UPIs. NRI customers can also use PayPal for payments.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>What facilities does the company provide?</Accordion.Header>
          <Accordion.Body>
            We offer a comprehensive range of puja/sevas curated by a team of Vedic priests as per the Hindu calendar and also as per astro predictions of 12 rashis. The monthly astro predictions are offered for FREE on our platform. Additionally, we perform all kinds of dosha removal rituals as per customer requirements. All the ritual/puja/sevas are conducted in temples only. We also offer personalized horoscope reading service & conduct personalized remedial puja/sevas if needed.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Why choose Jyotirgamaya.online?</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>Easy and smooth booking process</li>
              <li>Multiple payment options</li>
              <li>Booking confirmation via email, SMS, or WhatsApp</li>
              <li>High-quality puja execution, conducted in temples only</li>
              <li>Live streaming of puja</li>
              <li>Opportunity to be part of social/Dharmic initiatives</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>Can puja be performed online?</Accordion.Header>
          <Accordion.Body>
            Yes, pujas can be performed online. All you need to do is click on the “Livestream” tab at the bottom of the app or on your profile page. We will also share the live video link on your WhatsApp number.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>Can someone from a foreign country book a puja?</Accordion.Header>
          <Accordion.Body>
            Yes, pujas can be performed online. All you need to do is click on the “Livestream” tab at the bottom of the app or on your profile page. We will also share the live video link on your WhatsApp number.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>Will prasadam be provided after the puja?</Accordion.Header>
          <Accordion.Body>
            Yes, optionally, we provide prasadam within the stipulated time by couriering to your address, only if the address is updated in the profile page. Please note that the edible prasadam will be distributed among the devotees physically present in the temple, and this is considered as a seva done on your behalf.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header>Why should one go for online puja service?</Accordion.Header>
          <Accordion.Body style={{ fontFamily: 'RobotoRegular' }}>
            There are various Hindu ritual/puja/sevas one can do towards strengthening self in the spiritual journey and also seek divine intervention during challenging times based on reading of the horoscope chart. But due to time constraint and busy lifestyle one is unable to do the same despite having the intent to do. This is where we come in to help you to do all the puja/sevas you would like to do. We become the bridge between you and the divine blessings by conducting all the rituals in the best ritualistic way, on behalf of yourself and your family.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="8">
          <Accordion.Header>Do you provide astrology services?</Accordion.Header>
          <Accordion.Body style={{ fontFamily: 'RobotoRegular' }}>
            Yes, for personalized astrology services, you may please connect to astro@Jyotirgamaya.online
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
    )
}

export default FAQ;