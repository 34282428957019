import {
  GET_ASTROROCO_REQUEST,
  GET_ASTROROCO_SUCCESS,
  GET_ASTROROCO_FAILURE,
  SET_SELECTED_RASHI_OBJ,
} from "./astroReco.actionType";

interface AstroRecoState {
  loading: boolean;
  rashiData: any; // Adjust this according to the structure of your ASTROROCO data
  error: string | null;
  selectedRashiObj: any;
}

const initialState: AstroRecoState = {
  loading: false,
  rashiData: {},
  error: null,
  selectedRashiObj: {},
};

const astroRecoReducer = (
  state = initialState,
  action: any
): AstroRecoState => {
  switch (action.type) {
    case GET_ASTROROCO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ASTROROCO_SUCCESS:
      return {
        ...state,
        loading: false,
        rashiData: action.payload,
        error: null,
      };
    case GET_ASTROROCO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_SELECTED_RASHI_OBJ:
      return {
        ...state,
        selectedRashiObj: action.payload,
      };
    default:
      return state;
  }
};

export default astroRecoReducer;
