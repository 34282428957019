import "./style.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import backArrow from "../../assets/images/back-arrow.svg";
import whiteRightArrow from "../../assets/images/whiteRightArrow.svg";
import VideoIcon from "../../assets/images/videoIcon.png";
import removeIcon from "../../assets/images/removeIcon.svg";
import Header from "../Header";
import { useNavigate } from "react-router-dom";

import Footer from "../Footer";
import useAppDispatch from "../../hooks/useAppDispatch";
import fireBaseUtil from "../../utils/fireBaseUtil";
import {
  setAllPujasDataList,
  setAllTempleDataList,
} from "../Home/home.actions";
import { useAppAccessor } from "../../hooks";
import moment from "moment";
const RecordingPuja = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { getHomeProps } = useAppAccessor();
  const { allPujaData, allTempleData } = getHomeProps();

  const [currentMonthPujas, setCurrentMonthPujas] = useState<any>([]);

  useEffect(() => {
    getPujasData();
    getAllTemples();
  }, []);

  const getAllTemples = async () => {
    await fireBaseUtil
      .getTemplsData()
      .then((data: any) => {
        dispatch(setAllTempleDataList(data || []));
      })
      .catch((err) => {});
  };

  const getPujasData = async () => {
    await fireBaseUtil?.getPujasData().then((data: any) => {
      dispatch(setAllPujasDataList(data));
    });
  };

  useEffect(() => {
    if (allPujaData.length > 0) {
      setCurrentMonthPujas(
        allPujaData.filter(
          (ele) =>
            ele.daysRemaining < 0 ||
            (moment(ele.dateFormated).month() + 1 == moment().month() + 1 &&
              ele.daysRemaining == 0 &&
              new Date(
                ele?.live_puja_endTime?.seconds * 1000 +
                  ele?.live_puja_endTime?.nanoseconds / 1000000
              ).getTime() <= new Date().getTime())
        )
      );
    }
  }, [allPujaData]);

  return (
    <div>
      <Header />
      <div className="RecordingPujapage">
        <div className="container">
          <div className="flex">
            <img
              className="back-arrow-icon"
              id="backButton"
              src={backArrow}
              alt="Back"
              onClick={() => navigate(-1)}
            />
            <span className="make-payment-title">Puja Recordings</span>
          </div>

          <div className="container">
            <div className="row mb-5 mt-2 gx-5">
              {currentMonthPujas?.map((item: any) => {
                const venue: any = allTempleData?.filter(
                  (i: any) => `temples/${i.id}` == item?.venue?.path || ""
                )?.[0];
                return (
                  <div className="col-12 col-lg-4 col-xl-4 col-md-6 col-sm-4">
                    <div className="row">
                      {/*----- Your Order -----*/}
                      <div className="col-xs-12 col-md-12 col-sm-12 ">
                        <div className="puja-card row">
                          <div className="col-4 nopadding">
                            <img
                              className="puja-img-right"
                              src={item?.image_main || VideoIcon || ""}
                              // style={{ minHeight: "209px", width:"250px" }}
                              alt="pujaImg"
                            />
                          </div>
                          <div className="puja-img-right-div col-8">
                            <div className="allDesc">
                              <p className="puja-title">{item?.name || ""}</p>
                              <p className="redDesc">
                                {`Venue: ${venue?.name || item?.venue || ""}`}
                              </p>
                              <p className="d-flex mb-0">
                                <div className="orange-bg mr-3">
                                  {" "}
                                  {moment(
                                    new Date(item?.dateFormated) || ""
                                  )?.format("DD-MM-YYYY")}
                                </div>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default RecordingPuja;
