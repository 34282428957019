import React, { useRef } from "react";
import "./style.css";
import moment from "moment";
import { useAppAccessor } from "../../../hooks";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import {
  clearVedicSignDataList,
  setSelectedPujaObj,
} from "../../Home/home.actions";

export default function AllPujaList({ data }: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { getHomeProps, getBookingProps } = useAppAccessor();

  const { allTempleData } = getHomeProps();
  const { allBookedPujas, paidBookedPujas } = getBookingProps();

  const onPressBookPuja = (item: any) => {
    dispatch(clearVedicSignDataList([]));
    dispatch(setSelectedPujaObj(item));
    navigate(`/puja/${item?.id || ""}`);
  };

  const checkBookedPuja: any = (item: any) => {
    const findPujaBookedOrNot = paidBookedPujas?.filter(
      (i: any) => `/pujas/${item?.id}` == i?.main_puja_id
    );

    return findPujaBookedOrNot && findPujaBookedOrNot?.length !== 0;
  };

  return (
    <div className="container">
      <div className="row mb-50">
        {/*----- 1 Card -----*/}

        {data?.map((item: any) => {
          const venue: any = allTempleData?.filter(
            (i) => `temples/${i.id}` == item?.venue?.path || ""
          )?.[0];

          return (
            <div
              className="col-12 col-lg-4 col-xl-4 col-md-6 col-sm-4"
              onClick={() => onPressBookPuja(item)}
              style={{ cursor: "pointer" }}
            >
              <div className="puja-card-item">
                <img src={item?.image_main || ""} alt="Puja" />
                <div className="p-3">
                  <p className="card-puja-title">
                    {item.name || ""}
                  </p>
                  <div className="d-flex justify-content-between address">
                    <div className="text-section">
                      <p className="disc-text font-reg">
                        {moment(item?.dateFormated)?.format("Do MMM, dddd") ||
                          ""}
                        <br />
                        {venue?.name || item?.venue || ""}
                      </p>
                    </div>
                    {item?.daysRemaining >= 0 && item?.daysRemaining < 4 && (
                      <div className="day-section">
                        <span className="day-count font-medium">
                          {item.daysRemaining == 0
                            ? new Date(
                              item?.live_puja_endTime?.seconds * 1000
                            ).getHours() - new Date().getHours()
                            : item.daysRemaining}
                        </span>
                        <span className="digit font-medium">
                          {item.daysRemaining == 0 ? `hrs` : `day left`}
                        </span>
                      </div>
                    )}
                  </div>
                  {(item?.daysRemaining > 0 ||
                    (item.daysRemaining == 0 &&
                      new Date(
                        item?.live_puja_endTime?.seconds * 1000 +
                        item?.live_puja_endTime?.nanoseconds / 1000000
                      ).getTime() >= new Date().getTime())) && (
                      <div className="text-center pt-1-rem">
                        <button
                          onClick={() => onPressBookPuja(item)}
                          className="btn btn-primary book-puja-w"
                        >
                          {checkBookedPuja(item)
                            ? `Puja is booked`
                            : item?.is_live_now
                              ? `Live`
                              : `Book Puja`}
                        </button>
                      </div>
                    )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
