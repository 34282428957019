// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-section {
  text-align: center;
  background-color: #e2e2e2;
  padding: 30px 0px;
  margin-bottom: 30px;
}

.our-offerings {
  color: #0d0101;
  font-size: 32px;
  line-height: 37px;
  font-weight: 500;
  font-family: Medium;
  align-self: center;
}

.title-bold {
  font-size: 18px;
  font-family: Bold;
}

.li-list li {
  color: #0d0101;
  font-size: 16px;
  font-family: RobotoRegular;
}

/*----- Product CSS End -----*/

.botom-link {
  font-size: 18px;
  color: white;
  font-family: RobotoRegular;
  text-decoration: none;
  padding: 0px 7px;
}

.botom-link:hover {
  color: #ec9494;
}

.bottom-link-section {
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 20px;
}`, "",{"version":3,"sources":["webpack://./src/modules/PrivacyPolicy/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,0BAA0B;AAC5B;;AAEA,8BAA8B;;AAE9B;EACE,eAAe;EACf,YAAY;EACZ,0BAA0B;EAC1B,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".header-section {\n  text-align: center;\n  background-color: #e2e2e2;\n  padding: 30px 0px;\n  margin-bottom: 30px;\n}\n\n.our-offerings {\n  color: #0d0101;\n  font-size: 32px;\n  line-height: 37px;\n  font-weight: 500;\n  font-family: Medium;\n  align-self: center;\n}\n\n.title-bold {\n  font-size: 18px;\n  font-family: Bold;\n}\n\n.li-list li {\n  color: #0d0101;\n  font-size: 16px;\n  font-family: RobotoRegular;\n}\n\n/*----- Product CSS End -----*/\n\n.botom-link {\n  font-size: 18px;\n  color: white;\n  font-family: RobotoRegular;\n  text-decoration: none;\n  padding: 0px 7px;\n}\n\n.botom-link:hover {\n  color: #ec9494;\n}\n\n.bottom-link-section {\n  margin-top: 10px;\n  margin-bottom: 20px;\n  margin-left: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
