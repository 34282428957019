import "./style.css";
import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import backArrow from "../../assets/images/back-arrow.svg";
import editIcon from "../../assets/images/editPencile.svg";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
import useAppDispatch from "../../hooks/useAppDispatch";
import { getAddressBookList } from "../Profile/profile.action";
import { useAppAccessor } from "../../hooks";
import { countryArray, stateArray } from "../../constants/dataConstants";
import { generateUniqueId } from "../../utils/GlobalFunctions";
import { getStringAsync } from "../../lib/storage";
import fireBaseUtil from "../../utils/fireBaseUtil";
import { GET_ADDRESS_LIST_SUCCESS } from "../Profile/profile.actionType";
import { toast } from "react-toastify";

const AddressBook = () => {
  const { getProfileProps } = useAppAccessor();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { addressBookData } = getProfileProps();

  const [isLoading, setIsLoading] = useState(false);
  const [isShowEditProfile, setIsShowEditProfile] = useState(false);

  const [oldData, setOldData] = useState<any>({});
  const [whatAddress, setWhatAddress] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [street, setStreet] = useState("");
  const [landmarks, setLandMarks] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [otherValue, setOtherValue] = useState("");

  useEffect(() => {
    dispatch(getAddressBookList());
  }, []);

  const onClickSave = () => {
    if (whatAddress == "") {
      toast.error("Please enter the address");
      // }
      // else if (buildingName == "") {
      //   toast.error("Please enter the building name");
      // } else if (street == "") {
      //   toast.error("Please enter the streat");
      // } else if (landmarks == "") {
      //   toast.error("Please enter the landmark");
      // } else if (pincode == "") {
      //   toast.error("Please enter the pincode");
      // } else if (city == "") {
      //   toast.error("Please enter the city");
      // } else if (state == "") {
      //   toast.error("Please enter the state");
      // } else if (country == "") {
      //   toast.error("Please enter the country");
    } else {
      let userData: any = {
        what_address: whatAddress || "",
        otherValue: otherValue || "",
        building_name: buildingName || "",
        streat: street || "",
        landmarks: landmarks || "",
        pincode: pincode || "",
        city: city || "",
        state: state || "",
        country: country || "",
      };
      setIsLoading(true);

      const isAlreadyData = addressBookData?.addressBookList?.filter(
        (i: any) => oldData?.uniqueId && i?.uniqueId == oldData?.uniqueId
      );

      if (isEmpty(isAlreadyData)) {
        userData.uniqueId = generateUniqueId();
        let oldData: any = addressBookData?.addressBookList || [];
        oldData.push(userData);

        checkIfUserExist(oldData);
        setIsShowEditProfile(false);
      } else {
        let finalData = addressBookData?.addressBookList?.map((item: any) => {
          if (item?.uniqueId == oldData?.uniqueId)
            return { ...oldData, ...userData };
          else return item;
        });

        checkIfUserExist(finalData);
        setIsShowEditProfile(false);
      }
    }
  };

  const updateUserData = async (userData: any) => {
    const data: any = await getStringAsync("userDatas");

    await fireBaseUtil.updateFamilyData(
      JSON.parse(data)?.phoneNumber,
      "address_book_list",
      "addressBookList",
      userData
    );
  };

  const createUserData = async (userData: any) => {
    const data: any = await getStringAsync("userDatas");

    await fireBaseUtil.addNewFamilyUserRecord(
      JSON.parse(data)?.phoneNumber,
      "address_book_list",
      "addressBookList",
      userData
    );
  };

  const checkIfUserExist = async (payload: any) => {
    const data: any = await getStringAsync("userDatas");

    const isUserExist = await fireBaseUtil.checkIfUserExistsOrNot(
      `/address_book_list/${JSON.parse(data)?.phoneNumber}`
    );

    if (isUserExist) {
      updateUserData(payload);
      dispatch({
        type: GET_ADDRESS_LIST_SUCCESS,
        payload: { addressBookList: payload },
      });

      setIsLoading(false);
    } else {
      createUserData(payload);
      dispatch({
        type: GET_ADDRESS_LIST_SUCCESS,
        payload: { addressBookList: payload },
      });
      setIsLoading(false);
    }
  };

  const onEditPress = (data: any) => {
    setOldData(data);

    setWhatAddress(data?.what_address || "");
    setOtherValue(data?.otherValue || "");
    setBuildingName(data?.building_name || "");
    setStreet(data?.streat || "");
    setLandMarks(data?.landmarks || "");
    setPincode(data?.pincode || "");
    setCity(data?.city || "");
    setState(data?.state || "");
    setCountry(data?.country || "");
    setIsShowEditProfile(true);
  };

  const onPressAddress = () => {
    setOldData({});
    setOtherValue("");
    setWhatAddress("");
    setBuildingName("");
    setStreet("");
    setLandMarks("");
    setPincode("");
    setCity("");
    setCountry("");
    setIsShowEditProfile(true);
  };

  return (
    <div>
      <Header />
      <div className="address-book-page">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 col-xl-8 col-md-8 col-sm-8">
              <div className="flex">
                <img
                  onClick={() => navigate(-1)}
                  className="back-arrow-icon"
                  id="backButton"
                  src={backArrow}
                  alt="Back"
                />
                <span className="our-offerings">Address Book</span>
              </div>
            </div>
            <div
              className="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-4"
              style={{ cursor: "pointer" }}
              onClick={onPressAddress}
            >
              <div className="d-flex flex-row-reverse">
                <button className="btn btn-primary book-puja-w">
                  + Add Address Book
                </button>
              </div>
            </div>
          </div>

          {/* list */}
          <div className="container">
            <div className="row mb-5 mt-3">
              {/*----- Card 1 -----*/}
              {addressBookData?.addressBookList?.map((item: any) => {
                return (
                  <div className="col-12 col-lg-3 col-xl-3 col-md-3 col-sm-3 pl-inherit">
                    <div className="puja-card">
                      <div className="row">
                        <div className="col-10">
                          <p className="puja-title">
                            {item?.what_address == "Other"
                              ? item?.otherValue
                              : item?.what_address || ""}
                          </p>
                          <p className="puja-description">
                            {item?.building_name || ""}
                            {`, ${item?.building_name || ""}`}
                            {`, ${item?.streat || ""}`}
                            {`, ${item?.landmarks || ""}`}
                            {`, ${item?.pincode || ""}`}
                            {`, ${item?.city || ""}`}
                            {`, ${item?.state || ""}`}
                            {`, ${item?.country || ""}`}
                          </p>
                        </div>
                        <div
                          className="col-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => onEditPress(item)}
                        >
                          <p>
                            {" "}
                            <img
                              className="editIconCoursor"
                              src={editIcon}
                              alt="edit"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {isShowEditProfile && (
          <div className="popup">
            <div className="popup-inner container edit-modal">
              <div className="row">
                <div className="col-xs-12 col-md-6 col-sm-6 mb-3">
                  <div className="flex">
                    <img
                      onClick={() => setIsShowEditProfile(false)}
                      className="back-arrow-icon"
                      id="backButton"
                      src={backArrow}
                      alt="Back"
                    />
                    <span className="edit-personal">{"Your Address"}</span>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-sm-6 mb-3"></div>
                <div className="pl-3 row">
                  {/*----- Message -----*/}
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="whatAdress">What address is this?</div>
                    <div
                      className="d-grid gap-2 d-md-block"
                      style={{ marginTop: 8 }}
                    >
                      <button
                        onClick={() => setWhatAddress("Home")}
                        className={
                          whatAddress == "Home"
                            ? "btn btn-primary-custom"
                            : "btn btn-address"
                        }
                        type="button"
                      >
                        Home
                      </button>
                      <button
                        onClick={() => setWhatAddress("Office")}
                        className={
                          whatAddress == "Office"
                            ? "btn btn-primary-custom"
                            : "btn btn-address"
                        }
                        type="button"
                      >
                        Office
                      </button>
                      <button
                        onClick={() => setWhatAddress("Hostel")}
                        className={
                          whatAddress == "Hostel"
                            ? "btn btn-primary-custom"
                            : "btn btn-address"
                        }
                        type="button"
                      >
                        Hostel
                      </button>
                      <button
                        onClick={() => setWhatAddress("Other")}
                        className={
                          whatAddress == "Other"
                            ? "btn btn-primary-custom"
                            : "btn btn-address"
                        }
                        type="button"
                      >
                        Other
                      </button>
                    </div>
                    {whatAddress == "Other" && (
                      <div className="d-flex mb-2 mt-2">
                        <button
                          type="button"
                          data-mdb-button-init
                          data-mdb-ripple-init
                          className="btn btn-primary-custom  me-3"
                        >
                          Other
                        </button>
                        <div data-mdb-input-init className="form-outline w-100">
                          <input
                            type="text"
                            id="form1"
                            className="form-control mt-0"
                            value={otherValue}
                            onChange={(e) => setOtherValue(e.target.value)}
                          />
                          <label className="form-label">Other</label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-xs-12 col-md-6 col-sm-6 Profilepl-0">
                    <p className="edit-message">
                      {
                        "Below fields are optional. Why answer all this? They help us provide accurate information curated for you."
                      }
                    </p>
                  </div>
                </div>
                <div className="pl-3 row">
                  {/*----- Name & Gotra -----*/}
                  {/* <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <input
                        value={whatAddress}
                        type="text"
                        className="form-control"
                        id="username"
                        onChange={(e) => setWhatAddress(e.target.value)}
                      />
                      <label className="form-label">
                        What address is this*
                      </label>
                    </div>
                  </div> */}
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <input
                        value={buildingName || ""}
                        type="text"
                        className="form-control"
                        id="gotra"
                        onChange={(e) => setBuildingName(e.target.value)}
                      />
                      <label className="form-label">
                        Building Name/House number
                      </label>
                    </div>
                  </div>

                  {/*----- Phone Number & Select Rashi -----*/}
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <input
                        value={landmarks || ""}
                        type="text"
                        className="form-control"
                        id="lastname"
                        onChange={(e) => setLandMarks(e.target.value)}
                      />
                      <label className="form-label">Landmarks</label>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <input
                        value={street || ""}
                        type="text"
                        className="form-control"
                        id="lastname"
                        onChange={(e) => setStreet(e.target.value)}
                      />
                      <label className="form-label">Street/locality</label>
                    </div>
                  </div>

                  {/*----- Birthday -----*/}
                  <div className="col-xs-12 col-md-6 col-sm-6"></div>

                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="row">
                      <div className="col-xs-12 col-md-6 col-sm-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            value={pincode || ""}
                            onChange={(e) => setPincode(e.target.value)}
                          />
                          <label className="form-label">Pincode</label>
                        </div>
                      </div>
                      <div className="col-xs-12 col-md-6 col-sm-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            value={city || ""}
                            onChange={(e) => setCity(e.target.value)}
                          />
                          <label className="form-label">City</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*----- Select Nakshatram -----*/}
                  <div className="col-xs-12 col-md-6 col-sm-6"></div>
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <select
                        id="nakshatramDropdown"
                        className="form-control with-arrow"
                        value={state || ""}
                        onChange={(e) => setState(e.target.value)}
                      >
                        {stateArray?.map((i) => {
                          return <option value={i}>{i}</option>;
                        })}
                      </select>
                      <label className="form-label">State</label>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-6 col-sm-6"></div>
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <select
                        id="nakshatramDropdown"
                        className="form-control with-arrow"
                        value={country || ""}
                        onChange={(e) => setCountry(e.target.value)}
                      >
                        {countryArray?.map((i) => {
                          return <option value={i}>{i}</option>;
                        })}
                      </select>
                      <label className="form-label">Country</label>
                    </div>
                  </div>

                  {/*----- Email Id -----*/}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-xs-12 col-md-6 col-sm-6"></div>
                <div className="col-xs-12 col-md-6 col-sm-6">
                  <div className="text-center my-3">
                    <button
                      onClick={onClickSave}
                      className="btn btn-primary pd-8-40"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div style={{ paddingTop: 300 }}>
        <Footer />
      </div>
    </div>
  );
};

export default AddressBook;
