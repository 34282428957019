export const totalPrice = (bookedPuja: any) => {
  let total = 0;
  bookedPuja.map((i: any) => {
    total += Number(i?.price || 0) * Number(i?.quentity || 1);
  });
  return total;
};

export function generateUniqueId() {
  return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
}

export const calculateProfileCompleteness = (data: any) => {
  // Calculate the total number of keys in the profile data
  let totalFields = 0;

  // Count the number of non-null fields
  let filledFields = 0;
  if (data) {
    totalFields = Object.keys(data).length;
    filledFields = Object.keys(data).reduce((count, key) => {
      // You may need to adjust the condition based on how your profile data is structured
      if (data[key]) {
        count++;
      }

      return count;
    }, 0);
  }

  // Calculate the percentage of completeness
  let completenessPercentage = 0;
  if (totalFields !== 0) {
    completenessPercentage = (filledFields / totalFields) * 100;
  }

  return completenessPercentage;
};
