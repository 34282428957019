import "./style.css";
// import RemovesAllOobstacles from "../../../assets/images/removes-all-obstacles.svg";
// import CleansesNegativity from "../../../assets/images/cleanses-negativity.svg";
// import FulfillsYourLifesDesires from "../../../assets/images/fulfills-your-lifes-desires.svg";
// import MakesYouContentPeaceful from "../../../assets/images/makes-you-content-peaceful.svg";

const Rashi = ({ data }: any) => {
  return (
    <div className="">
      <div className="puja-rashi">
        <div className="row">
          <div className="col-xs-12 col-md-12 col-sm-12">
            <p className="vedic-significance">{"Vedic significance"}</p>
          </div>
          {data?.map((i: any) => {
            return (
              <div className="col-xs-12 col-md-6 col-sm-6">
                <div className="ta-center">
                  <img
                    src={i?.icon || ""}
                    alt="logo"
                    className="rashi-icon"
                  />
                  <p className="vedic-significance-description">
                    {i?.name || ""}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Rashi;