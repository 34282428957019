import "./style.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../Header";
import backArrow from "../../assets/images/back-arrow.svg";
import ShriRam from "../../assets/images/shriRam.svg";

import Footer from "../Footer";
import useAppDispatch from "../../hooks/useAppDispatch";
import {
  clearVedicSignDataList,
  setAllPujasDataList,
} from "../Home/home.actions";
import fireBaseUtil from "../../utils/fireBaseUtil";
import { getStringAsync } from "../../lib/storage";
import { setPaidBookedAllPujasList } from "../MakePayment/booking.actions";
import { useAppAccessor } from "../../hooks";
import moment from "moment";
const YourBookedPuja = () => {
  const dispatch = useAppDispatch();

  const { getHomeProps } = useAppAccessor();
  const { allPujaData } = getHomeProps();

  const navigate = useNavigate();

  const [currentMonthPujas, setCurrentMonthPujas] = useState<any>([]);
  const [perviousMonthPujas, setPerviousMonthPujas] = useState<any>([]);
  const [thisMonth, setThisMonth] = useState(true);
  const [recommended, setRecommended] = useState(false);

  useEffect(() => {
    getPujasData();
    getPaidBookedPujasByuser();
  }, []);

  const getPujasData = async () => {
    await fireBaseUtil?.getPujasData().then((data: any) => {
      dispatch(setAllPujasDataList(data));
    });
  };

  const getPaidBookedPujasByuser = async () => {
    const mobileNum: any = await getStringAsync("userDatas");
    await fireBaseUtil
      .getBookedPujaById(
        `/paid_booked_pujas/${JSON.parse(mobileNum)?.phoneNumber}`
      )
      .then((data: any) => {
        dispatch(setPaidBookedAllPujasList(data?.booked_puja_data || []));
        if (data?.booked_puja_data?.length > 0) {
          setCurrentMonthPujas(
            data?.booked_puja_data?.filter(
              (ele: any) => moment(ele.date) >= moment()
            )
          );
          setPerviousMonthPujas(
            data?.booked_puja_data?.filter(
              (ele: any) => moment() > moment(ele.date)
            )
          );
        } else {
          setCurrentMonthPujas([]);
          setPerviousMonthPujas([]);
        }
      })
      .catch((err: any) => {
        console.log("Errorr:getPaidBookedPujasByuser", err);
      });
  };

  const onClickUpcomming = () => {
    setThisMonth(true);
    setRecommended(false);
  };

  const onClickPast = () => {
    setRecommended(true);
    setThisMonth(false);
  };

  let showData = thisMonth ? currentMonthPujas : perviousMonthPujas;

  return (
    <div>
      <Header />
      <div className="YourBookedPujapage">
        <div className="container">
          <div className="flex">
            <img
              className="back-arrow-icon"
              id="backButton"
              src={backArrow}
              alt="Back"
              onClick={() => navigate(-1)}
            />
            <span className="make-payment-title">Your Booked Puja</span>
          </div>
          <div className="container">
            <div className="row tab-btn">
              <button
                onClick={onClickUpcomming}
                className={thisMonth ? "active-tab" : "deactivate-tab"}
              >
                Upcoming
              </button>
              <button
                onClick={onClickPast}
                className={recommended ? "active-tab" : "deactivate-tab"}
              >
                Past
              </button>
            </div>
          </div>
          <div className="container pr-zero">
            <div className="row mb-5">
              {showData?.map((data: any) => {
                return (
                  <div className="puja-img-right-div col-12 col-lg-5 col-xl-5 col-md-12 col-sm-5 mrbox">
                    <div className="row">
                      {/*----- Your Order -----*/}
                      <div className="col-xs-12 col-md-12 col-sm-12">
                        <div className="puja-card row">
                        <div className="col-12 col-lg-4 col-xl-3 col-md-3 col-sm-3 nopadding desktopHidden">
                            <img
                              className="puja-img-right"
                              src={
                                data?.pic ||
                                require("../../assets/images/tmpPujas.jpeg") ||
                                ""
                              }
                              // style={{ minHeight: "209px", width:"250px" }}
                              alt="pujaImg"
                            />
                          </div>
                          <div className="puja-img-right-div col-12 col-lg-8 col-xl-9 col-md-9 col-sm-9">
                            <div className="allDesc">
                              <p className="puja-sub-title truncate-text">
                                {data?.pujaIndex || ""}
                                {" : "}
                                {data?.puja_name || ""}
                              </p>
                              <p className="puja-title">
                                {`${data?.title || ""}`}
                              </p>
                              <p className="redDesc">{data?.subtitle || ""}</p>
                              <p className="d-flex mb-0">
                                <div className="recording-bg mr-3">
                                  {"• "}
                                  {`Rs ${data.price}/-`}
                                </div>
                                <div className="orange-bg">
                                  {" "}
                                  {moment(data?.date).format(
                                    "DD-MM-YYYY (dddd)"
                                  )}
                                </div>
                              </p>
                            </div>
                          </div>
                          <div className="col-12 col-lg-4 col-xl-3 col-md-3 col-sm-3 nopadding mobileHIden">
                            <img
                              className="puja-img-right"
                              src={
                                data?.pic ||
                                require("../../assets/images/tmpPujas.jpeg") ||
                                ""
                              }
                              // style={{ minHeight: "209px", width:"250px" }}
                              alt="pujaImg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="mb-5">&nbsp;</div>
            <div className="mb-5">&nbsp;</div>
          </div>
        </div>
      </div>

      {/* <div style={{ paddingTop: 250 }}> */}
        <Footer />
      {/* </div> */}
    </div>
  );
};

export default YourBookedPuja;
