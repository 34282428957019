import "./style.css";
import React from "react";
import { Link } from "react-router-dom";
import backArrow from "../../assets/images/back-arrow.svg";
import pujaImg from "../../assets/images/puja-img.svg";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";

const Product = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Header />
      <div className="product">
        <div className="container">
          <div className="flex">
            <img
              onClick={() => navigate(-1)}
              className="back-arrow-icon"
              id="backButton"
              src={backArrow}
              alt="Back"
            />
            <span className="our-offerings">{"Our Offerings"}</span>
          </div>

          <div className="container">
            <div className="row">
              {/*----- Card 1 -----*/}
              <div className="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-4 pl-inherit">
                <div className="puja-card">
                  <p className="puja-title">{"Main Puja"}</p>
                  <p className="puja-description">
                    {
                      "Vishesha puja offered to the diety on the auspicious day of that particular diety. It could be a sacred homa or a vishesha seva."
                    }
                  </p>
                  <p className="puja-price">{"RS 1201"}</p>
                </div>
              </div>

              {/*----- Card 2 -----*/}
              <div className="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-4">
                <div className="puja-card">
                  <p className="puja-title">{"Abhisheka"}</p>
                  <p className="puja-description">
                    {
                      "Vishesha puja offered to the diety on the auspicious day of that particular diety. It could be a sacred homa or a vishesha seva."
                    }
                  </p>
                  <p className="puja-price">{"RS 999"}</p>
                </div>
              </div>

              {/*----- Card 3 -----*/}
              <div className="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-4">
                <div className="puja-card">
                  <p className="puja-title">{"Special Naivedya"}</p>
                  <p className="puja-description">
                    {
                      "Vishesha puja offered to the diety on the auspicious day of that particular diety. It could be a sacred homa or a vishesha seva."
                    }
                  </p>
                  <p className="puja-price">{"RS 999"}</p>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-4 d-md-none"></div>

              {/*----- Free Add Ons -----*/}
              <div className="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-4 pl-inherit">
                <div className="free-add-ons-card">
                  <p className="free-add-ons-txt">{"Free Add Ons"}</p>
                  <p className="free-add-ons-description">
                    {
                      "A small package of God's blessings in the form of prasadam will be couriered to devotee's address free of cost"
                    }
                  </p>
                </div>
              </div>
              <div className="col-xs-12 col-md-7 col-sm-7"></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Product;
