import {
  GET_RAZORPAY_BOOKED_FAILURE,
  GET_RAZORPAY_BOOKED_REQUEST,
  GET_RAZORPAY_BOOKED_SUCCESS,
  SET_ALL_BOOKED_PUJAS_BY_USER,
  SET_PAID_ALL_BOOKED_PUJAS_BY_USER,
} from "./booking.actionsType";

const initialState = {
  allBookedPujas: [],
  razorPayPuchasedDataLoading: false,
  razorPayPuchasedData: [],
  errorRazorPayPuchasedData: null,
  paidBookedPujas: [],
};

export default function bookingReducer(state = initialState, actions: any) {
  switch (actions.type) {
    case SET_ALL_BOOKED_PUJAS_BY_USER:
      return {
        ...state,
        allBookedPujas: actions?.payload,
      };

    case GET_RAZORPAY_BOOKED_REQUEST:
      return {
        ...state,
        razorPayPuchasedDataLoading: true,
        errorRazorPayPuchasedData: null,
      };
    case GET_RAZORPAY_BOOKED_SUCCESS:
      return {
        ...state,
        razorPayPuchasedDataLoading: false,
        razorPayPuchasedData: actions?.payload,
        errorRazorPayPuchasedData: null,
      };
    case GET_RAZORPAY_BOOKED_FAILURE:
      return {
        ...state,
        razorPayPuchasedDataLoading: false,
        errorRazorPayPuchasedData: actions?.payload,
      };
    case SET_PAID_ALL_BOOKED_PUJAS_BY_USER:
      return {
        ...state,
        paidBookedPujas: actions?.payload,
      };
    default:
      return state;
  }
}
