import React, { useState } from "react";
import "./style.css";
import mainLogo from "../../../assets/images/logo-main.svg";

const Banner = ({ data }: any) => {
  return (
    <div className="col-12">
      <iframe
        width="100%"
        height="580"
        src="https://www.youtube.com/embed/TvkbuIHndhk"
        allowFullScreen
        style={{ border: "0px solid #ccc" }}
      ></iframe>
    </div>
  );
};

export default Banner;
