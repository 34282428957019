import { Dispatch } from "redux";
import {
  GET_ASTROROCO_REQUEST,
  GET_ASTROROCO_SUCCESS,
  GET_ASTROROCO_FAILURE,
  SET_SELECTED_RASHI_OBJ,
} from "./astroReco.actionType";
// Assuming you have a Firebase utility file named fireBaseUtil
import fireBaseUtil from "../../../utils/fireBaseUtil";
import { getJsonAsync, getStringAsync } from "../../../lib/storage";
import { storageKeys } from "../../../constants";
import { ActionFunc, DispatchFunc } from "../../../definitions/actions";

export const getRashis =
  (isYearlySelect: any) => async (dispatch: Dispatch) => {
    dispatch({ type: GET_ASTROROCO_REQUEST });
    try {
      // Retrieve mobile number from AsyncStorage or any other source
      // Call Firebase utility function to get devotee data
      await fireBaseUtil
        ?.getRashiPhalaData(
          isYearlySelect ? `/rashi_phala` : `/rashi_phala_monthly`
        )
        .then((data) => {
          dispatch({ type: GET_ASTROROCO_SUCCESS, payload: data });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error: any) {
      dispatch({ type: GET_ASTROROCO_FAILURE, payload: error.message });
    }
  };

export function setSelectedRashi(payload: any): ActionFunc {
  return (dispatch: DispatchFunc) => {
    dispatch({
      type: SET_SELECTED_RASHI_OBJ,
      payload,
    });
  };
}
