import "./style.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import backArrow from "../../assets/images/back-arrow.svg";
import editIcon from "../../assets/images/editPencile.svg";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
import useAppDispatch from "../../hooks/useAppDispatch";
import { useAppAccessor } from "../../hooks";
import { getKartaList } from "../Profile/profile.action";
import { NakshatraData, rashiData } from "../../constants/dataConstants";
import { toast } from "react-toastify";
import { generateUniqueId } from "../../utils/GlobalFunctions";
import { getStringAsync } from "../../lib/storage";
import fireBaseUtil from "../../utils/fireBaseUtil";
import { GET_SEVA_KARTA_SUCCESS } from "../Profile/profile.actionType";

const SevaKartaDetails = () => {
  const dispatch = useAppDispatch();

  const { getProfileProps } = useAppAccessor();
  const { seveKartaData } = getProfileProps();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isShowEditProfile, setIsShowEditProfile] = useState(false);
  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [prefix, setPrefix] = useState("");
  const [mobile, setMobile] = useState("");
  const [gotra, setGotra] = useState("");
  const [birthday, setBirthday] = useState("");
  const [timeOfBirth, setTimeOfBirth] = useState("");
  const [birthLocation, setBirthLocation] = useState("");
  const [email, setEmail] = useState("");
  const [selectedNakshatram, setSelectedNakshatram] = useState("");
  const [selectedRashi, setSelectedRashi] = useState("");
  const [oldData, setOldData] = useState<any>({});
  const [gender, setGender] = useState("");

  useEffect(() => {
    dispatch(getKartaList());
  }, []);

  const onClickSave = () => {
    if (userName == "") {
      toast.error("Please enter the name");
    } else if (selectedRashi == "") {
      toast.error("Please select the rashi");
    } else if (gotra == "") {
      toast.error("Please enter the gotra");
    } else {
      let userData: any = {
        userName: userName || "",
        first_name: firstName || "",
        last_name: lastName || "",
        gotra: gotra || "",
        rashi: selectedRashi || "",
        birth_day: birthday || "",
        birth_time: timeOfBirth || "",
        birth_location: birthLocation || "",
        nakshatra: selectedNakshatram || "",
        mobile: mobile || "",
      };
      setIsLoading(true);

      const isAlreadyData = seveKartaData?.sevaKartaList?.filter(
        (i: any) => oldData?.uniqueId && i?.uniqueId == oldData?.uniqueId
      );

      if (isEmpty(isAlreadyData)) {
        userData.uniqueId = generateUniqueId();
        let oldData: any = seveKartaData?.sevaKartaList || [];
        oldData.push(userData);

        checkIfUserExist(oldData);
        setIsShowEditProfile(false);
      } else {
        let finalData = seveKartaData?.sevaKartaList?.map((item: any) => {
          if (item?.uniqueId == oldData?.uniqueId)
            return { ...oldData, ...userData };
          else return item;
        });

        checkIfUserExist(finalData);
        setIsShowEditProfile(false);
      }
    }
  };

  const updateUserData = async (userData: any) => {
    const data: any = await getStringAsync("userDatas");

    await fireBaseUtil.updateFamilyData(
      JSON.parse(data)?.phoneNumber,
      "seva_karta_list",
      "sevaKartaList",
      userData
    );
  };

  const createUserData = async (userData: any) => {
    const data: any = await getStringAsync("userDatas");

    await fireBaseUtil.addNewFamilyUserRecord(
      JSON.parse(data)?.phoneNumber,
      "seva_karta_list",
      "sevaKartaList",
      userData
    );
  };

  const checkIfUserExist = async (payload: any) => {
    const data: any = await getStringAsync("userDatas");

    const isUserExist = await fireBaseUtil.checkIfUserExistsOrNot(
      `/seva_karta_list/${JSON.parse(data)?.phoneNumber}`
    );

    if (isUserExist) {
      updateUserData(payload);
      dispatch({
        type: GET_SEVA_KARTA_SUCCESS,
        payload: { sevaKartaList: payload },
      });

      setIsLoading(false);
    } else {
      createUserData(payload);
      dispatch({
        type: GET_SEVA_KARTA_SUCCESS,
        payload: { sevaKartaList: payload },
      });
      setIsLoading(false);
    }
  };

  const onEditPress = (data: any) => {
    setOldData(data);
    setUserName(data?.userName || "");
    setFirstName(data?.first_name || "");
    setMiddleName(data?.middle_name || "");
    setLastName(data?.last_name || "");
    setPrefix(data?.prefix || "");
    setGender(data?.gender || "");
    setMobile(data?.mobile || "");
    setGotra(data?.gotra || "");
    setBirthday(data?.birth_day || "");
    setTimeOfBirth(data?.birth_time || "");
    setBirthLocation(data?.birth_location || "");
    setSelectedNakshatram(data?.nakshatra || "");
    setSelectedRashi(data?.rashi || "");
    setIsShowEditProfile(true);
  };

  const onPressAddSevaKarta = () => {
    setOldData({});
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setPrefix("");
    setGender("");
    setMobile("");
    setGotra("");
    setBirthday("");
    setTimeOfBirth("");
    setBirthLocation("");
    setSelectedNakshatram("");
    setSelectedRashi("");
    setUserName("");
    setIsShowEditProfile(true);
  };

  const handleChange = (event: any) => {
    setSelectedRashi(event.target.value);
  };

  const handleNakshatramChange = (event: any) => {
    setSelectedNakshatram(event.target.value);
  };
  return (
    <div>
      <Header />
      <div className="sevaKertaDetails">
        <div className="container">
          <div className="row">
            {/*----- Back Button & Title -----*/}
            <div className="col-12 col-lg-9 col-xl-9 col-md-9 col-sm-9">
              <div className="flex">
                <img
                  onClick={() => navigate(-1)}
                  className="back-arrow-icon"
                  id="backButton"
                  src={backArrow}
                  alt="Back"
                />
                <span className="our-offerings">Seva Karta Details</span>
              </div>
            </div>

            {/*----- Add Button -----*/}
            <div
              className="col-12 col-lg-3 col-xl-3 col-md-3 col-sm-3"
              style={{ cursor: "pointer" }}
              onClick={onPressAddSevaKarta}
            >
              <div className="d-flex flex-row-reverse">
                <button className="btn btn-primary book-puja-w">
                  + Add Seva Karta
                </button>
              </div>
            </div>
          </div>

          {/* list */}
          <div className="container">
            <div className="row mb-5 mt-3">
              {/*----- Card 1 -----*/}
              {seveKartaData?.sevaKartaList?.map((item: any) => {
                return (
                  <div className="col-12 col-lg-3 col-xl-3 col-md-3 col-sm-3 pl-inherit">
                    <div className="puja-card">
                      <div className="row">
                        <div className="col-10">
                          <p className="puja-title"> {item?.userName || ""}</p>
                          <p className="puja-description">
                            {" "}
                            {item?.gotra || ""}
                          </p>
                          <p className="puja-description">
                            {item?.rashi || ""} | {item?.nakshatra || ""}
                          </p>
                        </div>
                        <div
                          className="col-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => onEditPress(item)}
                        >
                          <p>
                            {" "}
                            <img
                              className="editIconCoursor"
                              src={editIcon}
                              alt="edit"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/*----- Add Seva Karta Details Modal Popup -----*/}
        {isShowEditProfile && (
          <div className="popup">
            <div className="popup-inner container edit-modal">
              <div className="row">
                {/*----- Back Button & Title -----*/}
                <div className="col-xs-12 col-md-6 col-sm-6">
                  <div className="flex">
                    <img
                      onClick={() => setIsShowEditProfile(false)}
                      className="back-arrow-icon"
                      id="backButton"
                      src={backArrow}
                      alt="Back"
                    />
                    <span className="edit-personal">
                      {"Add Seva Karta Details"}
                    </span>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-sm-6"></div>

                {/*----- Message -----*/}
                <div className="col-xs-12 col-md-6 col-sm-6"></div>
                <div className="col-xs-12 col-md-6 col-sm-6 Profilepl-0">
                  <p className="edit-message">
                    {
                      "Below fields are optional. Why answer all this? They help us provide accurate information curated for you."
                    }
                  </p>
                </div>
                <div className="pl-3 row">
                  {/*----- User Name -----*/}
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <input
                        value={userName}
                        type="text"
                        className="form-control"
                        id="username"
                        onChange={(e) => setUserName(e.target.value)}
                      />
                      <label className="form-label">Name*</label>
                    </div>
                  </div>

                  {/*----- Rashi -----*/}
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <select
                        id="rashiDropdown"
                        className="form-control with-arrow"
                        onChange={handleChange}
                        value={selectedRashi}
                      >
                        {rashiData?.map((i: any) => {
                          return <option value={i}>{i}</option>;
                        })}
                      </select>
                      <label className="form-label">Select Rashi*</label>
                    </div>
                  </div>

                  {/*----- Gotra -----*/}
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <input
                        value={gotra || ""}
                        type="text"
                        className="form-control"
                        id="gotra"
                        onChange={(e) => setGotra(e.target.value)}
                      />
                      <label className="form-label">Gotra*</label>
                    </div>
                    <p className="grotraNO">
                      ( If you don't know your Gotra, please add 'Do not know'
                      ) 
                    </p>
                  </div>

                  {/*----- Birthday -----*/}
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <input
                        value={birthday || ""}
                        type="date"
                        className="form-control"
                        id="birthday"
                        onChange={(e) => setBirthday(e.target.value)}
                      />
                      <label className="form-label">Birthday</label>
                    </div>
                  </div>

                  {/*----- Time of Birth -----*/}
                  <div className="col-xs-12 col-md-6 col-sm-6"></div>
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <input
                        value={timeOfBirth || ""}
                        type="time"
                        className="form-control"
                        id="timeofbirth"
                        onChange={(e) => setTimeOfBirth(e.target.value)}
                      />
                      <label className="form-label">Time of Birth</label>
                    </div>
                  </div>

                  {/*----- Birth Location -----*/}
                  <div className="col-xs-12 col-md-6 col-sm-6"></div>
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <input
                        value={birthLocation || ""}
                        type="text"
                        className="form-control"
                        id="birthlocation"
                        onChange={(e) => setBirthLocation(e.target.value)}
                      />
                      <label className="form-label">Birth Location</label>
                    </div>
                  </div>

                  {/*----- Select Nakshatram -----*/}
                  <div className="col-xs-12 col-md-6 col-sm-6"></div>
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <div className="form-group">
                      <select
                        id="nakshatramDropdown"
                        className="form-control with-arrow"
                        onChange={handleNakshatramChange}
                        value={selectedNakshatram}
                      >
                        {NakshatraData?.map((i: any) => {
                          return <option value={i}>{i}</option>;
                        })}
                      </select>
                      <label className="form-label">Select Nakshatram</label>
                    </div>
                  </div>
                </div>
              </div>

              {/*----- Save Button -----*/}
              <div className="row mt-3">
                <div className="col-xs-12 col-md-6 col-sm-6"></div>
                <div className="col-xs-12 col-md-6 col-sm-6">
                  <div className="text-center my-3">
                    <button
                      onClick={onClickSave}
                      className="btn btn-primary pd-8-40"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div style={{ paddingTop: 250 }}>
        <Footer />
      </div>
    </div>
  );
};

export default SevaKartaDetails;
