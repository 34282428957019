import React, { useEffect, useState } from "react";
import "./style.css";

import { isEmpty } from "lodash";
import mainLogo from "../../assets/images/logo-main.svg";
import { useLocation, useNavigate } from "react-router-dom";

import { getStringAsync } from "../../lib/storage";
import { ToastContainer, toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import fireBaseUtil from "../../utils/fireBaseUtil";
import useAppDispatch from "../../hooks/useAppDispatch";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../config/firebaseConfig";
import { setBookedAllPujasList } from "../MakePayment/booking.actions";
import { useAppAccessor } from "../../hooks";
import Popup from "../Common/LoginModal";
import Loader from "../Common/Loader";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getBookingProps, getProfileProps } = useAppAccessor();
  const { allBookedPujas } = getBookingProps();
  const [showPopup, setShowPopup] = useState(false);

  const [userDetails, setUserDetails] = useState();
  const [phoneNumber, setPhoneNumber] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [appVerifier, setAppVerifier] = useState<any>(null);
  const [isSendOTP, setIsSendOTP] = useState(false);
  const [isShowPersonalDetailsPopUp, setIsShowPersonalDetailsPopUp] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState<any>(null);
  const [mobile, setMobile] = useState("");

  const [userData, setUserData] = useState<any>(null);
  const [time, setTime] = useState(2 * 15);

  useEffect(() => {
    getUserData();
    getBookedPujasByuser();
  }, []);


  useEffect(() => {
    if (time > 0) {
      const timerId = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [time]);

  const getBookedPujasByuser = async () => {
    const mobileNum: any = await getStringAsync("userDatas");

    await fireBaseUtil
      .getBookedPujaById(`/booked_pujas/${JSON.parse(mobileNum)?.phoneNumber}`)
      .then((data: any) => {
        dispatch(setBookedAllPujasList(data?.booked_puja_data || []));
      })
      .catch((err: any) => { });
  };

  const getUserData = async () => {
    const data: any = await getStringAsync("userDatas");
    if (data) {
      setUserDetails(JSON.parse(data));
    }
  };
  const handleLogout = () => {
    setUserDetails(undefined);
    localStorage.removeItem("userDatas");
    navigate("/");
    toast.success("Logged out successfully");
  };

  const createCaptchContainer = () => {
    const existingCaptcha = document.getElementById("recaptcha-container");
    if (existingCaptcha) {
      console.log("Captcha container already exists");
      return existingCaptcha;
    } else {
      const captchaElement = document.createElement("div");
      captchaElement.style.visibility = "hidden"; // Example style
      captchaElement.id = "recaptcha-container"; // Example id
      const rootElement = document.getElementById("root");
      if (rootElement) {
        rootElement.appendChild(captchaElement);
        console.log("Captcha container created");
        return captchaElement;
      } else {
        console.error("Root element not found");
        return null;
      }
    }
  };

  const generateRecaptcha = () => {
    createCaptchContainer();
    const recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response: any) => { },
        "expired-callback": () => { },
      }
    );
    return recaptchaVerifier;
  };


  const handleFireBaseSendCode = (phNumber: any) => {
    setIsLoading(true)
    let captchVerifier = appVerifier;
    if (appVerifier === null) {
      captchVerifier = generateRecaptcha();
      setAppVerifier(captchVerifier);
    }
    signInWithPhoneNumber(auth, `${phNumber}`, captchVerifier)
      .then((confirmationResult: any) => {
        setIsLoading(false)
        setIsSendOTP(true);
        setTime(2 * 15);
        setConfirmationResult(confirmationResult);
        // setShowPopup(false);
      })
      .catch((error) => {
        setIsLoading(false)
        if (error.code === "auth/too-many-requests") {
          toast.warn("Phone number blocked due to unusual activity. Please try again later.");
        }
      });
  };

  const handleVerifyCode = (verificationCode: any) => {
    setIsLoading(true)

    confirmationResult
      ?.confirm(verificationCode)
      ?.then(async (result: any) => {
        setIsLoading(false)
        if (result?.user) {
          //success
          setUserData(result?.user);
          setShowPopup(false);


          const isUserExists = await fireBaseUtil.checkIfUserExistsOrNot(
            `/devotees/${result?.user?.phoneNumber}`
          );
          setPhoneNumber(result?.user?.phoneNumber)

          if (isUserExists) {
            localStorage.setItem("userDatas", JSON.stringify(result?.user || {}));
            if(location.pathname){
              window.location.href = window.location.href;
              navigate(location.pathname);
            }else{
              navigate("/");
            }
          } else {
            setMobile(result?.user?.phoneNumber)
            setIsShowPersonalDetailsPopUp(true)
          }

          toast.success("OTP Verified Successfully");
        }
      })
      .catch((error: any) => {
        setIsLoading(false)
        toast.error("OTP is Not Verified");
      });
  };

  const handleResendOTP = (phNumber: any) => {
    let captchVerifier = appVerifier;
    if (!appVerifier) {
      captchVerifier = generateRecaptcha();
      setAppVerifier(captchVerifier);
    }
    signInWithPhoneNumber(auth, `${phNumber}`, captchVerifier)
      .then((confirmationResult: any) => {
        setConfirmationResult(confirmationResult);

        setTime(2 * 15);
        toast.success("OTP Resent Successfully");
      })
      .catch((error) => {
        if (error.code === "auth/too-many-requests") {
          toast.warn("Phone number blocked due to unusual activity. Please try again later.");
        } else {
          toast.error("Failed to Resend OTP");
        }
      });
  };

  const togglePopup = () => {
    if (!showPopup) {
      let captchVerifier = appVerifier;
      if (appVerifier === null) {
        captchVerifier = generateRecaptcha();
        setAppVerifier(captchVerifier);
      }
    }
    setShowPopup(!showPopup);
  };

  return (
    <header className="header">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-dark">
          <a
            className="navbar-brand"
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          >
            <img src={mainLogo} alt="logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <a
                  style={{ color: "#FFE000" }}
                  target="_blank"
                  className="nav-link"
                  aria-current="page"
                  href="https://play.google.com/store/apps/details?id=com.divinedeeds"
                >
                  Download the App Now
                </a>
              </li>

              <li className="nav-item">
                <a
                  className={
                    location.pathname == "/home"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  aria-current="page"
                  style={{ color: "#FFF", cursor: "pointer" }}
                  onClick={() => navigate("/")}
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  style={{ cursor: "pointer", color: "#FFF" }}
                  className={
                    location.pathname == "/allpuja"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  onClick={() => navigate("/allpuja")}
                >
                  Pujas
                </a>
              </li>
              <li className="nav-item">
                <a
                  style={{ cursor: "pointer", color: "#FFF" }}
                  className={
                    location.pathname == "/livestram"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  onClick={() => navigate("/livestram")}
                >
                  Livestream
                </a>
              </li>

              {/* <li className="nav-item">
                <a
                  style={{ cursor: "pointer", color: "#FFF" }}
                  className={
                    location.pathname == "/product"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  onClick={() => navigate("/product")}
                >
                  Products
                </a>
              </li> */}
              {!isEmpty(userDetails) && (
                <li className="nav-item">
                  <a
                    style={{ cursor: "pointer", color: "#FFF" }}
                    className={
                      location.pathname == "/make-payment"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    onClick={() => navigate("/make-payment")}
                  >
                    Cart {allBookedPujas?.length ? <sup>{allBookedPujas?.length}</sup> : ''}
                  </a>
                </li>
              )}
              {!isEmpty(userDetails) && (
                <li className="nav-item">
                  <a
                    style={{ color: "#FFF", cursor: "pointer" }}
                    className={
                      location.pathname == "/profile"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    onClick={() => navigate("/profile")}
                  >
                    Profile
                  </a>
                </li>
              )}
              {!isEmpty(userDetails) && (
                <li className="nav-item">
                  <a
                    style={{ cursor: "pointer", color: "#FFF" }}
                    className="nav-link"
                    onClick={() => handleLogout()}
                  >
                    Logout
                  </a>
                </li>
              )}
              {isEmpty(userDetails) && (
                <li className="nav-item">
                  <a
                    style={{ cursor: "pointer", color: "#FFF" }}
                    className="nav-link"
                    onClick={() => togglePopup()}
                  >
                    Login
                  </a>
                </li>
              )}
            </ul>
          </div>
        </nav>
        {showPopup && (
          <Popup
            isLoading={isLoading}
            recatchaId={"recaptcha-container"}
            onClose={togglePopup}
            data={userData}
            handleOtpCall={(phoneNumber: any) => {
              handleFireBaseSendCode(phoneNumber);
            }}
            handleVerifiyOtp={(code: any) => {
              handleVerifyCode(code);
            }}
            handleResendOtp={(phoneNumber: any) => {
              handleResendOTP(phoneNumber);
            }}
            isSendOTP={isSendOTP}
            time={time}
          />
        )}
      </div>
    </header>
  );
};

export default Header;
