import { ActionFunc, DispatchFunc } from "../../definitions/actions";
import {
  Clear_Multiple_VEIDC_OBJECT,
  SET_ALL_PUJAS_DATA,
  SET_ALL_TEMPLE_DATA,
  SET_Multiple_VEIDC_OBJECT,
  SET_SELECTED_PUJA_OBJ,
  SET_SELECTED_TEMPLE_OBJ,
} from "./home.actionsType";

export function setAllPujasDataList(payload: any): ActionFunc {
  return (dispatch: DispatchFunc) => {
    dispatch({
      type: SET_ALL_PUJAS_DATA,
      payload,
    });
  };
}

export function setAllTempleDataList(payload: any): ActionFunc {
  return (dispatch: DispatchFunc) => {
    dispatch({
      type: SET_ALL_TEMPLE_DATA,
      payload,
    });
  };
}

export function clearVedicSignDataList(payload: any): ActionFunc {
  return (dispatch: DispatchFunc) => {
    dispatch({
      type: Clear_Multiple_VEIDC_OBJECT,
      payload,
    });
  };
}

export function setSelectedPujaObj(payload: any): ActionFunc {
  return (dispatch: DispatchFunc) => {
    dispatch({
      type: SET_SELECTED_PUJA_OBJ,
      payload,
    });
  };
}

export function setSelectedTempleObj(payload: any): ActionFunc {
  return (dispatch: DispatchFunc) => {
    dispatch({
      type: SET_SELECTED_TEMPLE_OBJ,
      payload,
    });
  };
}

export function setVedicSingleObj(payload: any): ActionFunc {
  return (dispatch: DispatchFunc) => {
    dispatch({
      type: SET_Multiple_VEIDC_OBJECT,
      payload,
    });
  };
}
