import React, { useEffect, useState } from "react";
import Header from "../Header";
import AllPujaList from "./AllPujaList";
import useAppDispatch from "../../hooks/useAppDispatch";
import "./AllPujaList/style.css";
import { useAppAccessor } from "../../hooks";
import moment from "moment";
import fireBaseUtil from "../../utils/fireBaseUtil";
import {
  setAllPujasDataList,
  setAllTempleDataList,
} from "../Home/home.actions";
import Footer from "../Footer";
import { getStringAsync } from "../../lib/storage";
import { setPaidBookedAllPujasList } from "../MakePayment/booking.actions";

const AllPuja = () => {
  const dispatch = useAppDispatch();
  const { getHomeProps, getBookingProps } = useAppAccessor();

  const { allPujaData } = getHomeProps();

  const { allBookedPujas, paidBookedPujas } = getBookingProps();

  const [currentMonthPujas, setCurrentMonthPujas] = useState<any>([]);
  const [nextMonthPujas, setNextMonthPujas] = useState<any>([]);
  const [recomndedPujas, setRecomndedPujas] = useState<any>([]);

  const [thisMonth, setThisMonth] = useState(true);
  const [recommended, setRecommended] = useState(false);
  const [aprilMonth, setAprilMonth] = useState(false);

  const handleThisMonthChange = () => {
    setThisMonth(true);
    setRecommended(false);
    setAprilMonth(false);
  };

  const handleRecommendedChange = () => {
    setRecommended(true);
    setThisMonth(false);
    setAprilMonth(false);
  };

  const handleAprilMonthChange = () => {
    setAprilMonth(true);
    setThisMonth(false);
    setRecommended(false);
  };

  useEffect(() => {
    getAllPujas();
    getAllTemples();
    getPaidBookedPujasByuser();
  }, []);

  const getPaidBookedPujasByuser = async () => {
    const mobileNum: any = await getStringAsync("userDatas");

    await fireBaseUtil
      .getBookedPujaById(
        `/paid_booked_pujas/${JSON.parse(mobileNum)?.phoneNumber}`
      )
      .then((data: any) => {
        dispatch(setPaidBookedAllPujasList(data?.booked_puja_data || []));
      })
      .catch((err: any) => {});
  };

  const getAllTemples = async () => {
    await fireBaseUtil
      .getTemplsData()
      .then((data: any) => {
        dispatch(setAllTempleDataList(data || []));
      })
      .catch((err) => {});
  };

  const getAllPujas = async () => {
    await fireBaseUtil?.getPujasData().then((data: any) => {
      dispatch(setAllPujasDataList(data));
    });
  };

  useEffect(() => {
    allPujaData.forEach((element) => {
      console.log(
        "currentMonth===>FORMATTED",
        moment(element.dateFormated).month() + 1
      );
    });

    setCurrentMonthPujas(
      allPujaData.filter(
        (ele) => moment(ele.dateFormated).month() + 1 == moment().month() + 1
      )
    );
  }, []);

  useEffect(() => {
    if (allPujaData.length > 0) {
      setCurrentMonthPujas(
        allPujaData.filter(
          (ele) =>
            ele.daysRemaining > 0 ||
            (ele.daysRemaining == 0 &&
              new Date(
                ele?.live_puja_endTime?.seconds * 1000 +
                  ele?.live_puja_endTime?.nanoseconds / 1000000
              ).getTime() >= new Date().getTime())
        )
      );

      setNextMonthPujas(
        allPujaData.filter(
          (ele) =>
            ele.daysRemaining < 0 ||
            (ele.daysRemaining == 0 &&
              new Date(
                ele?.live_puja_endTime?.seconds * 1000 +
                  ele?.live_puja_endTime?.nanoseconds / 1000000
              ).getTime() < new Date().getTime())
        )
      );
    }
  }, [allPujaData]);

  const getFallbackScreen = () => {
    if (thisMonth) {
      if (currentMonthPujas.length == 0) {
        return true;
      } else {
        return false;
      }
    } else if (recommended) {
      if (recomndedPujas.length == 0) {
        return true;
      } else {
        return false;
      }
    } else if (aprilMonth) {
      if (nextMonthPujas.length == 0) {
        return true;
      } else {
        return false;
      }
    }
  };
  return (
    <div>
      <Header />

      <div className="container">
        <div className="row">
          {/*----- All Pujas -----*/}
          <p className="all-puja-title pl-80-pt-20">All Pujas</p>

          {/*----- Tab bar Pujas -----*/}
          <div className="container">
            <div className="row tab-btn">
              <button
                className={
                  thisMonth === true ? " active-tab" : "deactivate-tab"
                }
                onClick={handleThisMonthChange}
              >
                {"This Month"}
              </button>
              <button
                className={
                  recommended === true ? " active-tab" : "deactivate-tab"
                }
                onClick={handleRecommendedChange}
              >
                {"Recommended"}
              </button>
              <button
                className={
                  aprilMonth === true ? " active-tab" : "deactivate-tab"
                }
                onClick={handleAprilMonthChange}
              >
                {"Past Pujas"}
              </button>
            </div>
          </div>

          {/*----- Puja List -----*/}
          {thisMonth === true && <AllPujaList data={currentMonthPujas} />}

          {/*----- Puja List -----*/}
          {recommended === true && <AllPujaList data={[]} />}

          {/*----- Puja List -----*/}
          {aprilMonth === true && <AllPujaList data={nextMonthPujas} />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AllPuja;
