import React, { useState } from "react";
import "./style.css";
import Temple1 from "../../../assets/images/temple-1.svg";
import Temple2 from "../../../assets/images/temple-2.svg";
import Temple3 from "../../../assets/images/temple-3.svg";

const Banner = ({ data }: any) => {
  return (
    <div className="temple-page">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-9 col-sm-9 bigImg">
            <img
              className="img-w-100 temple-img-h"
              src={data?.image_main || ""}
              alt="Temple1"
            />
          </div>
          <div className="col-xs-12 col-md-3 col-sm-3 imageTop">
            {data?.images?.slice(0, 2)?.map((i: any) => {
              return (
                <img
                  className="img-w-100 sub-temple-img-h"
                  src={i || ""}
                  alt="Temple2"
                  
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
