import React, { useEffect, useState } from "react";
import Select from "react-dropdown-select";
import "./style.css";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { getRashis } from "../../AstroRecommendation/Reducer/astroReco.action";
import { useAppAccessor } from "../../../hooks";

const options: any = [
  { label: "Mesha (Aries)", value: "Mesha (Aries)" },
  { label: "Vrishabha (Taurus)", value: "Vrishabha (Taurus)" },
  { label: "Mithuna (Gemini)", value: "Mithuna (Gemini)" },
  { label: "Karka (Cancer)", value: "Karka (Cancer)" },
  { label: "Simha (Leo)", value: "Simha (Leo)" },
  { label: "Kanya (Virgo)", value: "Kanya (Virgo)" },
  { label: "Tula (Libra)", value: "Tula (Libra)" },
  { label: "Vrishchika (Scorpio)", value: "Vrishchika (Scorpio)" },
  { label: "Dhanu (Sagitarius)", value: "Dhanu (Sagitarius)" },
  { label: "Makara (Capricon)", value: "Makara (Capricon)" },
  { label: "Kumbha (Aquarius)", value: "Kumbha (Aquarius)" },
  { label: "Meena (Pisces)", value: "Meena (Pisces)" },
];

const SelectDropdown = ({ isYearlySelect, setRashi }: any) => {
  const dispatch = useAppDispatch();
  const { getAsgroRocoProps } = useAppAccessor();

  const { rashiData } = getAsgroRocoProps();

  const [selectedValue, setSelectedValue] = useState("");
  const [rashiDataArray, setRashiDataArray] = useState([]);

  useEffect(() => {
    setSelectedValue("");
    dispatch(getRashis(isYearlySelect));
  }, [isYearlySelect]);

  useEffect(() => {
    const updatedArray = rashiData.labelArray?.map((sign: any) => {
      const [label, id] = sign.split("_");
      const capitalizedLabel = label.charAt(0).toUpperCase() + label.slice(1);
      if (capitalizedLabel === "Dhani") {
        return { label: "Dhanu (Sagitarius)", value: sign };
      }
      const matchingOption = options.find((option: any) =>
        option.label.includes(capitalizedLabel)
      );
      return {
        label: matchingOption ? matchingOption.label : capitalizedLabel,
        value: sign,
      };
    });

    let finalData: any = [];

    options?.map((k: any) => {
      updatedArray?.map((uItem: any) => {
        if (k?.label == uItem?.label) {
          finalData?.push(uItem);
        }
      });
    });

    setRashiDataArray(finalData);
  }, [rashiData.labelArray]);

  const handleChange = (selectedValues: any) => {
    setSelectedValue(selectedValues.label);
    setRashi(selectedValues);
  };

  return (
    <div>
      <Select
        options={rashiDataArray}
        onChange={handleChange}
        values={[]}
        placeholder="Select your rashi from the list"
      />
    </div>
  );
};

export default SelectDropdown;
