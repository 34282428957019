import React, { useState, useEffect } from "react";

import "./style.css";
import mainLogo from "../../../assets/images/logo-main.svg";
import popupLogo from "../../../assets/images/popupLogo.svg";
import DownloadApp from "../../../assets/images/DownloadApp.svg";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../config/firebaseConfig";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import backArrow from "../../../assets/images/back-arrow.svg";
import {
  NakshatraData,
  genderData,
  preFixDataArray,
  rashiData,
} from "../../../constants/dataConstants";
import fireBaseUtil from "../../../utils/fireBaseUtil";
import Popup from "../../Common/LoginModal";
import Loader from "../../Common/Loader";

const Banner = () => {
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const [appVerifier, setAppVerifier] = useState<any>(null);
  const [isSendOTP, setIsSendOTP] = useState(false);
  const [isShowPersonalDetailsPopUp, setIsShowPersonalDetailsPopUp] =
    useState(false);
  const [confirmationResult, setConfirmationResult] = useState<any>(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [userData, setUserData] = useState<any>(null);
  const [time, setTime] = useState(2 * 15);

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [gotra, setGotra] = useState("");
  const [birthday, setBirthday] = useState<any>();
  const [timeOfBirth, setTimeOfBirth] = useState("");
  const [birthLocation, setBirthLocation] = useState("");
  const [email, setEmail] = useState("");
  const [selectedNakshatram, setSelectedNakshatram] = useState("");
  const [service, setService] = useState("");
  const [gender, setGender] = useState("");
  const [prefix, setPrefix] = useState("");

  useEffect(() => {
    getStorageData();
  }, []);

  const getStorageData = () => {
    let user: any = localStorage.getItem("userDatas");
    if (user) {
      setUserData(JSON?.parse(user));
    }
  };

  const createCaptchContainer = () => {
    const captchaElement = document.createElement("div");
    captchaElement.style.visibility = "hidden"; // Example style
    captchaElement.id = "recaptcha-container"; // Example id
    const rootElement = document.getElementById("Root");
    if (rootElement) rootElement.appendChild(captchaElement);
  };

  const generateRecaptcha = () => {
    createCaptchContainer();
    const recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response: any) => { },
        "expired-callback": () => { },
      }
    );
    return recaptchaVerifier;
  };

  const handleFireBaseSendCode = (phNumber: any) => {
    setIsLoading(true);
    let captchVerifier = appVerifier;
    if (appVerifier === null) {
      captchVerifier = generateRecaptcha();
      setAppVerifier(captchVerifier);
    }
    signInWithPhoneNumber(auth, `${phNumber}`, captchVerifier)
      .then((confirmationResult: any) => {
        setIsLoading(false);
        setIsSendOTP(true);
        setTime(2 * 15);
        setConfirmationResult(confirmationResult);
        // setShowPopup(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.code === "auth/too-many-requests") {
          toast.warn("Phone number blocked due to unusual activity. Please try again later.");
        }
      });
  };

  const handleVerifyCode = (verificationCode: any) => {
    setIsLoading(true);

    confirmationResult
      ?.confirm(verificationCode)
      ?.then(async (result: any) => {
        setIsLoading(false);
        if (result?.user) {
          //success
          setUserData(result?.user);
          setShowPopup(false);

          const isUserExists = await fireBaseUtil.checkIfUserExistsOrNot(
            `/devotees/${result?.user?.phoneNumber}`
          );
          setPhoneNumber(result?.user?.phoneNumber);

          if (isUserExists) {
            localStorage.setItem(
              "userDatas",
              JSON.stringify(result?.user || {})
            );
            navigate("/");
          } else {
            setMobile(result?.user?.phoneNumber);
            setIsShowPersonalDetailsPopUp(true);
          }

          toast.success("OTP Verified Successfully");
        }
      })
      .catch((error: any) => {
        setIsLoading(false);
        toast.error("OTP is Not Verified");
      });
  };

  const handleResendOTP = (phNumber: any) => {
    let captchVerifier = appVerifier;
    if (!appVerifier) {
      captchVerifier = generateRecaptcha();
      setAppVerifier(captchVerifier);
    }
    signInWithPhoneNumber(auth, `${phNumber}`, captchVerifier)
      .then((confirmationResult: any) => {
        setConfirmationResult(confirmationResult);

        setTime(2 * 15);
        toast.success("OTP Resent Successfully");
      })
      .catch((error) => {
        if (error.code === "auth/too-many-requests") {
          toast.warn("Phone number blocked due to unusual activity. Please try again later.");
        } else {
          toast.error("Failed to Resend OTP");
        }
      });
  };
  const togglePopup = () => {
    if (!showPopup) {
      let captchVerifier = appVerifier;
      if (appVerifier === null) {
        captchVerifier = generateRecaptcha();
        setAppVerifier(captchVerifier);
      }
    }
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    if (time > 0) {
      const timerId = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [time]);

  const onClickSave = async () => {
    if (firstName == "") {
      toast.error("Please enter the first name");
    } else if (lastName == "") {
      toast.error("Please enter the last name");
    } else {
      let userObj: any = {
        first_name: firstName || "",
        middle_name: middleName || "",
        last_name: lastName || "",
        gender: gender || "",
        mobile: mobile || "",
        gotra: gotra || "",
        rashi: service || "",
        birth_day: birthday || "",
        birth_time: timeOfBirth || "",
        birth_location: birthLocation || "",
        nakshatra: selectedNakshatram || "",
        email: email || "",
        prefix: prefix || "",
      };

      await fireBaseUtil.addDocumentWithCustomID(phoneNumber, userObj);
      localStorage.setItem("userDatas", JSON.stringify(userData || {}));
      setIsShowPersonalDetailsPopUp(false);
      navigate("/");
    }
  };

  return (
    <div className="banner" id="Root">
      {isLoading && <Loader />}
      <div className="container">
        <div className="div-text-section">
          <h1 className="banner-title">
            Start your online spiritual
            <br />
            journey with
            <img src={mainLogo} alt="logo" />
          </h1>
          {isEmpty(userData) && (
            <div className="book-puja-btn-div">
              <button
                className="btn btn-primary book-puja-btn-w"
                onClick={togglePopup}
              >
                Book A Puja
              </button>
            </div>
          )}
        </div>
        <div className="text-center">
          <a
            href="https://play.google.com/store/apps/details?id=com.divinedeeds"
            target="_blank"
          >
            <img src={DownloadApp} />
            {/* <img src="assets/images/DownloadApp.svg" /> */}
          </a>
        </div>
      </div>
      {showPopup && (
        <Popup
          isLoading={isLoading}
          recatchaId={"recaptcha-container"}
          onClose={togglePopup}
          data={userData}
          handleOtpCall={(phoneNumber: any) => {
            handleFireBaseSendCode(phoneNumber);
          }}
          handleVerifiyOtp={(code: any) => {
            handleVerifyCode(code);
          }}
          handleResendOtp={(phoneNumber: any) => {
            handleResendOTP(phoneNumber);
          }}
          isSendOTP={isSendOTP}
          time={time}
        />
      )}

      {isShowPersonalDetailsPopUp && (
        <div className="popup">
          <div className="popup-inner container edit-modal">
            <div className="row">
              <div className="col-xs-12 col-md-6 col-sm-6">
                <div className="flex">
                  <img
                    onClick={() => setIsShowPersonalDetailsPopUp(false)}
                    className="back-arrow-icon"
                    id="backButton"
                    src={backArrow}
                    alt="Back"
                  />
                  <span className="edit-personal">{"Personal Details"}</span>
                </div>
              </div>
              <div className="col-xs-12 col-md-6 col-sm-6"></div>

              {/*----- Message -----*/}
              <div className="col-xs-12 col-md-6 col-sm-6"></div>
              <div className="col-xs-12 col-md-6 col-sm-6">
                <p className="edit-message">
                  {
                    "Below fields are optional. Why answer all this? They help us provide accurate information curated for you."
                  }
                </p>
              </div>

              {/*----- Name & Gotra -----*/}
              <div className="col-xs-12 col-md-6 col-sm-6">
                <div className="form-group">
                  <select className="form-control with-arrow">
                    {preFixDataArray?.map((i: any) => {
                      return <option value={prefix}>{i}</option>;
                    })}
                  </select>
                  <label className="form-label">Select Prefix</label>
                </div>
              </div>
              <div className="col-xs-12 col-md-6 col-sm-6">
                <div className="form-group">
                  <input
                    value={gotra || ""}
                    type="text"
                    className="form-control"
                    id="gotra"
                    onChange={(e) => setGotra(e.target.value)}
                  />
                  <label className="form-label">Gotra</label>
                </div>
              </div>

              {/*----- Phone Number & Select Rashi -----*/}
              <div className="col-xs-12 col-md-6 col-sm-6">
                <div className="form-group">
                  <input
                    value={firstName}
                    type="text"
                    className="form-control"
                    id="username"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <label className="form-label">First Name*</label>
                </div>
              </div>
              <div className="col-xs-12 col-md-6 col-sm-6">
                <div className="form-group">
                  <select
                    id="rashiDropdown"
                    className="form-control with-arrow"
                  >
                    {rashiData?.map((i: any) => {
                      return <option value={i}>{i}</option>;
                    })}
                  </select>
                  <label className="form-label">Select Rashi</label>
                </div>
              </div>

              {/*----- Birthday -----*/}
              <div className="col-xs-12 col-md-6 col-sm-6">
                <div className="form-group">
                  <input
                    value={middleName || ""}
                    type="text"
                    className="form-control"
                    id="middleName"
                    onChange={(e) => setMiddleName(e.target.value)}
                  />
                  <label className="form-label">Middle Name *</label>
                </div>
              </div>
              <div className="col-xs-12 col-md-6 col-sm-6">
                <div className="form-group">
                  <input
                    value={birthday || ""}
                    type="date"
                    className="form-control"
                    id="birthday"
                    onChange={(e) => setBirthday(e.target.value)}
                  />
                  <label className="form-label">Birthday</label>
                </div>
              </div>

              {/*----- Time of Birth -----*/}
              <div className="col-xs-12 col-md-6 col-sm-6">
                <div className="form-group">
                  <input
                    value={lastName || ""}
                    type="text"
                    className="form-control"
                    id="lastname"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <label className="form-label">Last Name *</label>
                </div>
              </div>
              <div className="col-xs-12 col-md-6 col-sm-6">
                <div className="form-group">
                  <input
                    value={timeOfBirth || ""}
                    type="time"
                    className="form-control"
                    id="timeofbirth"
                    onChange={(e) => setTimeOfBirth(e.target.value)}
                  />
                  <label className="form-label">Time of Birth</label>
                </div>
              </div>

              {/*----- Birth Location -----*/}
              <div className="col-xs-12 col-md-6 col-sm-6">
                <div className="form-group">
                  <input
                    value={mobile}
                    type="text"
                    className="form-control"
                    id="phoneno"
                    disabled={true}
                  />
                  <label className="form-label">Phone Number *</label>
                </div>
              </div>
              <div className="col-xs-12 col-md-6 col-sm-6">
                <div className="form-group">
                  <input
                    value={birthLocation || ""}
                    type="text"
                    className="form-control"
                    id="birthlocation"
                    onChange={(e) => setBirthLocation(e.target.value)}
                  />
                  <label className="form-label">Birth Location</label>
                </div>
              </div>

              {/*----- Select Nakshatram -----*/}
              <div className="col-xs-12 col-md-6 col-sm-6">
                <div className="form-group">
                  <select className="form-control with-arrow">
                    {genderData?.map((i: any) => {
                      return <option value={gender}>{i}</option>;
                    })}
                  </select>
                  <label className="form-label">Select Gender</label>
                </div>
              </div>
              <div className="col-xs-12 col-md-6 col-sm-6">
                <div className="form-group">
                  <select
                    id="nakshatramDropdown"
                    className="form-control with-arrow"
                  >
                    {NakshatraData?.map((i: any) => {
                      return <option value={selectedNakshatram}>{i}</option>;
                    })}
                  </select>
                  <label className="form-label">Select Nakshatram</label>
                </div>
              </div>

              {/*----- Email Id -----*/}
              <div className="col-xs-12 col-md-6 col-sm-6"></div>
              <div className="col-xs-12 col-md-6 col-sm-6">
                <div className="form-group">
                  <input
                    value={email || ""}
                    type="text"
                    className="form-control"
                    id="emailid"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label className="form-label">Email Id</label>
                </div>
              </div>
            </div>

            <button
              onClick={onClickSave}
              className="btn btn-primary book-puja-btn-w"
            >
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Banner;
