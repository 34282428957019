import { lazyImport } from "../utils/lazyImport";
import { screens } from "../constants";
import Home from "../modules/Home";
import AboutUs from "../modules/AboutUs";
import PrivacyPolicy from "../modules/PrivacyPolicy";
import TermsConditions from "../modules/TermsConditions";
import RefundPolicy from "../modules/RefundPolicy";
import AllPuja from "../modules/AllPuja";
import PujaPage from "../modules/Puja";
import TemplePage from "../modules/TemplePage";
import AstroRecommendation from "../modules/AstroRecommendation";
import Puja from "../modules/Puja";
import LiveStram from "../modules/LiveStream";
import MakePayment from "../modules/MakePayment";
import Donation from "../modules/Donation";
import Profile from "../modules/Profile";
import Product from "../modules/Product";
import FamilyMembers from "../modules/FamilyMembers";
import AncestorDetails from "../modules/AncestorDetails";
import KartaAncestorDetails from "../modules/KartaAncestorDetails";
import SevaKartaDetails from "../modules/SevaKartaDetails";
import AddressBook from "../modules/AddressBook";
import YourBookedPuja from "../modules/YourBookedPuja";
import RecordingPuja from "../modules/PujaRecordings";


// const { AuthRoutes } = lazyImport(() => import('@/features/auth'), 'AuthRoutes');
// lazyImport(() => import('../modules/live-end'), LiveEnd);

export const publicRoutes = [
  {
    path: "/livestram",
    element: <LiveStram />,
  },
  {
    path: "/astrorecommendation",
    element: <AstroRecommendation />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/home/aboutus",
    element: <AboutUs />,
  },
  {
    path: "/home/privacypolicy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/home/termsconditions",
    element: <TermsConditions />,
  },
  {
    path: "/home/refundpolicy",
    element: <RefundPolicy />,
  },
  {
    path: "/allpuja",
    element: <AllPuja />,
  },
  {
    path: "/pujapage",
    element: <PujaPage />,
  },
  {
    path: "/temple/:id",
    element: <TemplePage />,
  },
  {
    path: "/puja/:id",
    element: <Puja />,
  },
  {
    path: "/make-payment",
    element: <MakePayment />,
  },
  {
    path: "/donation",
    element: <Donation />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/product",
    element: <Product />,
  },
  {
    path: "/familymembers",
    element: <FamilyMembers />,
  },
  {
    path: "/addressBook",
    element: <AddressBook />,
  },
  {
    path: "/ancestorDetails",
    element: <AncestorDetails />,
  },
  {
    path: "/sevaKartaDetails",
    element: <SevaKartaDetails />,
  },
  {
    path: "/kartaAncestorDetails",
    element: <KartaAncestorDetails />,
  },

  {
    path: "/yourbookedpuja",
    element: <YourBookedPuja />,
  },
  {
    path: "/recordingpuja",
    element: <RecordingPuja />,
  },
];

