// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  background: linear-gradient(93.02deg, #8c1e2b 13.24%, #ff0b00 149.53%);
  font-family: RobotoRegular;
}
.navbar-nav .nav-item {
  padding: 2px 15px;
  font-size: 14px;
  color: white;
}
.navbar-dark .navbar-nav .nav-link {
  color: white;
}
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
  border-bottom: 2px solid white;
}

.nav-link sup {
  /* top: -.5em; */
  background-color: red;
  padding: 3px 7px;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  vertical-align: top;
}

@media (min-width: 1023px) {
  .navbar-nav .nav-item {
    padding: 2px 9px;
  }

}
`, "",{"version":3,"sources":["webpack://./src/modules/Header/style.css"],"names":[],"mappings":"AAAA;EACE,sEAAsE;EACtE,0BAA0B;AAC5B;AACA;EACE,iBAAiB;EACjB,eAAe;EACf,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;;EAEE,WAAW;EACX,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;EAChB,qBAAqB;EACrB,gBAAgB;EAChB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE;IACE,gBAAgB;EAClB;;AAEF","sourcesContent":[".header {\n  background: linear-gradient(93.02deg, #8c1e2b 13.24%, #ff0b00 149.53%);\n  font-family: RobotoRegular;\n}\n.navbar-nav .nav-item {\n  padding: 2px 15px;\n  font-size: 14px;\n  color: white;\n}\n.navbar-dark .navbar-nav .nav-link {\n  color: white;\n}\n.navbar-dark .navbar-nav .nav-link.active,\n.navbar-dark .navbar-nav .show > .nav-link {\n  color: #fff;\n  border-bottom: 2px solid white;\n}\n\n.nav-link sup {\n  /* top: -.5em; */\n  background-color: red;\n  padding: 3px 7px;\n  border-radius: 10px;\n  width: 20px;\n  height: 20px;\n  font-size: 12px;\n  font-weight: 400;\n  color: #fff;\n  vertical-align: top;\n}\n\n@media (min-width: 1023px) {\n  .navbar-nav .nav-item {\n    padding: 2px 9px;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
