import {
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  GET_FAMILY_REQUEST,
  GET_FAMILY_SUCCESS,
  GET_FAMILY_FAILURE,
  GET_SEVA_KARTA_REQUEST,
  GET_SEVA_KARTA_SUCCESS,
  GET_SEVA_KARTA_FAILURE,
  GET_KARTA_ANCESTOR_SUCCESS,
  GET_KARTA_ANCESTOR_REQUEST,
  GET_KARTA_ANCESTOR_FAILURE,
  GET_ADD_ANCESTOR_REQUEST,
  GET_ADD_ANCESTOR_SUCCESS,
  GET_ADD_ANCESTOR_FAILURE,
  GET_ADDRESS_LIST_REQUEST,
  GET_ADDRESS_LIST_SUCCESS,
  GET_ADDRESS_LIST_FAILURE,
} from "./profile.actionType";

interface ProfileState {
  loading: boolean;
  profileData: any; // Adjust this according to the structure of your profile data
  error: string | null;
  loadingFamily: boolean;
  familyData: any; // Adjust this according to the structure of your profile data
  errorFamily: string | null;
  loadingSevaKarta: boolean;
  seveKartaData: any; // Adjust this according to the structure of your profile data
  errorSeveKarta: string | null;
  loadingKartaAncestor: boolean;
  kartaAncestorData: any; // Adjust this according to the structure of your profile data
  errorKartaAncestor: string | null;

  loadingAddAncestor: boolean;
  addAncestorData: any;
  errorAddAncestor: string | null;

  loadingAddressBook: boolean;
  addressBookData: any;
  errorAddressBook: string | null;
}

const initialState: ProfileState = {
  loading: false,
  profileData: [],
  error: null,
  loadingFamily: false,
  familyData: [],
  errorFamily: null,

  loadingSevaKarta: false,
  seveKartaData: [],
  errorSeveKarta: null,

  loadingKartaAncestor: false,
  kartaAncestorData: [],
  errorKartaAncestor: null,

  loadingAddAncestor: false,
  addAncestorData: [],
  errorAddAncestor: null,

  loadingAddressBook: false,
  addressBookData: [],
  errorAddressBook: null,
};

const profileReducer = (state = initialState, action: any): ProfileState => {
  switch (action.type) {
    case GET_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profileData: action.payload,
        error: null,
      };
    case GET_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_FAMILY_REQUEST:
      return {
        ...state,
        loadingFamily: true,
        errorFamily: null,
      };
    case GET_FAMILY_SUCCESS:
      return {
        ...state,
        loadingFamily: false,
        familyData: action.payload,
        errorFamily: null,
      };
    case GET_FAMILY_FAILURE:
      return {
        ...state,
        loadingFamily: false,
        errorFamily: action.payload,
      };

    case GET_SEVA_KARTA_REQUEST:
      return {
        ...state,
        loadingSevaKarta: true,
        errorSeveKarta: null,
      };
    case GET_SEVA_KARTA_SUCCESS:
      return {
        ...state,
        loadingSevaKarta: false,
        seveKartaData: action.payload,
        errorSeveKarta: null,
      };
    case GET_SEVA_KARTA_FAILURE:
      return {
        ...state,
        loadingSevaKarta: false,
        errorSeveKarta: action.payload,
      };

    case GET_KARTA_ANCESTOR_REQUEST:
      return {
        ...state,
        loadingKartaAncestor: true,
        errorKartaAncestor: null,
      };
    case GET_KARTA_ANCESTOR_SUCCESS:
      return {
        ...state,
        loadingKartaAncestor: false,
        kartaAncestorData: action.payload,
        errorKartaAncestor: null,
      };
    case GET_KARTA_ANCESTOR_FAILURE:
      return {
        ...state,
        loadingKartaAncestor: false,
        errorKartaAncestor: action.payload,
      };

    case GET_ADD_ANCESTOR_REQUEST:
      return {
        ...state,
        loadingAddAncestor: true,
        errorAddAncestor: null,
      };
    case GET_ADD_ANCESTOR_SUCCESS:
      return {
        ...state,
        loadingAddAncestor: false,
        addAncestorData: action.payload,
        errorAddAncestor: null,
      };
    case GET_ADD_ANCESTOR_FAILURE:
      return {
        ...state,
        loadingAddAncestor: false,
        errorAddAncestor: action.payload,
      };

    case GET_ADDRESS_LIST_REQUEST:
      return {
        ...state,
        loadingAddressBook: true,
        errorAddressBook: null,
      };
    case GET_ADDRESS_LIST_SUCCESS:
      return {
        ...state,
        loadingAddressBook: false,
        addressBookData: action.payload,
        errorAddressBook: null,
      };
    case GET_ADDRESS_LIST_FAILURE:
      return {
        ...state,
        loadingAddressBook: false,
        errorAddressBook: action.payload,
      };
    default:
      return state;
  }
};

export default profileReducer;
