import { combineReducers } from "redux";
import home from "../modules/Home/home.reducer";
import astroRecoReducer from "../modules/AstroRecommendation/Reducer/astroReco.reducer";
import bookingReducer from "../modules/MakePayment/booking.reducer";
import profileReducer from "../modules/Profile/profile.reducer";
// import authReducer from "../modules/login/auth.reducer";
// import telehealthReducer from "../modules/telehealth/telehealth.reducer";

export default combineReducers({
  home,
  astroRecoReducer,
  bookingReducer,
  profileReducer,
});
