

import './style.css';
import topImageHow from '../../../assets/images/top-image-How.svg';
import topImageHow2 from '../../../assets/images/top-imag-2.svg';
import choosePuja from '../../../assets/images/choose-your-puja.svg';
import pickffering from '../../../assets/images/pick-offering.svg';
import finishBooking from '../../../assets/images/finish-booking.svg';
import recieveBlessings from '../../../assets/images/recieve-blessings.svg';



const HowItWorks = () => {
  return (
    <div className="">
      <div className="how-we-work">
        <div className="custom-container p-relative">
          <img src={topImageHow} alt="logo" className="how-imagng-1" />
          <img src={topImageHow2} alt="logo" className="how-imagng-2" />

          {/* Heading */}
          <div className="heading-div">
            <label className="heading">
              How we work?
            </label>
          </div>

          <div className="box-div">
            <div className="row inside-div">
              <img src={choosePuja} alt="logo" className="img-1" />
              <div className="col-10 as-center">
                <label className="section-title">
                  Choose your Puja
                </label>
                <label className="section-description">
                  Choose from a wide range of pujas from our
                  <br />
                  collection, worship your beloved deity.
                </label>
              </div>
              <div className="col-2 count-right-div">
                <label className="count">
                  1
                </label>
              </div>
            </div>

            <div className="row inside-div-1">
              <img src={pickffering} alt="logo" className="img-2" />
              <div className="col-2 count-left-div">
                <label className="count">
                  2
                </label>
              </div>
              <div className="col-10 as-center">
                <label className="section-title">
                  Pick Offerings
                </label>
                <label className="section-description">
                  Pick the puja you would like done, get
                  <br />
                  prasadam options and more.
                </label>
              </div>
            </div>

            <div className="row inside-div">
              <img src={finishBooking} alt="logo" className="img-1" />
              <div className="col-10 as-center">
                <label className="section-title">
                  Finish Booking
                </label>
                <label className="section-description">
                  Pay through our secure payment gateway and
                  <br />
                  complete your booking.
                </label>
              </div>
              <div className="col-2 count-right-div">
                <label className="count">
                  3
                </label>
              </div>
            </div>

            <div className="row inside-div-1">
              <img src={recieveBlessings} alt="logo" className="img-2" />
              <div className="col-2 count-left-div">
                <label className="count">
                  4
                </label>
              </div>
              <div className="col-10 as-center">
                <label className="section-title">
                  Receive Blessings
                </label>
                <label className="section-description">
                  Enjoy the live puja, and get blessed from
                  <br />
                  the comfort of your home.
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowItWorks;