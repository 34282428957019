export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

export const GET_FAMILY_REQUEST = "GET_FAMILY_REQUEST";
export const GET_FAMILY_SUCCESS = "GET_FAMILY_SUCCESS";
export const GET_FAMILY_FAILURE = "GET_FAMILY_FAILURE";

export const GET_SEVA_KARTA_REQUEST = "GET_SEVA_KARTA_REQUEST";
export const GET_SEVA_KARTA_SUCCESS = "GET_SEVA_KARTA_SUCCESS";
export const GET_SEVA_KARTA_FAILURE = "GET_SEVA_KARTA_FAILURE";

export const GET_KARTA_ANCESTOR_REQUEST = "GET_KARTA_ANCESTOR_REQUEST";
export const GET_KARTA_ANCESTOR_SUCCESS = "GET_KARTA_ANCESTOR_SUCCESS";
export const GET_KARTA_ANCESTOR_FAILURE = "GET_KARTA_ANCESTOR_FAILURE";

export const GET_ADD_ANCESTOR_REQUEST = "GET_ADD_ANCESTOR_REQUEST";
export const GET_ADD_ANCESTOR_SUCCESS = "GET_ADD_ANCESTOR_SUCCESS";
export const GET_ADD_ANCESTOR_FAILURE = "GET_ADD_ANCESTOR_FAILURE";

export const GET_ADDRESS_LIST_REQUEST = "GET_ADDRESS_LIST_REQUEST";
export const GET_ADDRESS_LIST_SUCCESS = "GET_ADDRESS_LIST_SUCCESS";
export const GET_ADDRESS_LIST_FAILURE = "GET_ADDRESS_LIST_FAILURE";
