import React, { useState } from "react";
import "./style.css";
import defualtImage from "../../../assets/images/default_iamge.jpeg";

const Banner = ({ data }: any) => {
  const imageUrl = data?.image_main_web || defualtImage;
  return (
    <div className="puja-detail-banner">
      <div className="">
        <div className="banner-text-section">
          <img 
           src={imageUrl}
          alt="logo" 
          className="banner-image"
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
