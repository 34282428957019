// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-div {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#fff;
    background: rgb(54 52 52 / 80%);
    z-index: 99;
    
}
`, "",{"version":3,"sources":["webpack://./src/modules/Common/Loader/style.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;IACV,+BAA+B;IAC/B,WAAW;;AAEf","sourcesContent":[".loader-div {\n    height: 100%;\n    width: 100%;\n    position: absolute;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color:#fff;\n    background: rgb(54 52 52 / 80%);\n    z-index: 99;\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
