import React, { useEffect, useState } from "react";
import Header from "../Header";
import Banner from "./Banner";
import HowItWorks from "./HowItWorks";
import FAQ from "./FAQ";
import Footer from "../Footer";
import MostPopularPuja from "./MostPopularPuja";
import Rashi from "./Rashi";
import Temple from "./Temple";
import fireBaseUtil from "../../utils/fireBaseUtil";
import { useAppAccessor } from "../../hooks";
import { setAllPujasDataList, setAllTempleDataList } from "./home.actions";
import useAppDispatch from "../../hooks/useAppDispatch";
import Video from "./Video";
import { getStringAsync } from "../../lib/storage";
import { setPaidBookedAllPujasList } from "../MakePayment/booking.actions";
import Loader from "../Common/Loader";

const Home = () => {
  const dispatch = useAppDispatch();
  const { getHomeProps } = useAppAccessor();
  const { allPujaData, allTempleData } = getHomeProps();

  useEffect(() => {
    getAllPujas();
    getAllTemples();
    getPaidBookedPujasByuser();
  }, []);


  const getAllTemples = async () => {
    await fireBaseUtil
      .getTemplsData()
      .then((data: any) => {
        dispatch(setAllTempleDataList(data || []));
      })
      .catch((err) => { });
  };

  const getAllPujas = async () => {
    await fireBaseUtil?.getPujasData().then((data: any) => {
      dispatch(setAllPujasDataList(data));
    });
  };

  const getPaidBookedPujasByuser = async () => {
    const mobileNum: any = await getStringAsync("userDatas");

    await fireBaseUtil
      .getBookedPujaById(
        `/paid_booked_pujas/${JSON.parse(mobileNum)?.phoneNumber}`
      )
      .then((data: any) => {
        dispatch(setPaidBookedAllPujasList(data?.booked_puja_data || []));
      })
      .catch((err: any) => { });
  };

  return (
    <div>
      {/* <Loader /> */}
      <Header />
      <Banner />
      <MostPopularPuja data={allPujaData} type={"puja"} />
      <Rashi />
      {/*<Video />*/}

      <HowItWorks />
      <Temple data={allTempleData} />
      <FAQ />
      <Footer />

    </div>
  );
};

export default Home;
