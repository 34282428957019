import React, { useState } from "react";
import popupLogo from "../../../assets/images/popupLogo.svg";
import OTPInput from "react-otp-input";
import Select from "react-dropdown-select";
import "./style.css";
import { countryPickerData } from "../../../constants/dataConstants";
import { useNavigate } from "react-router-dom";
import { values } from "lodash";


const Popup = ({
    onClose,
    isLoading,
    handleOtpCall,
    isSendOTP,
    handleVerifiyOtp,
    handleResendOtp,
    time,
    recatchaId
}: any) => {
    const navigate = useNavigate();
    const [phoneNumber, setPhoneNumber] = useState<any>("");
    //const [countryFlag, setCountryFlag] = useState<any>("+91");
    const [countryFlag, setCountryFlag] = useState({ dial_code: "+91", flag: "🇮🇳", code: "IN" });
    const [otp, setOtp] = useState("");
    const formatTime = (time: any) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    };

    return (
        <div className="otp-popup">
            <div id={recatchaId} className="otp-popup-inner">
                <img src={popupLogo} alt="logo" />
                {!isSendOTP ? (
                    <div className="details">
                        <div className="formOuter">
                            <p className="label">Enter Mobile Number</p>
                            <div className="d-flex">
                                {/* <select
                                    className="country-dd"
                                    value={countryFlag}
                                    onChange={(e) => {
                                        console.log("e.target.value", e.target.value);

                                        setCountryFlag(e.target.value)
                                    }}
                                >
                                    {countryPickerData.map((item) => (
                                        <option key={item.code} value={item.dial_code} data-thumbnail="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3e/LetterA.svg/2000px-LetterA.svg.png">
                                            {item.flag} {item.dial_code} ({item.code})
                                        </option>
                                    ))}
                                </select> */}

                                <Select
                                    className="country-dd"
                                    options={countryPickerData}
                                    values={[countryFlag]}
                                    // labelField="dial_code"
                                    // valueField="dial_code"
                                    labelField={"dial_code"}
                                    valueField={"dial_code"}
                                    onChange={(values) => {
                                        if (values.length > 0) {
                                            setCountryFlag(values[0]);
                                        }
                                    }}
                                    itemRenderer={({ item, itemIndex, props, state, methods }) => (
                                        <div key={item.code} onClick={() => methods.addItem(item)} style={{ marginTop: "8px" }}>
                                            {item.flag} {item.dial_code} ({item.code})
                                        </div>
                                    )}
                                />

                                <input
                                    className="mobileNumber"
                                    type="text"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    placeholder="Enter your mobile number for Login or signup"
                                />
                            </div>
                        </div>
                        <p className="policyContent" style={{ cursor: 'pointer' }}>
                            By signing in agree to our <a onClick={() => navigate('/home/termsconditions')}>Terms & Services</a> and{" "}
                            <a onClick={() => navigate('/home/privacypolicy')}>Privacy Policy</a>
                        </p>
                        <button
                            className="btn btn-secondary"
                            style={{ backgroundColor: phoneNumber?.length == 10 ? "#EC6236" : "#DEE4ED", color: phoneNumber?.length == 10 ? "white" : "black", }}

                            onClick={() => {
                                if (phoneNumber?.length === "") {
                                    alert("Please enter the phone number");
                                } else if (phoneNumber?.toString()?.length !== 10) {
                                    alert("Please enter the correct phone number");
                                } else {
                                    handleOtpCall(`${countryFlag.dial_code}${phoneNumber}`);
                                }
                            }}
                        >
                            Get Started! {isLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                        </button>
                    </div>
                ) : (
                    <div className="otp">
                        <p className="otpTitle">Enpter the 6-digit OTP</p>
                        <p className="otpSubtitle">
                            A verification code has been sent to your Mobile Number{" "}
                        </p>
                        <div>
                            {/* <input className="otpInput" type="text" name="" id="" 
                  onChange={(e) => setOtp(e.target.value)}/> */}
                            <OTPInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                // renderSeparator={<span>-</span>}
                                renderInput={(props: any) => <input {...props} />}
                                containerStyle={{ justifyContent: "space-around" }}
                                inputStyle={{
                                    width: 45,
                                    height: 40,
                                    borderRadius: 17,
                                    fontSize: 20,
                                    fontWeight: 700,
                                    border: "1px solid #DEE4ED",
                                }}
                            />
                        </div>
                        <div className="otpTimer">
                            <div className="otpText">
                                <p className="otpTextTitle">OTP sent to mobile</p>
                                <p className="otpTextSubTitle">
                                    You should get an otp within 30 sec.
                                </p>
                            </div>
                            {time !== 0 && (
                                <div className="timer timerMain">{formatTime(time)}</div>
                            )}
                            {!time && (
                                <button
                                    className="btn btn-secondary resent"
                                    onClick={() => {
                                        handleResendOtp(`${countryFlag?.dial_code}${phoneNumber}`);
                                    }}
                                >
                                    Resend
                                </button>
                            )}
                        </div>
                        <button
                            className="btn btn-secondary doneBtn"
                            style={{ backgroundColor: otp?.length == 6 ? "#EC6236" : "#DEE4ED", color: otp?.length == 6 ? "white" : "black", }}
                            onClick={() => {
                                handleVerifiyOtp(otp);
                            }}
                        >
                            Done {isLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Popup