import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import reducers from "../reducers";

let middlewares = [thunk];
const thunkMiddleware = require("redux-thunk").thunk;
let InitialState: any;

const store = createStore(reducers, {}, applyMiddleware(thunk));

export type AppDispatch = typeof store.dispatch;
export default store;
