import { Dispatch } from "redux";

import { ActionFunc, DispatchFunc } from "../../definitions/actions";
import {
  GET_RAZORPAY_BOOKED_FAILURE,
  GET_RAZORPAY_BOOKED_REQUEST,
  GET_RAZORPAY_BOOKED_SUCCESS,
  SET_ALL_BOOKED_PUJAS_BY_USER,
  SET_PAID_ALL_BOOKED_PUJAS_BY_USER,
} from "./booking.actionsType";
import { getStringAsync } from "../../lib/storage";
import { storageKeys } from "../../constants";
import fireBaseUtil from "../../utils/fireBaseUtil";

export function setBookedAllPujasList(payload: any): ActionFunc {
  return (dispatch: DispatchFunc) => {
    dispatch({
      type: SET_ALL_BOOKED_PUJAS_BY_USER,
      payload,
    });
  };
}

export const getRazorpayPurchasePuja = () => async (dispatch: Dispatch) => {
  dispatch({ type: GET_RAZORPAY_BOOKED_REQUEST });
  try {
    // Retrieve mobile number from AsyncStorage or any other source
    const mobileNumber: any = await getStringAsync("userData"); // Adjust the key according to how you stored the mobile number

    if (!mobileNumber) {
      console.log("Mobile number not found");
    }

    // Call Firebase utility function to get devotee data
    await fireBaseUtil
      ?.getRazorPayById(
        `/razorpay_purchase_puja/${JSON.parse(mobileNumber)?.phoneNumber}`
      )
      .then((data) => {

        dispatch({ type: GET_RAZORPAY_BOOKED_SUCCESS, payload: data });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error: any) {
    dispatch({ type: GET_RAZORPAY_BOOKED_FAILURE, payload: error.message });
  }
};

export function setPaidBookedAllPujasList(payload: any): ActionFunc {
  return (dispatch: DispatchFunc) => {
    dispatch({
      type: SET_PAID_ALL_BOOKED_PUJAS_BY_USER,
      payload,
    });
  };
}
