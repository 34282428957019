import React, { useRef } from "react";
import "./style.css";
import { useAppAccessor } from "../../../hooks";

export default function PujaList({ data, onClickAddPuja, removePuja }: any) {
  const { getHomeProps, getBookingProps } = useAppAccessor();
  const { allBookedPujas, paidBookedPujas } = getBookingProps();
  const { selectedPujaObj, allTempleData, vedicSignData } = getHomeProps();

  const checkBookedPuja: any = (item: any) => {
    const findPujaBookedOrNot = paidBookedPujas?.filter(
      (i: any) => `/pujas/${item?.id}` == i?.main_puja_id
    );
    return findPujaBookedOrNot && findPujaBookedOrNot?.length !== 0;
  };


  return (
    <div className="container">
      <div className="row">
        {data?.map((i: any, index: any) => {
          return (
            <div className="col-12 col-lg-4 col-xl-4 col-md-6 col-sm-4">
              <div className="puja-list-card-item">
                <img
                  src={
                    i?.pic ||
                    require("../../../assets/images/tmpPujas.jpeg") ||
                    ""
                  }
                  alt="Puja"
                />
                <div className="plrt-1-rem">
                  <p className="card-puja-title">{`${i?.title || ""}`}</p>
                  <p className="card-puja-sub-title">{i?.subtitle || ""}</p>
                  <p className="card-puja-description">
                    {i?.description || ""}
                  </p>

                  {i?.services && (
                    <div className="recording-bg">
                      <p className="bullet-point">
                        {`• ${i?.services?.join(" • ") || ""}`}
                      </p>
                    </div>
                  )}

                  <div className="text-center pt-1-rem mt-15">
                    {/* <button
                      onClick={() => onClickAddPuja(i, index)}
                      className="btn btn-primary pd-8-40 card-button"
                    >
                      {`Add for Rs ${i?.price || ""}`}
                    </button> */}

                    {!checkBookedPuja(selectedPujaObj) &&
                      (selectedPujaObj.daysRemaining > 0 ||
                        (selectedPujaObj.daysRemaining == 0 &&
                          new Date(
                            selectedPujaObj?.live_puja_endTime?.seconds * 1000 +
                              selectedPujaObj?.live_puja_endTime?.nanoseconds /
                                1000000
                          ).getTime() >= new Date().getTime())) && (
                        <button
                          onClick={() =>
                            allBookedPujas?.findIndex((e) => e.id === i.id) > -1
                              ? removePuja(i)
                              : onClickAddPuja(i, index)
                          }
                          className="btn btn-primary pd-8-40 card-button"
                        >
                          {`${
                            allBookedPujas?.findIndex((e) => e.id === i.id) > -1
                              ? "Remove"
                              : `Add for Rs ${i?.price || ""}`
                          }`}
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
