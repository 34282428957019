import "./style.css";
import React from "react";
import { Link } from "react-router-dom";
import backArrow from "../../assets/images/back-arrow.svg";
import { Container } from "react-bootstrap";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <>
      <div>
        <Header />
       
          
             <div className="header-section-about">
            <div className="container">
            <div className="flex">
            <img
              className="back-arrow-icon"
              id="backButton"
              style={{ cursor: "pointer" }}
              src={backArrow}
              alt="Back"
              onClick={() => navigate("/")}
            />
            <span className="our-offerings-about">Privacy Policy</span>
          </div>
          </div>
          </div>
          <Container className="custom-container">
          <div className="mb-5">
            <p className="title-bold">Introduction</p>
            <p className="disc-tex">
              <strong>Jyotirgamaya.online</strong> (part of Jyotirgamaya Pvt
              Ltd) respects your privacy and is committed to protecting your
              personal data. This Privacy Policy explains how we collect, use,
              and disclose your personal information when you use our online
              puja services.
            </p>
            <p className="title-bold">Information We Collect</p>
            <p className="disc-tex">
              <ul className="li-list">
                <li>
                  <strong>Personal Data:</strong> We collect information such as
                  your name, email address, phone number, and birth details to
                  provide customized puja services based on your birth charts.
                  We also collect some details about your family like the birth
                  details, Rashi, etc. for the same reason.
                </li>
                <li>
                  <strong>Payment Information:</strong> When you book a puja, we
                  collect payment details to process transactions securely.
                </li>
                <li>
                  <strong>Usage Data:</strong> We collect data on how you
                  interact with our website and services, including IP
                  addresses, browser type, and device information.
                </li>
              </ul>
            </p>
            <p className="title-bold">How We Use Your Information</p>
            <p className="title-bold" style={{ fontSize: "16px" }}>
              We use your data to:
            </p>
            <p className="disc-tex">
              <ul className="li-list">
                <li>Provide relevant information about puja services.</li>
                <li>Improve our services and user experience.</li>
                <li>Process payments securely.</li>
                <li>
                  Communicate with you regarding bookings, updates, and special
                  offers.
                </li>
              </ul>
            </p>
            <p className="title-bold">Data Security</p>
            <ul className="li-list">
              <li>
                We implement security measures to protect your personal data,
                including encryption and secure payment processing.
              </li>
              <li>
                <strong>End-to-End Encryption:</strong>
                <ul className="li-list">
                  <li>
                    Implement strong encryption protocols for all data
                    transmitted between users, pandits, and the platform.
                  </li>
                  <li>
                    Secure communication channels ensure that sensitive
                    information (such as personal details, birth charts, and
                    puja requests) remains confidential.
                  </li>
                </ul>
              </li>
              <li>
                <strong>User Authentication and Authorization:</strong>
                <ul className="li-list">
                  <li>
                    Strictly authenticate users before granting access to puja
                    services.
                  </li>
                  <li>
                    Role-based access control ensures that only authorized
                    individuals (users, pandits, administrators) can perform
                    specific actions.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Secure Payment Processing:</strong>
                <ul className="li-list">
                  <li>
                    Use reputable payment gateways with robust security
                    features.
                  </li>
                  <li>
                    Encrypt payment data during transactions to prevent
                    unauthorized access.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Data Storage and Retention:</strong>
                <ul className="li-list">
                  <li>
                    Limit data retention to the necessary duration for
                    puja-related purposes.
                  </li>
                  <li>
                    Regularly audit and review stored data to identify and
                    remove outdated or unnecessary information.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Privacy Policies and Consent:</strong>
                <ul className="li-list">
                  <li>
                    Clearly communicate privacy policies to users, detailing how
                    their data will be handled.
                  </li>
                  <li>
                    Obtain explicit consent from users before collecting any
                    personal information.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Regular Security Audits:</strong>
                <ul className="li-list">
                  <li>
                    Conduct periodic security assessments to identify
                    vulnerabilities. Address any weaknesses promptly to prevent
                    data breaches.
                  </li>
                </ul>
              </li>
            </ul>
            <p className="title-bold">Third-Party Services</p>
            <p className="disc-tex">
              <ul className="li-list">
                <li>
                  We may use third-party services (such as payment gateways) to
                  process transactions. These services have their own privacy
                  policies.
                </li>
              </ul>
            </p>
            <p className="title-bold">Your Rights</p>
            <p className="disc-tex">
              <ul className="li-list">
                <li>
                  You have the right to access, correct, or delete your personal
                  data. Contact us if you need assistance by sending an email to
                  puja@Jyotirgamaya.online
                </li>
              </ul>
            </p>
            <p className="title-bold">Changes to this Privacy Policy</p>
            <p className="disc-tex">
              <ul className="li-list">
                <li>
                  We may update this Privacy Policy from time to time. Check our
                  website for the latest version.
                </li>
              </ul>
            </p>
          </div>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default PrivacyPolicy;
