import "./style.css";

const AboutPuja = ({ data }: any) => {
  return (
    <div className="about-puja-card">
      <p className="about-puja-heading">{"About Puja"}</p>
      <p className="about-puja-des">{data?.about || ""}</p>
    </div>
  );
};

export default AboutPuja;