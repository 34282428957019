import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import PujaImg from "../../../assets/images/PujaImg.svg"; // Ensure this path is correct
import RightArrow from "../../../assets/images/rightArrow.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { clearVedicSignDataList, setSelectedPujaObj } from "../home.actions";
import { useAppAccessor } from "../../../hooks";

export default function MostPopularPuja({ data, type }: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { getHomeProps, getBookingProps } = useAppAccessor();
  const { paidBookedPujas } = getBookingProps();

  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = (e: React.WheelEvent<HTMLDivElement>) => {
    const delta = e.deltaY;
    if (containerRef.current) {
      containerRef.current.scrollLeft -= delta * 20; // Adjust the scrolling speed as needed
    }
  };
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 3,
  };

  const onPressBookAPuja = (data: any) => {
    dispatch(clearVedicSignDataList([]));
    dispatch(setSelectedPujaObj(data));
    navigate(`/puja/${data?.id || ""}`);
  };

  const onPressRightIcon = () => {
    navigate("/allpuja");
  };

  const checkBookedPuja: any = (item: any) => {
    const findPujaBookedOrNot = paidBookedPujas?.filter(
      (i: any) => `/pujas/${item?.id}` == i?.main_puja_id
    );

    return findPujaBookedOrNot && findPujaBookedOrNot?.length !== 0;
  };

  return (
    <div className="container">
      <div className="row">
        <div className="d-flex justify-content-between address section-padding">
          <p className="most-popular-title">Most Popular Pujas</p>
          <img
            onClick={onPressRightIcon}
            src={RightArrow}
            alt="Puja"
            style={{ cursor: "pointer", width: 40, height: 40 }}
          />
        </div>
      </div>
      <div
        id="scroll"
        className="scrollMostPoular"
        onWheel={handleScroll}
        ref={containerRef}
      >
        {(type == "puja"
          ? data.filter(
            (item: any) =>
              item.daysRemaining > 0 ||
              (item.daysRemaining == 0 &&
                new Date(
                  item?.live_puja_endTime?.seconds * 1000 +
                  item?.live_puja_endTime?.nanoseconds / 1000000
                ).getTime() >= new Date().getTime())
          )
          : data
        )?.map((item: any) => {
          return (
            <div>
              {/* <div className="row">
                <div className="col-xs-12 col-md-4 col-sm-4"> */}
              <div className="card-item">
                <img src={item.image_main} alt="Puja" />
                <div className=" p-3">
                  <p className="card-title font-medium">
                    {item?.name || ""}
                  </p>
                  <div className="d-flex justify-content-between address">
                    <div className="text-section">
                      <p className="disc-text font-reg">
                        {moment(item?.dateFormated).format("Do MMM, dddd")}
                        <br />
                        {item?.tithi || ""}
                      </p>
                    </div>
                    {item?.daysRemaining >= 0 &&
                      item?.daysRemaining < 4 && (
                        <div className="day-section">
                          <span className="day font-medium">
                            {" "}
                            {item.daysRemaining == 0
                              ? new Date(
                                item?.live_puja_endTime?.seconds * 1000
                              ).getHours() - new Date().getHours()
                              : item.daysRemaining}
                          </span>
                          <span className="digit font-medium">
                            {" "}
                            {item.daysRemaining == 0 ? `hrs` : `day left`}
                          </span>
                        </div>
                      )}
                  </div>
                  <div className="text-center my-3">
                    <button
                      onClick={() => onPressBookAPuja(item)}
                      className="btn btn-primary pd-8-40"
                    >
                      {checkBookedPuja(item)
                        ? `Puja is booked`
                        : item?.is_live_now
                          ? `Live`
                          : `Book Puja`}
                    </button>
                  </div>
                </div>
              </div>
              {/* </div>
              </div> */}
            </div>
          );
        })}
        {/* <div className="card-item">
          <img src={PujaImg} alt="Puja" />
          <div className=" p-2">
            <p className="card-title font-medium">
              Sankashthahara Chaturti Maha Puja
            </p>
            <div className="d-flex justify-content-between address">
              <div className="text-section">
                <p className="disc-text font-reg">
                  28th Mar, Thursday <br />
                  Sankashthahara Chaturti
                </p>
              </div>
              <div className="day-section">
                <span className="day font-medium">1</span>
                <span className="digit font-medium">day left</span>
              </div>
            </div>
            <div className="text-center my-3">
              <button className="btn btn-primary pd-8-40">Book A Puja</button>
            </div>
          </div>
        </div>
        <div className="card-item">
          <img src={PujaImg} alt="Puja" />
          <div className=" p-2">
            <p className="card-title font-medium">
              Sankashthahara Chaturti Maha Puja
            </p>
            <div className="d-flex justify-content-between address">
              <div className="text-section">
                <p className="disc-text font-reg">
                  28th Mar, Thursday <br />
                  Sankashthahara Chaturti
                </p>
              </div>
              <div className="day-section">
                <span className="day font-medium">1</span>
                <span className="digit font-medium">day left</span>
              </div>
            </div>
            <div className="text-center my-3">
              <button className="btn btn-primary pd-8-40">Book A Puja</button>
            </div>
          </div>
        </div>
        <div className="card-item">
          <img src={PujaImg} alt="Puja" />
          <div className=" p-2">
            <p className="card-title font-medium">
              Sankashthahara Chaturti Maha Puja
            </p>
            <div className="d-flex justify-content-between address">
              <div className="text-section">
                <p className="disc-text font-reg">
                  28th Mar, Thursday <br />
                  Sankashthahara Chaturti
                </p>
              </div>
              <div className="day-section">
                <span className="day font-medium">1</span>
                <span className="digit font-medium">day left</span>
              </div>
            </div>
            <div className="text-center my-3">
              <button className="btn btn-primary pd-8-40">Book A Puja</button>
            </div>
          </div>
        </div>
        <div className="card-item">
          <img src={PujaImg} alt="Puja" />
          <div className=" p-2">
            <p className="card-title font-medium">
              Sankashthahara Chaturti Maha Puja
            </p>
            <div className="d-flex justify-content-between address">
              <div className="text-section">
                <p className="disc-text font-reg">
                  28th Mar, Thursday <br />
                  Sankashthahara Chaturti
                </p>
              </div>
              <div className="day-section">
                <span className="day font-medium">1</span>
                <span className="digit font-medium">day left</span>
              </div>
            </div>
            <div className="text-center my-3">
              <button className="btn btn-primary pd-8-40">Book A Puja</button>
            </div>
          </div>
        </div>
        <div className="card-item">
          <img src={PujaImg} alt="Puja" />
          <div className=" p-2">
            <p className="card-title font-medium">
              Sankashthahara Chaturti Maha Puja
            </p>
            <div className="d-flex justify-content-between address">
              <div className="text-section">
                <p className="disc-text font-reg">
                  28th Mar, Thursday <br />
                  Sankashthahara Chaturti
                </p>
              </div>
              <div className="day-section">
                <span className="day font-medium">1</span>
                <span className="digit font-medium">day left</span>
              </div>
            </div>
            <div className="text-center my-3">
              <button className="btn btn-primary pd-8-40">Book A Puja</button>
            </div>
          </div>
        </div> */}
      </div>

      {/* <div style={{ width: '57%' }}>
        <Slider {...settings}>
          <div>
            <div className="card-item">
              <img src={PujaImg} alt="Puja" />
              <div className=" p-2">
                <p className="card-title font-medium">
                  Sankashthahara Chaturti Maha Puja
                </p>
                <div className="d-flex justify-content-between address">
                  <div className="text-section">
                    <p className="disc-text font-reg">
                      28th Mar, Thursday <br />
                      Sankashthahara Chaturti
                    </p>
                  </div>
                  <div className="day-section">
                    <span className="day font-medium">1</span>
                    <span className="digit font-medium">day left</span>
                  </div>
                </div>
                <div className="text-center my-3">
                  <button className="btn btn-primary pd-8-40">Book A Puja</button>
                </div>
              </div>


            </div>
          </div>

          <div>
            <div className="card-item">
              <img src={PujaImg} alt="Puja" />
              <div className=" p-2">
                <p className="card-title font-medium">
                  Sankashthahara Chaturti Maha Puja
                </p>
                <div className="d-flex justify-content-between address">
                  <div className="text-section">
                    <p className="disc-text font-reg">
                      28th Mar, Thursday <br />
                      Sankashthahara Chaturti
                    </p>
                  </div>
                  <div className="day-section">
                  <span className="day font-medium">1</span>
                    <span className="digit font-medium">day left</span>
                  </div>
                </div>
                <div className="text-center my-3">
                  <button className="btn btn-primary pd-8-40">Book A Puja</button>
                </div>
              </div>


            </div>
          </div>


        </Slider>
      </div> */}
    </div>
  );
}
