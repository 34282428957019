import React, { useEffect, useState } from "react";
import "./style.css";

const Loader = () => {
    return (
        <div className="loader-div">
            <div className="spinner-border" role="status">
                {/* <span className="sr-only"></span> */}
            </div>
        </div>
    );
};

export default Loader;
