import "./style.css";
import React from "react";
import { Link } from "react-router-dom";
import backArrow from "../../assets/images/back-arrow.svg";
import pujaImg from "../../assets/images/puja-img.svg";
import Header from "../Header";
import { useNavigate } from "react-router-dom";

const Donation = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Header />
      <div className="donation">
        <div className="container">
          <div className="flex">
            <img
              className="back-arrow-icon"
              id="backButton"
              src={backArrow}
              alt="Back"
            />
            <span className="for-a-good-cause">{"For A Good Cause"}</span>
          </div>

          <div className="container">
            <div className="row">
              {/*----- Card -----*/}
              <div className="col-xs-12 col-md-9 col-sm-9 pl-inherit">
                <div className="order-card">
                  <p className="would-you-like">{"Would you like to give back to the community?"}</p>
                  <p className="some-donations">{"Some Contribution you can do"}</p>

                  <div className="container">
                    <div className="row">
                      {/*----- Card 1 -----*/}
                      <div className="col-xs-12 col-md-6 col-sm-6">
                        <div className="puja-card">
                          <p className="puja-title">{"Achaya Dakshina"}</p>
                          <p className="puja-description">{"Yatashakti Dakshina to the priest/s for performing ritualistic puja seva to God. The blessings of priests is considered sacred in Hinduism"}</p>
                          <div className="input-field">
                            <input type="text" placeholder="Include any amount" />
                          </div>
                        </div>
                      </div>

                      {/*----- Card 2 -----*/}
                      <div className="col-xs-12 col-md-6 col-sm-6">
                        <div className="puja-card">
                          <p className="puja-title">{"Contribution Towards Gow Shala"}</p>
                          <p className="puja-description">{"Contribution towards the welfare of Gows is thought a pious deed. One receives extraordinary spiritual benefits & positive energy with Gowseva(feeding,serving,worshipping)"}</p>
                          <div className="input-field">
                            <input type="text" placeholder="Include any amount" />
                          </div>
                        </div>
                      </div>

                      {/*----- Card 3 -----*/}
                      <div className="col-xs-12 col-md-6 col-sm-6">
                        <div className="puja-card">
                          <p className="puja-title">{"Contribution Towards Temple Renovation & Maintenance"}</p>
                          <p className="puja-description">{"Temples for Hindus is a place of worship and a store house of culture & tradition.Protecting and restoring Hindu temples is a pious deed of preserving heritage. It is believed that one can free self from sins of 100 births by building a temple."}</p>
                          <div className="input-field">
                            <input type="text" placeholder="Include any amount" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*----- Order Summary -----*/}
              <div className="col-xs-12 col-md-3 col-sm-3 ac-center">
                <p className="order-summary">{"Order Summary"}</p>
                <div className="d-flex-jc-sb">
                  <p className="item-txt-value">{"1 ITEM"}</p>
                  <p className="item-txt-value">{"999.00"}</p>
                </div>

                <div className="d-flex-jc-sb">
                  <p className="item-txt-value">{"Tax"}</p>
                  <p className="item-txt-value">{"-"}</p>
                </div>

                <div className="d-flex-jc-sb">
                  <p className="total-txt-value">{"Total"}</p>
                  <p className="total-txt-value">{"999.00"}</p>
                </div>

                <div className="d-flex-jc-sb mt-40">
                  <a className="promo-code">{"User a promo code"}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Donation;
