import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  db,
  updateDoc,
  doc,
  deleteDoc,
  query,
  orderBy,
  setDoc,
  storage,
} from "../firebase/index";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import moment from "moment";
import { getStringAsync } from "../lib/storage";
import axios from "axios";

const getTempleByID = async (templeRef_Id: any) => {
  let obj_Temple: any;
  const docRef = doc(db, templeRef_Id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    obj_Temple = docSnap.data();
    obj_Temple.id = docSnap.id;
  } else {
    // docSnap.data() will be undefined in this case
  }

  return obj_Temple;
};

const getPujasById = async (puja_id: any) => {
  let obj_puja: any;
  const docRef = doc(db, `pujas/${puja_id}`);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    obj_puja = docSnap.data();
    obj_puja.id = docSnap.id;

    obj_puja.dateFormated =
      "" +
      new Date(
        obj_puja?.date?.seconds * 1000 + obj_puja?.date?.nanoseconds / 1000000
      );
    const dateB = moment(
      moment(
        new Date(
          obj_puja?.date?.seconds * 1000 + obj_puja?.date?.nanoseconds / 1000000
        )
      ).format("YYYY-MM-DD")
    );

    const dateC = moment(moment().format("YYYY-MM-DD"));
    obj_puja.daysRemaining = dateB.diff(dateC, "days");
  } else {
    // docSnap.data() will be undefined in this case
  }

  return obj_puja;
};

const getDevoteeById = async (devoteeNumber: any) => {
  let objDevotee: any;
  const docRef = doc(db, devoteeNumber);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    objDevotee = docSnap.data();
    objDevotee.id = docSnap.id;
  } else {
    // docSnap.data() will be undefined in this case
  }
  return objDevotee;
};

const getRazorPayById = async (mobileNumber: any) => {
  let objRazorPay: any;
  const docRef = doc(db, mobileNumber);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    objRazorPay = docSnap.data();
    objRazorPay.id = docSnap.id;
  } else {
    // docSnap.data() will be undefined in this case
  }
  return objRazorPay;
};

const getBookedPujaById = async (devoteeNumber: any) => {
  let bookedPujaObj: any;
  const docRef = doc(db, devoteeNumber);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    bookedPujaObj = docSnap.data();
    bookedPujaObj.id = docSnap.id;
  } else {
    // docSnap.data() will be undefined in this case
  }
  return bookedPujaObj;
};

const getVedicSignificanceID = async (vedicRef_Id: any) => {
  let obj_Temple: any;
  const docRef = doc(db, vedicRef_Id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    obj_Temple = docSnap.data();
    obj_Temple.id = docSnap.id;
  } else {
  }

  return obj_Temple;
};

const getPujasData = async () => {
  const pujasArray: any = [];


  try {
    const querySnapshot: any = await getDocs(
      query(collection(db, "pujas"), orderBy("date", "asc"))
    );
    querySnapshot.forEach(async (doc: any) => {
      // Assuming each document contains data in a field named 'dataField'
      let data: any = doc.data();
      data.id = doc.id;
      data.dateFormated =
        "" +
        new Date(
          data?.date?.seconds * 1000 + data?.date?.nanoseconds / 1000000
        );
      const dateB = moment(
        moment(
          new Date(
            data?.date?.seconds * 1000 + data?.date?.nanoseconds / 1000000
          )
        ).format("YYYY-MM-DD")
      );
      const dateC = moment(moment().format("YYYY-MM-DD"));
      data.daysRemaining = dateB.diff(dateC, "days");
      pujasArray.push(data);
    });
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }

  //console.log("finalArray", finalArray);
  return pujasArray;
};

const getTemplsData = async () => {
  const templeArray: any = [];
  try {
    const querySnapshot: any = await getDocs(collection(db, "temples"));
    querySnapshot.forEach(async (doc: any) => {
      // Assuming each document contains data in a field named 'dataField'
      let data: any = doc.data();
      data.id = doc.id;
      templeArray.push(data);
    });
  } catch (error) {
    return [];
  }
  return templeArray;
};

const addDocumentWithCustomID = async (customId: any, dataToSave: any) => {
  // const customId = '+918253085868'; // Replace with your unique ID
  // const dataToSave = {
  //   "email": "ssachint@gmail.com",
  //   "first_name": "Sham_2",
  //   "last_name": "Vagga",
  //   "middle_name": "Chandrakant",
  //   "mobile": "+918263971954",
  //   "prefix": "Mr"

  // };

  // const collectionRef = collection(db, 'devotees');
  // doc(collectionRef, customId);

  try {
    // await addDoc(collectionRef, { ...dataToSave, id: customId });
    await setDoc(doc(db, "devotees", customId), dataToSave);
    return true;
  } catch (error) {
    return false;
  }
};

const checkIfUserExistsOrNot = async (userRefId: any) => {
  let isExist: any;
  const docRef = doc(db, userRefId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    isExist = true;
  } else {
    isExist = false;
  }

  return isExist;
};

const updateBookPujaData = async (mobileNumber: any, payload: any) => {
  // let tempObj:any ={"date": {"nanoseconds": 830000000, "seconds": 1712633400}, "description": "Mahaabhisheka with Panchamrutha &, Dhraavya, special Alankara with fresh & fragrant flowers and Nanda Deepa Seva to Mahaganapati / Bhagwan Vishnu along with goddesses Sridevi & Bhudevi and lighting of Nanda Deepam", "id": "ugadi-2024_subpuja_01", "main_puja_id": {"_key": {"path": "abc/pqr"}, "converter": null, "firestore": "ssa", "type": "document"}, "pic": "https://firebasestorage.googleapis.com/v0/b/jmaya-2024.appspot.com/o/jmaya_puja_images%2Fugadi-2024%2Fmahaabhisheka%2Balankara.png?alt=media&token=990ff387-9702-4526-a977-6cd829d259df", "price": "0", "puja_url": "", "services": ["Live viewing + Recording", "Prasadam"], "status": "booked", "subtitle": "Maha Abhisheka seva , Alankara seva & Nanda Deepa seva", "title": "Maha Abhisheka Seva"}
  // let tempPayload:any=[]
  // tempPayload.push(tempObj)
  let isPujaUpdated: any;
  const bookPujaRef = doc(db, "booked_pujas", mobileNumber);
  await updateDoc(bookPujaRef, {
    booked_puja_data: payload,
  })
    .then((data) => {
      isPujaUpdated = true;
    })
    .catch((err) => {
      isPujaUpdated = false;
    });
  return isPujaUpdated;
};

const updateFamilyData = async (
  mobileNumber: any,
  key: string,
  type: string,
  payload: any
) => {
  let isUserDataUpdated: any;
  const userDataRef = doc(db, key, mobileNumber);
  await updateDoc(userDataRef, { [type]: payload })
    .then((data) => {
      isUserDataUpdated = true;
    })
    .catch((err) => {
      isUserDataUpdated = false;
    });
  return isUserDataUpdated;
};

const updateProfileData = async (mobileNumber: any, payload: any) => {
  // let tempObj:any ={"date": {"nanoseconds": 830000000, "seconds": 1712633400}, "description": "Mahaabhisheka with Panchamrutha &, Dhraavya, special Alankara with fresh & fragrant flowers and Nanda Deepa Seva to Mahaganapati / Bhagwan Vishnu along with goddesses Sridevi & Bhudevi and lighting of Nanda Deepam", "id": "ugadi-2024_subpuja_01", "main_puja_id": {"_key": {"path": "abc/pqr"}, "converter": null, "firestore": "ssa", "type": "document"}, "pic": "https://firebasestorage.googleapis.com/v0/b/jmaya-2024.appspot.com/o/jmaya_puja_images%2Fugadi-2024%2Fmahaabhisheka%2Balankara.png?alt=media&token=990ff387-9702-4526-a977-6cd829d259df", "price": "0", "puja_url": "", "services": ["Live viewing + Recording", "Prasadam"], "status": "booked", "subtitle": "Maha Abhisheka seva , Alankara seva & Nanda Deepa seva", "title": "Maha Abhisheka Seva"}
  // let tempPayload:any=[]
  // tempPayload.push(tempObj)
  let isProfileDataUpdated: any;
  const profileRef = doc(db, "devotees", mobileNumber);
  await updateDoc(profileRef, payload)
    .then((data) => {
      isProfileDataUpdated = true;
    })
    .catch((err) => {
      isProfileDataUpdated = false;
    });
  return isProfileDataUpdated;
};

const deleteProfileData = async (mobileNumber: any, key: string) => {
  // let tempObj:any ={"date": {"nanoseconds": 830000000, "seconds": 1712633400}, "description": "Mahaabhisheka with Panchamrutha &, Dhraavya, special Alankara with fresh & fragrant flowers and Nanda Deepa Seva to Mahaganapati / Bhagwan Vishnu along with goddesses Sridevi & Bhudevi and lighting of Nanda Deepam", "id": "ugadi-2024_subpuja_01", "main_puja_id": {"_key": {"path": "abc/pqr"}, "converter": null, "firestore": "ssa", "type": "document"}, "pic": "https://firebasestorage.googleapis.com/v0/b/jmaya-2024.appspot.com/o/jmaya_puja_images%2Fugadi-2024%2Fmahaabhisheka%2Balankara.png?alt=media&token=990ff387-9702-4526-a977-6cd829d259df", "price": "0", "puja_url": "", "services": ["Live viewing + Recording", "Prasadam"], "status": "booked", "subtitle": "Maha Abhisheka seva , Alankara seva & Nanda Deepa seva", "title": "Maha Abhisheka Seva"}
  // let tempPayload:any=[]
  // tempPayload.push(tempObj)
  let isDeletedUser: any;
  const deleteUser = doc(db, key, mobileNumber);
  await deleteDoc(deleteUser)
    .then((data) => {
      isDeletedUser = true;
    })
    .catch((err) => {
      isDeletedUser = false;
    });
  return isDeletedUser;
};

const updatePaidBookedPujasData = async (mobileNumber: any, payload: any) => {
  // let tempObj:any ={"date": {"nanoseconds": 830000000, "seconds": 1712633400}, "description": "Mahaabhisheka with Panchamrutha &, Dhraavya, special Alankara with fresh & fragrant flowers and Nanda Deepa Seva to Mahaganapati / Bhagwan Vishnu along with goddesses Sridevi & Bhudevi and lighting of Nanda Deepam", "id": "ugadi-2024_subpuja_01", "main_puja_id": {"_key": {"path": "abc/pqr"}, "converter": null, "firestore": "ssa", "type": "document"}, "pic": "https://firebasestorage.googleapis.com/v0/b/jmaya-2024.appspot.com/o/jmaya_puja_images%2Fugadi-2024%2Fmahaabhisheka%2Balankara.png?alt=media&token=990ff387-9702-4526-a977-6cd829d259df", "price": "0", "puja_url": "", "services": ["Live viewing + Recording", "Prasadam"], "status": "booked", "subtitle": "Maha Abhisheka seva , Alankara seva & Nanda Deepa seva", "title": "Maha Abhisheka Seva"}
  // let tempPayload:any=[]
  // tempPayload.push(tempObj)
  let isPujaUpdated: any;
  const bookPujaRef = doc(db, "paid_booked_pujas", mobileNumber);
  await updateDoc(bookPujaRef, {
    booked_puja_data: payload,
  })
    .then((data) => {
      isPujaUpdated = true;
    })
    .catch((err) => {
      isPujaUpdated = false;
    });
  return isPujaUpdated;
};

const addNewBookedPujasRecord = async (customId: any, dataToSave: any) => {
  // let tempObj:any ={"date": {"nanoseconds": 830000000, "seconds": 1712633400}, "description": "Mahaabhisheka with Panchamrutha &, Dhraavya, special Alankara with fresh & fragrant flowers and Nanda Deepa Seva to Mahaganapati / Bhagwan Vishnu along with goddesses Sridevi & Bhudevi and lighting of Nanda Deepam", "id": "ugadi-2024_subpuja_01", "main_puja_id": {"_key": {"path": "abc/pqr"}, "converter": null, "firestore": "ssa", "type": "document"}, "pic": "https://firebasestorage.googleapis.com/v0/b/jmaya-2024.appspot.com/o/jmaya_puja_images%2Fugadi-2024%2Fmahaabhisheka%2Balankara.png?alt=media&token=990ff387-9702-4526-a977-6cd829d259df", "price": "0", "puja_url": "", "services": ["Live viewing + Recording", "Prasadam"], "status": "booked", "subtitle": "Maha Abhisheka seva , Alankara seva & Nanda Deepa seva", "title": "Maha Abhisheka Seva"}
  // let tempPayload:any=[]
  // tempPayload.push(tempObj)

  try {
    // await addDoc(collectionRef, { ...dataToSave, id: customId });
    await setDoc(doc(db, "booked_pujas", customId), {
      booked_puja_data: dataToSave,
    });
    return true;
  } catch (error) {
    return false;
  }
};

const addNewFamilyUserRecord = async (
  customId: any,
  key: string,
  subKey: string,
  dataToSave: any
) => {
  try {
    await setDoc(doc(db, key, customId), { [subKey]: dataToSave });
    return true;
  } catch (error) {
    return false;
  }
};

const addPaidBookedPujasRecord = async (customId: any, dataToSave: any) => {
  // let tempObj:any ={"date": {"nanoseconds": 830000000, "seconds": 1712633400}, "description": "Mahaabhisheka with Panchamrutha &, Dhraavya, special Alankara with fresh & fragrant flowers and Nanda Deepa Seva to Mahaganapati / Bhagwan Vishnu along with goddesses Sridevi & Bhudevi and lighting of Nanda Deepam", "id": "ugadi-2024_subpuja_01", "main_puja_id": {"_key": {"path": "abc/pqr"}, "converter": null, "firestore": "ssa", "type": "document"}, "pic": "https://firebasestorage.googleapis.com/v0/b/jmaya-2024.appspot.com/o/jmaya_puja_images%2Fugadi-2024%2Fmahaabhisheka%2Balankara.png?alt=media&token=990ff387-9702-4526-a977-6cd829d259df", "price": "0", "puja_url": "", "services": ["Live viewing + Recording", "Prasadam"], "status": "booked", "subtitle": "Maha Abhisheka seva , Alankara seva & Nanda Deepa seva", "title": "Maha Abhisheka Seva"}
  // let tempPayload:any=[]
  // tempPayload.push(tempObj)

  try {
    // await addDoc(collectionRef, { ...dataToSave, id: customId });
    await setDoc(doc(db, "paid_booked_pujas", customId), {
      booked_puja_data: dataToSave,
    });
    return true;
  } catch (error) {
    return false;
  }
};

const getRashiPhalaData = async (path: any) => {
  const labelArray: any = [];
  const rashiPhalaArray: any = [];
  try {
    const querySnapshot: any = await getDocs(collection(db, path));
    querySnapshot.forEach(async (doc: any) => {
      // Assuming each document contains data in a field named 'dataField'
      let data: any = doc.data();
      data.id = doc.id;
      labelArray.push(doc.id);
      rashiPhalaArray.push(data);
    });
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
  return { labelArray, rashiPhalaArray };
};
const uploadProfilePhoto = async (data: any) => {
  const storageRef = ref(storage, `jmaya_profile_photos/${data.name}`);
  const uploadTask = uploadBytesResumable(storageRef, data);
  const userDatas: any = await getStringAsync("userDatas");

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
    },
    (error) => {
      console.error(error);
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
        let requestData = {
          profile_photo: downloadURL || "",
        };
        await fireBaseUtil.updateProfileData(
          JSON.parse(userDatas)?.phoneNumber,
          requestData
        );
      });
    }
  );

  // storage
  //   .ref(`/covers/${filename}`)
  //   .getDownloadURL()
  //   .then((url) => {
  //     console.log("Got download url: ", url);
  //   });
  // try {
  //   const uploadUri = data?.uri;
  //   let filename = uploadUri.substring(uploadUri.lastIndexOf("/") + 1);
  //   const extension = filename.split(".").pop();
  //   const name = filename.split(".").slice(0, -1).join(".");
  //   filename = name + Date.now() + "." + extension;
  //   const storageRef = firebase
  //     .storage()
  //     .ref(`jmaya_profile_photos/${filename}`);
  //   const task = storageRef.putFile(uploadUri);
  //   try {
  //     await task;
  //     const url = await storageRef.getDownloadURL();
  //     console.log("uploadProfilePhoto", url);
  //     return url;
  //   } catch (error) {
  //     console.error("Error uploadProfilePhoto data:", error);
  //     return [];
  //   }
  // } catch (error) {
  //   console.error("Error uploadProfilePhoto data:", error);
  //   return [];
  // }
};

const fireBaseUtil = {
  getPujasData,
  getTempleByID,
  getTemplsData,
  getDevoteeById,
  getRazorPayById,
  addDocumentWithCustomID,
  checkIfUserExistsOrNot,
  getBookedPujaById,
  updateBookPujaData,
  updateFamilyData,
  updatePaidBookedPujasData,
  updateProfileData,
  deleteProfileData,
  addNewBookedPujasRecord,
  addNewFamilyUserRecord,
  addPaidBookedPujasRecord,
  getRashiPhalaData,
  uploadProfilePhoto,
  getPujasById,
};

export default fireBaseUtil;

export const sendSMSBookingData = async (
  desc: any,
  onSuccess: any,
  onFail: any
) => {
  const mobileNum: any = await getStringAsync("userDatas");

  fetch(
    `https://ultronsms.com/api/mt/SendSMS?APIKey=pG9pWO3gvUmw5IVHnzxd5w&senderid=JOTIRG&channel=Trans&DCS=0&flashsms=0&number=${
      JSON.parse(mobileNum)?.phoneNumber
    }&text=${desc}&route=2&peid=1701171809993638732&DLTTemplateId=1707171818867954431`,
    { method: "GET" }
  )
    .then((response) => response.text())
    .then((result) => console.log("result sendSMSBookingData", result))
    .catch((error) => console.error(error));

  return;
  console.log("Ca;;ed");

  // const mobileNum: any = await getStringAsync("userDatas");

  // `http://ultronsms.com/api/mt/SendSMS?APIKey=pG9pWO3gvUmw5IVHnzxd5w&senderid=JOTIRG&channel=Trans&DCS=0&flashsms=0&number=8263971954&text=Dear devotee, your puja booking is confirmed! Confirmation%23 sham Puja Date :2024-14-06 - Team JyotirGamaya.online# 123A Puja Date : sfgg- Team JyotirGamaya.online&route=2&peid=1701171809993638732&DLTTemplateId=1707171818867954431`;

  // let config = {
  //   method: "get",
  //   maxBodyLength: Infinity,
  //   url: `http://ultronsms.com/api/mt/SendSMS?APIKey=pG9pWO3gvUmw5IVHnzxd5w&senderid=JOTIRG&channel=Promotional&DCS=0&flashsms=0&number=${
  //     JSON.parse(mobileNum)?.phoneNumber
  //   }&text=${decodeURI(desc)}&route=3`,
  // };

  // axios
  //   .request(config)
  //   .then((response) => {
  //     onSuccess(response.data);
  //     console.log(JSON.stringify(response.data));
  //   })
  //   .catch((error) => {
  //     onFail(error);
  //     console.log(error);
  //   });
};
