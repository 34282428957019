import "./style.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import backArrow from "../../assets/images/back-arrow.svg";
import whiteRightArrow from "../../assets/images/whiteRightArrow.svg";
import plusCircle from "../../assets/images/plusCircle.svg";
import greenRight from "../../assets/images/greenRight.png";
import removeIcon from "../../assets/images/removeIcon.svg";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import { getStringAsync } from "../../lib/storage";
import fireBaseUtil, { sendSMSBookingData } from "../../utils/fireBaseUtil";
import useAppDispatch from "../../hooks/useAppDispatch";
import {
  getRazorpayPurchasePuja,
  setBookedAllPujasList,
  setPaidBookedAllPujasList,
} from "./booking.actions";
import { useAppAccessor } from "../../hooks";
import { generateUniqueId, totalPrice } from "../../utils/GlobalFunctions";
import Footer from "../Footer";
import { GET_PROFILE_SUCCESS } from "../Profile/profile.actionType";
import { GET_RAZORPAY_BOOKED_SUCCESS } from "./booking.actionsType";
import moment from "moment";
import axios from "axios";
import { setAllPujasDataList } from "../Home/home.actions";

//----- Payment Successful Popup -----//
const Popup = ({ onClose }: any) => {
  return (
    <div className="payment-successful-popup">
      <div className="popup-inner">
        <p className="payment-successful-txt">{"Payment Successful!"}</p>
        <p className="payment-successful-des">
          {
            "We will notify you of your upcoming puja 24 hours in prior. You can also view your booked pujas in your profile page"
          }
        </p>
        <button onClick={onClose} className="btn done-btn">
          Done
        </button>
      </div>
    </div>
  );
};

const MakePayment = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { getBookingProps, getProfileProps, getHomeProps } = useAppAccessor();
  const { allBookedPujas, paidBookedPujas, razorPayPuchasedData } =
    getBookingProps();
  const { profileData } = getProfileProps();

  const { allPujaData } = getHomeProps();

  const [dakshinaAmount, setDakshinaAmount] = useState("");
  const [renovationAmount, setRenovationAmount] = useState("");
  const [donationTowards, setDonationTowards] = useState("");
  const [isPrasadam, setIsPrasadam] = useState(false);
  const [buttonDisble, setButtonDisble] = useState(false);

  useEffect(() => {
    getBookedPujasByuser();
    getUserDetails();
    getAllPujas();
    dispatch(getRazorpayPurchasePuja());
  }, []);

  const getAllPujas = async () => {
    await fireBaseUtil?.getPujasData().then((data: any) => {
      dispatch(setAllPujasDataList(data));
    });
  };

  const getUserDetails = async () => {
    const data: any = await getStringAsync("userDatas");

    await fireBaseUtil
      ?.getDevoteeById(`/devotees/${JSON.parse(data)?.phoneNumber}`)
      .then((data) => {
        dispatch({ type: GET_PROFILE_SUCCESS, payload: data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBookedPujasByuser = async () => {
    const mobileNum: any = await getStringAsync("userDatas");

    await fireBaseUtil
      .getBookedPujaById(`/booked_pujas/${JSON.parse(mobileNum)?.phoneNumber}`)
      .then((data: any) => {
        dispatch(setBookedAllPujasList(data?.booked_puja_data || []));
      })
      .catch((err: any) => { });
  };

  const updatePujaTableDB = async (pujaData: any) => {
    const mobileNum: any = await getStringAsync("userDatas");

    const ispujaBooked = await fireBaseUtil.updateBookPujaData(
      JSON.parse(mobileNum)?.phoneNumber,
      pujaData
    );
  };

  const addBookPujaFirstTime = async (pujaData: any) => {
    const mobileNum: any = await getStringAsync("userDatas");

    const ispujaBooked = await fireBaseUtil.addNewBookedPujasRecord(
      JSON.parse(mobileNum)?.phoneNumber,
      pujaData
    );
  };

  const addBookPaidPujaFirstTime = async (pujaData: any) => {
    const mobileNum: any = await getStringAsync("userDatas");
    const ispujaBooked = await fireBaseUtil.addPaidBookedPujasRecord(
      JSON.parse(mobileNum)?.phoneNumber,
      pujaData
    );
  };

  const updatedPaidBookedDB = async (pujaData: any) => {
    const mobileNum: any = await getStringAsync("userDatas");
    const ispujaBooked = await fireBaseUtil.updatePaidBookedPujasData(
      JSON.parse(mobileNum)?.phoneNumber,
      pujaData
    );
  };

  const checkIfUserExistPaid = async (payload: any) => {
    const mobileNum: any = await getStringAsync("userDatas");

    const isUserExist = await fireBaseUtil.checkIfUserExistsOrNot(
      `/paid_booked_pujas/${JSON.parse(mobileNum)?.phoneNumber}`
    );
    if (isUserExist) {
      updatedPaidBookedDB(payload);
    } else {
      addBookPaidPujaFirstTime(payload);
    }
    // setIsShowLoader(false)
  };

  const checkIfUserExist = async (payload: any) => {
    const mobileNum: any = await getStringAsync("userDatas");
    const isUserExist = await fireBaseUtil.checkIfUserExistsOrNot(
      `/booked_pujas/${JSON.parse(mobileNum)?.phoneNumber}}`
    );
    if (isUserExist) {
      updatePujaTableDB(payload);
    } else {
      addBookPujaFirstTime(payload);
    }
  };

  let totalAmount =
    totalPrice(allBookedPujas) +
    (Number(dakshinaAmount) || 0) +
    (Number(renovationAmount) || 0) +
    (Number(donationTowards) || 0);

  const onPressRemove = (pujaObj: any) => {
    let tempBookPujas = allBookedPujas;
    let index = tempBookPujas.findIndex((e) => e.id == pujaObj.id);
    if (index != -1) {
      tempBookPujas.splice(index, 1);
      setTimeout(() => {
        dispatch(setBookedAllPujasList(tempBookPujas));
        checkIfUserExist(tempBookPujas);
      }, 100);
    }
  };

  const [showPopup, setShowPopup] = React.useState(false);

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  function formatAmountForRazorpayTest(amount: any) {
    return (amount * 100).toString(); // Convert to paise and string
  }

  const updateUserData = async (userData: any) => {
    const mobileNum: any = await getStringAsync("userDatas");
    await fireBaseUtil.updateFamilyData(
      JSON.parse(mobileNum)?.phoneNumber,
      "razorpay_purchase_puja",
      "razorpayPurchasePuja",
      userData
    );
  };

  const createUserData = async (userData: any) => {
    const mobileNum: any = await getStringAsync("userDatas");

    await fireBaseUtil.addNewFamilyUserRecord(
      JSON.parse(mobileNum)?.phoneNumber,
      "razorpay_purchase_puja",
      "razorpayPurchasePuja",
      userData
    );
  };

  const checkRazorPayPuchasedPuja = async (payload: any) => {
    const mobileNum: any = await getStringAsync("userDatas");

    const isUserExist = await fireBaseUtil.checkIfUserExistsOrNot(
      `/razorpay_purchase_puja/${JSON.parse(mobileNum)?.phoneNumber}`
    );

    if (isUserExist) {
      updateUserData(payload);
      dispatch({
        type: GET_RAZORPAY_BOOKED_SUCCESS,
        payload: { razorpayPurchasePuja: payload },
      });
    } else {
      createUserData(payload);
      dispatch({
        type: GET_RAZORPAY_BOOKED_SUCCESS,
        payload: { razorpayPurchasePuja: payload },
      });
    }
  };

  const sendGupShupWpData = async () => {
    const mobileNum: any = await getStringAsync("userDatas");

    allBookedPujas?.map((i) => {

      const findMainPujaName = allPujaData?.filter(
        (iPuja: any) => `/pujas/${iPuja?.id}` == i?.main_puja_id
      );

      let finalData: any = [];
      finalData?.push(findMainPujaName?.[0]?.name || "");
      finalData?.push(moment(i?.date)?.format("hh:mm: A"));
      finalData?.push(moment(i?.date)?.format("MMMM DD, YYYY"));
      finalData?.push(moment(i?.date)?.format("MMMM DD, YYYY"));
      finalData?.push(moment(i?.date)?.format("hh:mm: A"));
      finalData?.push(moment(i?.date)?.format("hh:mm: A"));
      const data = {
        channel: "whatsapp",
        source: "917028284977",
        destination: mobileNum || "",
        "src.name": "Jyotirgamaya",
        template: `{"id":"18871ebb-741b-40af-a850-b64848ca4ac0","params":${JSON.stringify(finalData) || ""
          }}`,
        message:
          '{"video":{"id":"c98e03ea-11c9-4dc8-a3db-6cb4096259f5"},"type":"video"}',
      };

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://api.gupshup.io/wa/api/v1/template/msg",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          apikey: "lgkldfmswcntxwi4n9808sqafkz0ujl1",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const sendSMSBooking = async () => {
    allBookedPujas?.map(async (i) => {
      const findMainPujaName = allPujaData?.filter(
        (iPuja) => `/pujas/${iPuja?.id}` == i?.main_puja_id
      );

      await sendSMSBookingData(
        `Dear devotee, your puja booking is confirmed! Confirmation%23 ${findMainPujaName?.[0]?.name.replace(
          /[&\/\\#, +()$~%.'":*?<>{}]/g,
          "_"
        )} Puja Date :${moment(i?.date)?.format(
          "YYYY-DD-MM"
        )} - Team JyotirGamaya.online`,

        (res: any) => {
          console.log("sendSMSBookingData", res);
        },
        (error: any) => {
          console.log("sendSMSBookingData Error", error);
        }
      );
    });
  };

  const createOrder = async (amount: any) => {
    try {
      const response = await axios.post(
        'https://us-central1-jmaya-2024.cloudfunctions.net/createRazorpayOrder',
        { amount }
      );
      setButtonDisble(false)
      return response.data.id; // Return the order_id
    } catch (error) {
      setButtonDisble(false)
      console.error('Error creating order:', error);
      throw error;
    }
  };


  const togglePopup = async () => {
    if (totalAmount != 0) {
      const res = await loadRazorpayScript();
      setButtonDisble(true)
      const orderId = await createOrder(formatAmountForRazorpayTest(totalAmount || 0))
      setButtonDisble(false)
      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      const options = {
        key: "rzp_live_ouAgj30TFRyv1n", // Enter the Key ID generated from the Dashboard
        // key: "rzp_live_ffVjplzsRVUCwG",
        amount: formatAmountForRazorpayTest(totalAmount || 0), // Amount is in currency subunits. For INR, 50000 paisa equals INR 500.
        currency: "INR",
        order_id:orderId,
        name: `${profileData?.first_name || ""} ${profileData?.last_name || ""
          }`,
        handler: function (response: any) {
          sendGupShupWpData();
          sendSMSBooking();

          let oldBookedPujas = [].concat(
            ...(allBookedPujas || []),
            ...(paidBookedPujas || [])
          );
          checkIfUserExistPaid(oldBookedPujas);
          dispatch(setPaidBookedAllPujasList(oldBookedPujas));
          let userData: any = {};

          userData.uniqueId = generateUniqueId();
          userData.bookedPujasId = oldBookedPujas?.map((i: any) => i?.id);
          userData.razorpayData = response;
          userData.createAt = new Date();
          userData.user = {
            first_name: profileData?.first_name,
            last_name: profileData?.last_name,
            email: profileData?.email,
            mobile: profileData?.mobile,
          };

          userData.donation = {
            acharyaDakshina: dakshinaAmount,
            daanMandiraJeernoddhara: renovationAmount,
            daanGaushala: donationTowards,
          };
          userData.TotalPujaAmount = totalAmount
          let oldData: any = razorPayPuchasedData?.razorpayPurchasePuja || [];

          oldData.push(userData);

          checkRazorPayPuchasedPuja(oldData);
          updatePujaTableDB([]);

          setShowPopup(!showPopup);
        },
        prefill: {
          email: profileData?.email || "",
          contact: profileData?.mobile || "",
          name: `${profileData?.first_name || ""} ${profileData?.last_name || ""
            }`,
        },
        theme: { color: "#FF5520" },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }
  };

  const onCloseModal = () => {
    navigate("/home");
  };

  return (
    <div>
      <Header />
      <div className="header-section-makepayment">
        <div className="container">
          <div className="flex">
            <img
              className="back-arrow-icon"
              id="backButton"
              src={backArrow}
              alt="Back"
              onClick={() => navigate(-1)}
            />
            <span className="make-payment-title">{"Puja Booking"}</span>
          </div>

          {totalAmount != 0 ? (
            <div className="container">
              <div className="row">
                {/*----- Your Order -----*/}
                <div className="col-12 col-lg-9 col-xl-9 col-md-8 col-sm-9 pl-inherit">
                  <div className="order-card">
                    <p className="your-order">{"Your Order"}</p>
                    <p className="your-order-description">
                      {"Make payment now to get your puja booked"}
                    </p>

                    {allBookedPujas?.map((i) => {
                      return (
                        <div className="puja-card">
                          <div>
                            <img
                              className="puja-img cardImg"
                              src={
                                i?.pic ||
                                require("../../assets/images/tmpPujas.jpeg") ||
                                ""
                              }
                              alt="pujaImg"
                            />
                          </div>
                          <div className="puja-img-right-div">
                            <div className="puja-sub-title">{`${i?.title || ""
                              }`}</div>
                            <div className="puja-title">{`${i?.pujaIndex || ""
                              } : ${i?.puja_name || ""}`}</div>

                            <div className="puja-undersub-title truncate-text">
                              {i?.description || ""}
                            </div>
                            {i?.detail_description && (
                              <div className="puja-description truncate-text">
                                {i?.detail_description || ""}
                              </div>
                            )}
                            <div className="truncate-text">
                              {`Price : ${i?.price || ""}`}
                            </div>
                          </div>
                          <div
                            onClick={() => onPressRemove(i)}
                            className="removeBtn"
                          >
                            <a style={{ cursor: "pointerI" }}>
                              <img src={removeIcon} alt="Remove" />
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="free-add-ons-card">
                    <p className="free-add-ons-txt">{"Add Ons"}</p>
                    <div className="would-you-like-bg">
                      <div>
                        <p className="puja-title">
                          {"Would you like to recieve a prasadam?"}
                        </p>
                        <p className="free-add-ons-description">
                          {
                            "A small package of God's blessings in the form of prasadam will be couriered to devotee's address free of cost"
                          }
                        </p>
                      </div>
                      <div className="plus-div">
                        <a
                          onClick={() => setIsPrasadam(!isPrasadam)}
                          style={{ cursor: "pointerI" }}
                        >
                          <img
                            src={isPrasadam ? greenRight : plusCircle}
                            alt="Remove"
                            style={{ height: 25, width: 25 }}
                          />
                        </a>
                        {/* <p className="plus-txt">{"+"}</p> */}
                      </div>
                    </div>
                  </div>

                  <div className="container nopaddingGrid">
                    <div className="row">
                      {/*----- Card 1 -----*/}
                      <div className="col-12 col-lg-6 col-xl-6 col-md-12 col-sm-6">
                        <div className="addons-puja-card">
                          <p className="puja-title">{"Achaya Dakshina"}</p>
                          <p className="puja-description">
                            {
                              "Yatashakti Dakshina to the priest/s for performing ritualistic puja seva to God. The blessings of priests is considered sacred in Hinduism"
                            }
                          </p>
                          <div className="input-field">
                            <input
                              value={dakshinaAmount}
                              onChange={(e) =>
                                setDakshinaAmount(e.target.value)
                              }
                              type="text"
                              placeholder="Include any amount"
                              readOnly={totalAmount === 0}
                            />
                          </div>
                        </div>
                      </div>

                      {/*----- Card 2 -----*/}
                      <div className="col-12 col-lg-6 col-xl-6 col-md-12 col-sm-6">
                        <div className="addons-puja-card">
                          <p className="puja-title">
                            {"Contribution Towards Gow Shala"}
                          </p>
                          <p className="puja-description">
                            {
                              "Contribution towards the welfare of Gows is thought a pious deed. One receives extraordinary spiritual benefits & positive energy with Gowseva(feeding,serving,worshipping)"
                            }
                          </p>
                          <div className="input-field">
                            <input
                              type="text"
                              placeholder="Include any amount"
                              value={donationTowards}
                              onChange={(e) =>
                                setDonationTowards(e.target.value)
                              }
                              readOnly={totalAmount === 0}
                            />
                          </div>
                        </div>
                      </div>

                      {/*----- Card 3 -----*/}
                      <div className="col-xs-12 col-md-12 col-sm-12">
                        <div className="addons-puja-card">
                          <p className="puja-title">
                            {
                              "Contribution Towards Temple Renovation & Maintenance"
                            }
                          </p>
                          <p className="puja-description">
                            {
                              "Temples for Hindus is a place of worship and a store house of culture & tradition.Protecting and restoring Hindu temples is a pious deed of preserving heritage. It is believed that one can free self from sins of 100 births by building a temple."
                            }
                          </p>
                          <div className="input-field">
                            <input
                              type="text"
                              placeholder="Include any amount"
                              value={renovationAmount}
                              onChange={(e) =>
                                setRenovationAmount(e.target.value)
                              }
                              readOnly={totalAmount === 0}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*----- Order Summary -----*/}
                <div className="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-3 ">
                  <p className="order-summary">{"Order Summary"}</p>
                  {allBookedPujas?.map((i, index) => {
                    return (
                      <div className="d-flex-jc-sb">
                        <p className="item-txt-value">{`${i?.pujaIndex || ""} ${i?.puja_name || ""
                          }`}</p>
                        <p className="item-txt-value">{i?.price || ""}</p>
                      </div>
                    );
                  })}

                  <div className="d-flex-jc-sb">
                    <p className="item-txt-value">{"Achaya Dakshina"}</p>
                    <p className="item-txt-value">{dakshinaAmount || 0}</p>
                  </div>
                  <div className="d-flex-jc-sb">
                    <p className="item-txt-value">{"Towards Gow Shala"}</p>
                    <p className="item-txt-value">{donationTowards || 0}</p>
                  </div>
                  <div className="d-flex-jc-sb">
                    <p className="item-txt-value">
                      {"Towards Temple Renovation & Maintenance"}
                    </p>
                    <p className="item-txt-value">{renovationAmount || 0}</p>
                  </div>
                  <div className="d-flex-jc-sb">
                    <p className="item-txt-value">{"Tax"}</p>
                    <p className="item-txt-value">{"0"}</p>
                  </div>

                  <div className="d-flex-jc-sb">
                    <p className="total-txt-value">{"Total"}</p>
                    <p className="total-txt-value">{`${totalAmount || ""}`}</p>
                  </div>

                  <div className="d-flex-jc-sb mt-40">
                    <a className="promo-code">{"User a promo code"}</a>
                  </div>

                  {!buttonDisble ? <div
                    onClick={togglePopup}
                    className={`continue-btn-div ${totalAmount === 0 ? "disabled" : ""
                      }`}
                    // onClick={totalAmount === 0 ? null : togglePopup}
                    style={{
                      cursor: totalAmount === 0 ? "default" : "pointer",
                    }}
                  >
                    <button
                      className="btn continue-btn"
                      style={{ border: "none" }}
                      disabled={totalAmount === 0}
                    >
                      Continue
                    </button>
                    <img
                      className="white-arrow-right"
                      id="backButton"
                      src={whiteRightArrow}
                      alt="Back"
                    // onClick={() => }
                    />
                  </div>
                    :
                    <div
                      className={`continue-btn-div ${totalAmount === 0 ? "disabled" : ""
                        }`}
                      // onClick={totalAmount === 0 ? null : togglePopup}
                      style={{
                        cursor: totalAmount === 0 ? "default" : "pointer",
                      }}
                    >
                      <button
                        className="btn continue-btn"
                        style={{ border: "none" }}
                        disabled={totalAmount === 0}
                      >
                       Loading...
                      </button>
                      <img
                        className="white-arrow-right"
                        id="backButton"
                        src={whiteRightArrow}
                        alt="Back"
                      // onClick={() => }
                      />
                    </div>
                  }

                  <div className="ta-center">
                    <p className="or-txt">or</p>
                    <button
                      className="btn continue-shopping"
                      onClick={() => navigate("/home")}
                    >
                      Continue Shopping
                    </button>
                  </div>
                </div>

                <div className="col-12 col-lg-9 col-xl-9 col-md-12 col-sm-9 pl-inherit">
                  {/* <div className="free-add-ons-card">
                    <p className="free-add-ons-txt">{"Add Ons"}</p>
                    <div className="would-you-like-bg">
                      <div>
                        <p className="puja-title">
                          {"Would you like to recieve a prasadam?"}
                        </p>
                        <p className="free-add-ons-description">
                          {
                            "A small package of God's blessings in the form of prasadam will be couriered to devotee's address free of cost"
                          }
                        </p>
                      </div>
                      <div className="plus-div">
                      <a style={{ cursor: "pointerI" }}>
                      <img src={plusCircle} alt="Remove"/></a>
                       
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="container nopaddingGrid">
                    <div className="row">
                    
                      <div className="col-xs-12 col-md-6 col-sm-6">
                        <div className="addons-puja-card">
                          <p className="puja-title">{"Achaya Dakshina"}</p>
                          <p className="puja-description">
                            {
                              "Yatashakti Dakshina to the priest/s for performing ritualistic puja seva to God. The blessings of priests is considered sacred in Hinduism"
                            }
                          </p>
                          <div className="input-field">
                            <input
                              value={dakshinaAmount}
                              onChange={(e) =>
                                setDakshinaAmount(e.target.value)
                              }
                              type="text"
                              placeholder="Include any amount"
                              readOnly={totalAmount === 0}
                            />
                          </div>
                        </div>
                      </div>

                     
                      <div className="col-xs-12 col-md-6 col-sm-6">
                        <div className="addons-puja-card">
                          <p className="puja-title">
                            {"Contribution Towards Gow Shala"}
                          </p>
                          <p className="puja-description">
                            {
                              "Contribution towards the welfare of Gows is thought a pious deed. One receives extraordinary spiritual benefits & positive energy with Gowseva(feeding,serving,worshipping)"
                            }
                          </p>
                          <div className="input-field">
                            <input
                              type="text"
                              placeholder="Include any amount"
                              value={donationTowards}
                              onChange={(e) =>
                                setDonationTowards(e.target.value)
                              }
                              readOnly={totalAmount === 0}
                            />
                          </div>
                        </div>
                      </div>

                     
                      <div className="col-xs-12 col-md-12 col-sm-12">
                        <div className="addons-puja-card">
                          <p className="puja-title">
                            {
                              "Contribution Towards Temple Renovation & Maintenance"
                            }
                          </p>
                          <p className="puja-description">
                            {
                              "Temples for Hindus is a place of worship and a store house of culture & tradition.Protecting and restoring Hindu temples is a pious deed of preserving heritage. It is believed that one can free self from sins of 100 births by building a temple."
                            }
                          </p>
                          <div className="input-field">
                            <input
                              type="text"
                              placeholder="Include any amount"
                              value={renovationAmount}
                              onChange={(e) =>
                                setRenovationAmount(e.target.value)
                              }
                              readOnly={totalAmount === 0}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="col-xs-12 col-md-4 col-sm-4"></div>
              </div>
            </div>
          ) : (
            <div style={{ height: 600, textAlign: "center", marginTop: 100 }}>
              <h4>
                Your spiritual journey awaits! Explore our offerings and add
                meaningful pujas to your cart.{" "}
              </h4>
            </div>
          )}
        </div>
      </div>

      {/*----- Show PopUp -----*/}
      {showPopup && <Popup onClose={onCloseModal} />}
      <Footer />
    </div>
  );
};

export default MakePayment;
