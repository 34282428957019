import "./style.css";
import mainLogo from "../../../assets/images/logo-main.svg";
import DownloadApp from "../../../assets/images/DownloadApp.svg";
import Select from "../../Common/SelectDropdown";
import { useNavigate, useNavigation } from "react-router-dom";
import { useState } from "react";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { setSelectedRashi } from "../../AstroRecommendation/Reducer/astroReco.action";

const Rashi = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [rashi, setRashi] = useState("");
  const [isYearlySelect, setIsYearlySelect] = useState(true);

  const onClickPrediction = () => {
    if (rashi != "") {
      dispatch(
        setSelectedRashi({
          rashi: rashi?.[0] || {},
          isYearlySelect: isYearlySelect,
        })
      );
      navigate("/astrorecommendation");
    } else {
      alert("Please select rashi");
    }
  };

  return (
    <div className="">
      <div className="Rashi flex">
        <div className="row rashi-img-bg">
          <div className="col-12 col-lg-6 col-xl-6 col-md-12 col-sm-6 video-pl-pt">
            <div className="video-section">
              <video
                width="100%"
                height=""
                controls
                poster={require("../../../assets/images/videoPoster.jpeg")}
                style={{
                  borderWidth: 1,
                  alignSelf: "center",
                  borderRadius: 20,
                }}
              >
                <source
                  src={require("../../../assets/videos/video.mov")}
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
          
          <div className="col-12 col-lg-6 col-xl-6 col-md-12 col-sm-6 video-txt-div">
            <div>
              <p className="title-text">
                {" "}
                Vedic Astrology Prediction
              </p>
              <p className="text-disc font-reg">
                Get your rashi based predictions for krodhi <br /> samvathsara
                (2024) + customised pujas
              </p>
            </div>
            <div className="d-flex butons">
              <span
                onClick={() => setIsYearlySelect(true)}
                className={`btn-custom font-reg ${isYearlySelect ? "btn-active" : ""
                  }`}
              >
                Yearly
              </span>
              <span
                onClick={() => setIsYearlySelect(false)}
                className={`btn-custom font-reg ${!isYearlySelect ? "btn-active" : ""
                  }`}
              >
                Monthly
              </span>
            </div>
            <div className="rashi-outer">
              <p className="rashi-title">
                Tell us the Rashi (Moon sign)
              </p>
              <div className="rashi-d-flex rashi-select dropdown">
                <div className="select-div">
                  <Select isYearlySelect={isYearlySelect} setRashi={setRashi} />
                </div>

                <span
                  onClick={onClickPrediction}
                  className="btn-custom font-reg yearly-predication-btn"
                >
                  {`${isYearlySelect ? "Yearly" : "Monthly"} Prediction`}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rashi;
