import React, { useEffect, useState } from "react";
import Header from "../Header";
import Banner from "./Banner";
import PujaList from "./PujaList";
import AboutPuja from "./AboutPuja";
import Rashi from "./Rashi";
import whiteRightArrow from "../../assets/images/whiteRightArrow.svg";
import useAppDispatch from "../../hooks/useAppDispatch";
import "./style.css";
import { useAppAccessor } from "../../hooks";
import moment from "moment";
import fireBaseUtil from "../../utils/fireBaseUtil";
import {
  clearVedicSignDataList,
  setAllTempleDataList,
  setSelectedPujaObj,
  setVedicSingleObj,
} from "../Home/home.actions";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getStringAsync } from "../../lib/storage";
import {
  setBookedAllPujasList,
  setPaidBookedAllPujasList,
} from "../MakePayment/booking.actions";
import Footer from "../Footer";
import Popup from "../Common/LoginModal";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../config/firebaseConfig";
import { toast } from "react-toastify";
import { totalPrice } from "../../utils/GlobalFunctions";

const Puja = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { getHomeProps, getBookingProps } = useAppAccessor();
  const { selectedPujaObj, allTempleData, vedicSignData } = getHomeProps();
  const { allBookedPujas, paidBookedPujas } = getBookingProps();

  const [appVerifier, setAppVerifier] = useState<any>(null);
  const [isSendOTP, setIsSendOTP] = useState(false);
  const [isShowPersonalDetailsPopUp, setIsShowPersonalDetailsPopUp] =
    useState(false);
  const [confirmationResult, setConfirmationResult] = useState<any>(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [mobile, setMobile] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [userData, setUserData] = useState<any>(null);
  const [time, setTime] = useState(2 * 15);


  const [isShowLoader, setIsShowLoader] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    getPujasData();
    getAllTemples();
    getBookedPujasByuser();
    getPaidBookedPujasByuser();
  }, []);

  useEffect(() => {
    if (time > 0) {
      const timerId = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [time]);

  const getPaidBookedPujasByuser = async () => {
    const mobileNum: any = await getStringAsync("userDatas");

    await fireBaseUtil
      .getBookedPujaById(
        `/paid_booked_pujas/${JSON.parse(mobileNum)?.phoneNumber}`
      )
      .then((data: any) => {
        dispatch(setPaidBookedAllPujasList(data?.booked_puja_data || []));
      })
      .catch((err: any) => { });
  };

  const getPujasData = async () => {
    const pujaData = await fireBaseUtil.getPujasById(id);
    dispatch(clearVedicSignDataList([]));
    dispatch(setSelectedPujaObj(pujaData));

    if (pujaData?.vedic_significance) {
      pujaData?.vedic_significance?.map(async (i: any) => {
        await getVedicSignObj(i?.path || "");
      });
    }
  };

  const getAllTemples = async () => {
    await fireBaseUtil
      .getTemplsData()
      .then((data: any) => {
        dispatch(setAllTempleDataList(data || []));
      })
      .catch((err) => { });
  };

  const venue: any = allTempleData?.filter(
    (i) => `temples/${i.id}` == selectedPujaObj?.venue?.path || ""
  )?.[0];

  const getVedicSignObj = async (vedic_sing_Id: any) => {
    await fireBaseUtil
      ?.getTempleByID(vedic_sing_Id)
      .then((data) => {
        dispatch(setVedicSingleObj(data));
        setIsShowLoader(false);
      })
      .catch((err) => {
        setIsShowLoader(false);
      });
  };

  const getBookedPujasByuser = async () => {
    const mobileNum: any = await getStringAsync("userDatas");
    await fireBaseUtil
      .getBookedPujaById(`/booked_pujas/${JSON.parse(mobileNum)?.phoneNumber}`)
      .then((data: any) => {
        dispatch(setBookedAllPujasList(data?.booked_puja_data || []));
      })
      .catch((err: any) => { });
  };

  const onClickVenue = () => {
    navigate(`/temple/${venue?.id || ""}`);
  };

  const onClickAddPuja = async (pujaObj: any, index: number) => {
    let user: any = await localStorage.getItem("userDatas");
    if (user) {

      pujaObj.date = moment(selectedPujaObj.dateFormated).format();
      pujaObj.puja_url = "";
      pujaObj.main_puja_id = `/pujas/${selectedPujaObj.id}`;
      pujaObj.status = "booked";
      pujaObj.pujaIndex = `Puja ${index + 1}`;
      pujaObj.status = "booked";
      pujaObj.pujaName = selectedPujaObj?.name || "";
      pujaObj.quentity = 1;

      let tempBookPujas: any = [];

      if (allBookedPujas != undefined) {
        tempBookPujas = allBookedPujas;
      } else {
        tempBookPujas = [];
      }
      tempBookPujas.push(pujaObj);
      dispatch(setBookedAllPujasList(tempBookPujas));
      checkIfUserExist(tempBookPujas);
    } else {
      togglePopup();
      // alert("You need to login first! then you can purchase puja")
    }
  };

  const removePujaFromList = (pujaObj: any) => {
    setIsShowLoader(true);
    let tempBookPujas = allBookedPujas;
    let index = tempBookPujas.findIndex((e) => e.id == pujaObj.id);
    if (index != -1) {
      tempBookPujas.splice(index, 1);
      setTimeout(() => {
        dispatch(setBookedAllPujasList(tempBookPujas));
        checkIfUserExist(tempBookPujas);
      }, 100);
    }
  };

  const updatePujaTableDB = async (pujaData: any) => {
    const mobileNum: any = await getStringAsync("userDatas");
    const ispujaBooked = await fireBaseUtil.updateBookPujaData(
      JSON.parse(mobileNum)?.phoneNumber,
      pujaData
    );
  };

  const addBookPujaFirstTime = async (pujaData: any) => {
    const mobileNum: any = await getStringAsync("userDatas");
    const ispujaBooked = await fireBaseUtil.addNewBookedPujasRecord(
      JSON.parse(mobileNum)?.phoneNumber,
      pujaData
    );
  };

  const checkIfUserExist = async (payload: any) => {
    const mobileNum: any = await getStringAsync("userDatas");
    const isUserExist = await fireBaseUtil.checkIfUserExistsOrNot(
      `/booked_pujas/${JSON.parse(mobileNum)?.phoneNumber}}`
    );
    if (isUserExist) {
      updatePujaTableDB(payload);
    } else {
      addBookPujaFirstTime(payload);
    }

    setIsShowLoader(false);
    // navigate("/make-payment");
  };

  const createCaptchContainer = () => {
    const captchaElement = document.createElement("div");
    captchaElement.style.visibility = "hidden"; // Example style
    captchaElement.id = "recaptcha-container"; // Example id
    const rootElement = document.getElementById("root");
    if (rootElement) rootElement.appendChild(captchaElement);
  };

  const generateRecaptcha = () => {
    createCaptchContainer();
    const recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response: any) => { },
        "expired-callback": () => { },
      }
    );
    return recaptchaVerifier;
  };

  const togglePopup = () => {
    if (!showPopup) {
      let captchVerifier = appVerifier;
      if (appVerifier === null) {
        captchVerifier = generateRecaptcha();
        setAppVerifier(captchVerifier);
      }
    }
    setShowPopup(!showPopup);
  };

  const handleFireBaseSendCode = (phNumber: any) => {
    setIsLoading(true);

    let captchVerifier = appVerifier;
    if (appVerifier === null) {
      captchVerifier = generateRecaptcha();
      setAppVerifier(captchVerifier);
    }
    signInWithPhoneNumber(auth, `${phNumber}`, captchVerifier)
      .then((confirmationResult: any) => {
        setIsLoading(false);
        setIsSendOTP(true);
        setTime(2 * 15);
        setConfirmationResult(confirmationResult);
        // setShowPopup(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.code === "auth/too-many-requests") {
          toast.warn("Phone number blocked due to unusual activity. Please try again later.");
        }
        console.error("Error sending code:", error);
      });
  };

  const handleVerifyCode = (verificationCode: any) => {
    setIsLoading(true);
    confirmationResult
      ?.confirm(verificationCode)
      ?.then(async (result: any) => {
        setIsLoading(false);
        if (result?.user) {
          //success
          setUserData(result?.user);
          setShowPopup(false);

          const isUserExists = await fireBaseUtil.checkIfUserExistsOrNot(
            `/devotees/${result?.user?.phoneNumber}`
          );
          setPhoneNumber(result?.user?.phoneNumber);

          if (isUserExists) {
            localStorage.setItem(
              "userDatas",
              JSON.stringify(result?.user || {})
            );
            localStorage.setItem("userDatas", JSON.stringify(result?.user || {}));
            if(location.pathname){
              window.location.href = window.location.href;
              navigate(location.pathname);
            }else{
              navigate("/");
            }
          } else {
            setMobile(result?.user?.phoneNumber);
            setIsShowPersonalDetailsPopUp(true);
          }

          toast.success("OTP Verified Successfully");
        }
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.error("Error verifying code:", error);
        toast.error("OTP is Not Verified");
      });
  };

  const handleResendOTP = (phNumber: any) => {
    let captchVerifier = appVerifier;
    if (!appVerifier) {
      captchVerifier = generateRecaptcha();
      setAppVerifier(captchVerifier);
    }
    signInWithPhoneNumber(auth, `${phNumber}`, captchVerifier)
      .then((confirmationResult: any) => {
        setConfirmationResult(confirmationResult);

        setTime(2 * 15);
        toast.success("OTP Resent Successfully");
      })
      .catch((error) => {
        if (error.code === "auth/too-many-requests") {
          toast.warn("Phone number blocked due to unusual activity. Please try again later.");
        } else {
          toast.error("Failed to Resend OTP");
        }
      });
  };

  const checkBookedPuja: any = (item: any) => {
    const findPujaBookedOrNot = paidBookedPujas?.filter(
      (i: any) => `/pujas/${item?.id}` == i?.main_puja_id
    );
    return findPujaBookedOrNot && findPujaBookedOrNot?.length !== 0;
  };

  return (
    <div className="puja-detail">
      <Header />

      {/*----- Banner -----*/}
      <Banner data={selectedPujaObj} />

      {/*----- Puja Title -----*/}
      <div className="container">
        <div className="row">
          <p className="puja-detail-title pl-pr-80 pt-20">
            {selectedPujaObj?.name || ""}
          </p>
        </div>

        {/*----- Date & Venue Pujas -----*/}
        <div className="container">
          <div className="row date-venue">
            <p className="date-venue-bg">
              {" "}
              {moment(selectedPujaObj?.dateFormated).format("Do MMM, dddd")}
            </p>
            <a onClick={onClickVenue} className="venue-bg">
              {" "}
              {`Venue : ${venue?.name || selectedPujaObj?.venue || "" || ""}`}
            </a>

            {!(
              selectedPujaObj.daysRemaining > 0 ||
              (selectedPujaObj.daysRemaining == 0 &&
                new Date(
                  selectedPujaObj?.live_puja_endTime?.seconds * 1000 +
                  selectedPujaObj?.live_puja_endTime?.nanoseconds / 1000000
                ).getTime() >= new Date().getTime())
            ) && <p className="date-venue-bg">Booking closed</p>}
          </div>
        </div>
      </div>

      {/*----- About Puja & Rashi -----*/}
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-7 col-xl-7 col-md-12 col-sm-7">
            <AboutPuja data={selectedPujaObj} />
          </div>
          <div className="col-12 col-lg-5 col-xl-5 col-md-12 col-sm-5 topSpace">
            <Rashi data={vedicSignData} />
          </div>
        </div>
      </div>

      {/*----- Puja List -----*/}
      <div className="mb-5">
        <div className="container">
          <div className="row">
            <p className="different-title">
              {"What are the different pujas you can do?"}
            </p>
            <PujaList
              data={selectedPujaObj?.pujas_available}
              onClickAddPuja={onClickAddPuja}
              removePuja={(data: any) => {
                removePujaFromList(data);
              }}
            />
            {/* <PujaList data={selectedPujaObj?.pujas_available} /> */}
          </div>
        </div>
      </div>

      {/*----- PopUp -----*/}
      {showPopup && (
        <Popup
          isLoading={isLoading}
          recatchaId={"recaptcha-container"}
          onClose={togglePopup}
          data={userData}
          handleOtpCall={(phoneNumber: any) => {
            handleFireBaseSendCode(phoneNumber);
          }}
          handleVerifiyOtp={(code: any) => {
            handleVerifyCode(code);
          }}
          handleResendOtp={(phoneNumber: any) => {
            handleResendOTP(phoneNumber);
          }}
          isSendOTP={isSendOTP}
          time={time}
        />
      )}
      <Footer />

      {/*----- Fixed Button -----*/}
      {allBookedPujas?.length > 0 && (
        <button
          onClick={() => navigate("/make-payment")}
          className="fixed-button"
        >
          <div className="row">
            {allBookedPujas?.map((data) => {
              return (
                <div className="col-7 col-md-7 col-sm-7 ta-left">
                  <label className="c-pointer">
                    {`${data?.pujaIndex || ""} : ${data?.title || ""}`}
                  </label>
                </div>
              );
            })}
            <div className="col-5 col-md-5 col-sm-5 ta-end">
              <label className="c-pointer">{`Rs ${totalPrice(allBookedPujas) || 0
                }`}</label>
              <img
                className="white-right-arrow"
                id="whiteRightArrow"
                src={whiteRightArrow}
                alt="White Right Arrow"
              />
            </div>
          </div>
        </button>
      )}
    </div>
  );
};

export default Puja;
