// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-dropdown-select{
    background-color: white;
    border-radius: 16px !important;
    min-height: 50px !important;
    padding: 2px 15px !important;
    border: none!important;
    font-family: RobotoRegular;
  }
  .react-dropdown-select-content {
    font-size: 18px;
    font-family: RobotoRegular !important;
  }
  .react-dropdown-select-dropdown{
    box-shadow: 0px 7.21px 11.54px 0px #00000033 !important;
    border-radius: 8px !important;
    padding: 15px 15px !important;
  }
  .react-dropdown-select:hover, .react-dropdown-select:focus-within{
    border: none!important;
  }
  .react-dropdown-select-item-selected
  {
    background-color: #FFF8E5 !important;
    color: #25213B !important;
  }
  .react-dropdown-select-item    {
    padding: 10px 10px !important;
    border-radius: 8px !important;
    font-size: 18px;
    font-family: RobotoRegular !important;
  }
  .react-dropdown-select svg{
    width: 30px !important;
    height: 30px !important;
  }


  @media only screen and (max-width: 480px) {
    .react-dropdown-select-content {
      font-size: 14px;
      font-family: RobotoRegular !important;
    }
  }
  
 `, "",{"version":3,"sources":["webpack://./src/modules/Common/SelectDropdown/style.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,8BAA8B;IAC9B,2BAA2B;IAC3B,4BAA4B;IAC5B,sBAAsB;IACtB,0BAA0B;EAC5B;EACA;IACE,eAAe;IACf,qCAAqC;EACvC;EACA;IACE,uDAAuD;IACvD,6BAA6B;IAC7B,6BAA6B;EAC/B;EACA;IACE,sBAAsB;EACxB;EACA;;IAEE,oCAAoC;IACpC,yBAAyB;EAC3B;EACA;IACE,6BAA6B;IAC7B,6BAA6B;IAC7B,eAAe;IACf,qCAAqC;EACvC;EACA;IACE,sBAAsB;IACtB,uBAAuB;EACzB;;;EAGA;IACE;MACE,eAAe;MACf,qCAAqC;IACvC;EACF","sourcesContent":[".react-dropdown-select{\n    background-color: white;\n    border-radius: 16px !important;\n    min-height: 50px !important;\n    padding: 2px 15px !important;\n    border: none!important;\n    font-family: RobotoRegular;\n  }\n  .react-dropdown-select-content {\n    font-size: 18px;\n    font-family: RobotoRegular !important;\n  }\n  .react-dropdown-select-dropdown{\n    box-shadow: 0px 7.21px 11.54px 0px #00000033 !important;\n    border-radius: 8px !important;\n    padding: 15px 15px !important;\n  }\n  .react-dropdown-select:hover, .react-dropdown-select:focus-within{\n    border: none!important;\n  }\n  .react-dropdown-select-item-selected\n  {\n    background-color: #FFF8E5 !important;\n    color: #25213B !important;\n  }\n  .react-dropdown-select-item    {\n    padding: 10px 10px !important;\n    border-radius: 8px !important;\n    font-size: 18px;\n    font-family: RobotoRegular !important;\n  }\n  .react-dropdown-select svg{\n    width: 30px !important;\n    height: 30px !important;\n  }\n\n\n  @media only screen and (max-width: 480px) {\n    .react-dropdown-select-content {\n      font-size: 14px;\n      font-family: RobotoRegular !important;\n    }\n  }\n  \n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
