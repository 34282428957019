import React, { useEffect } from "react";
import Header from "../Header";
import Banner from "./Banner";
import NellaiapparTemple from "./NellaiapparTemple";

import useAppDispatch from "../../hooks/useAppDispatch";
import "./style.css";
import { useAppAccessor } from "../../hooks";
import { useParams } from "react-router-dom";
import fireBaseUtil from "../../utils/fireBaseUtil";
import { setSelectedTempleObj } from "../Home/home.actions";
import Footer from "../Footer";

const TemplePage = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const { getHomeProps } = useAppAccessor();

  const { selectedTempleObj } = getHomeProps();

  useEffect(() => {
    getPujasData();
  }, []);

  const getPujasData = async () => {
    const templeData = await fireBaseUtil.getTempleByID(`/temples/${id}`);

    dispatch(setSelectedTempleObj(templeData || []));
  };

  return (
    <div>
      {/*----- Header -----*/}
      <Header />

      {/*----- Banner -----*/}
      <Banner data={selectedTempleObj} />

      {/*----- Nellaiappar Temple -----*/}
      <NellaiapparTemple data={selectedTempleObj} />

      {/*----- Daan Towards Temple Renovation & Maintenance -----*/}
      {/* <Rashi /> */}
      <Footer />
    </div>
  );
};

export default TemplePage;
