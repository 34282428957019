import "./style.css";
import React from "react";
import { Link } from "react-router-dom";
import backArrow from "../../assets/images/back-arrow.svg";
import messageIcon from "../../assets/images/message.svg";
import whatsappIcon from "../../assets/images/whatApp.svg";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
const AboutUs = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Header />
      <div className="header-section-about">
        <div className="container">
          <div className="flex">
            <img
              className="back-arrow-icon"
              id="backButton"
              style={{ cursor: "pointer" }}
              src={backArrow}
              alt="Back"
              onClick={() => navigate("/")}
            />
            <span className="our-offerings-about">About Us</span>
          </div>
        </div>
      </div>
      <div className="container custom-container">
        <div>
          <p className="disc-tex">
            <span style={{ fontWeight: 600 }}>Jyotirgamaya.online</span> is a venture of Divine Deeds Pvt Ltd stands as India’s online platform,
            dedicated to facilitating Hindu ritual/Puja/Sevas with authenticity
            and precision. Our platform seamlessly connects individuals to a
            comprehensive range of online puja services that are curated with
            the help of India’s seasoned pandits & Astrologers
          </p>
          <p className="title-bold">Our Mission</p>
          <p className="disc-tex">
            At Jyotirgamaya.online, our mission is to provide a sacred bridge
            between devotees and divine blessings. We understand the
            significance of rituals in our lives and strive to make them
            accessible, meaningful, and personalized.
          </p>
          <p className="title-bold">What Sets Us Apart?</p>
          <p className="disc-tex">
            <ul className="li-list">
              <li>
                <span style={{ fontWeight: 600 }}>Expert Pandits:</span> We have
                a team of well-qualified and experienced pandits for performing
                any puja. Our team of priests and astrologers collaboratively
                ensures the authenticity of our services.
              </li>
              <li>
                <span style={{ fontWeight: 600 }}>Customized Rituals:</span>{" "}
                Jyotirgamaya.online intends to provide customized puja services
                based on your birth charts. These services are not only remedial
                but also help improve your mental and physical strength, reduce
                relationship differences, mitigate malicious enemies, overcome
                obstacles in education, and bring peace and prosperity to your
                lives.
              </li>
              <li>
                <span style={{ fontWeight: 600 }}>Wide Range of Pujas:</span>{" "}
                From Mahamritunjaya Japa for good health and longevity to Maa
                Durga Samput path for removing obstacles related to health and
                wealth, we perform almost all kinds of pujas. Additionally, we
                conduct Vedic mantra chanting for planetary blessings.
              </li>
              <li>
                <span style={{ fontWeight: 600 }}>Temple network:</span> We are
                building a network of puratan/ancient temples that need
                attention/help in its upkeep & maintain its vigour.
              </li>
            </ul>
          </p>
          <p className="title-bold">Our Commitment</p>
          <p className="disc-tex">
            <ul className="li-list">
              <li>
                <span style={{ fontWeight: 600 }}>Transparency:</span> We
                believe in transparency. We facilitate live streaming of
                puja/ritual for you to view/and be part of the seva virtually.
              </li>
              <li>
                <span style={{ fontWeight: 600 }}>Customer Testimonials:</span>{" "}
                We seek reviews from our customers about our service as their
                trust/feedback will help us to improve and provide exceptional
                services in the future.
              </li>
            </ul>
          </p>
          <p className="title-bold">Why Choose Jyotirgamaya.online?</p>
          <ul className="li-list">
            <li>
              <strong>Authenticity: </strong> Our pandits have a vivid knowledge
              of the Puranas, Vedas, and astrology, ensuring the authenticity of
              every ritual.
            </li>
            <li>
              <strong>Divine venue: </strong> Our puja/sevas are mostly
              performed in puratan/ancient temples that offer positive vibes and
              divinity.
            </li>
            <li>
              <strong>Convenience: </strong> Perform pujas from the comfort of
              your home, anytime, anywhere.
            </li>
            <li>
              <strong>Peace of Mind: </strong> Let our rituals bring peace,
              harmony, and positive energy into your life.
            </li>
          </ul>
          <div
            className="contact-section"
            style={{
              paddingTop: "15px",
              paddingBottom: "50px",
              borderTop: "1px solid #a6a6a6",
            }}
          >
            <p className="title-bold" style={{ marginBottom: "5px" }}>
              Contact Us
            </p>
            <p className="disc-tex" style={{ fontSize: "14px" }}>
              Contact us in case of any queries, concerns or if you want to
              terminate your account.
            </p>
            <ul
              className="li-list"
              style={{ listStyleType: "none", paddingLeft: "0px" }}
            >
              <li style={{ marginBottom: "10px" }}>
                <a href="mailto:pujas@jyotirgamaya.online">
                  <img
                    src={messageIcon}
                    style={{ paddingRight: "15px" }}
                    alt="Email"
                  />
                  pujas@jyotirgamaya.online
                </a>
              </li>
              <li>
                <a href="https://wa.me/919900941761">
                  <img
                    src={whatsappIcon}
                    style={{ paddingRight: "15px" }}
                    alt="WhatsApp"
                  />
                  +91 - 9900941761
                </a>
              </li>
            </ul>
            <p className="title-bold" style={{ marginBottom: "5px" }}>
              Registered Office
            </p>
            <p className="disc-tex" style={{ fontSize: "14px" }}>
              {`Divine Deeds Pvt Ltd\nRH No. A-7, Srirang Presidency\nVijay Nagar Road, Garkheda\nAurangabad 431001, Maharashtra, India.`}
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
