// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nellaiappar-temple {
  padding: 15px 30px 30px;
  box-shadow: 0px 0px 4px 0px #00000026;
  background-color: #FFFFFF;
  margin-top: 30px;
  margin-left: 72px;
  margin-right: 72px;
  border-radius: 15px;
  margin-bottom: 50px;
}

.nellaiappar-temple .location-icon {
  width: 18px;
  height: 23px;
  margin-bottom: 5px;
}

.nellaiappar-temple .location-label {
  color: #120226;
  font-size: 17px;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
  margin-left: 5px;
}

.nellaiappar-temple .temple-description {
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  margin-top: 30px;
}

.nellaiappar-temple .mb-inherit {
  margin-bottom: inherit;
}

.nellaiappar-temple .bullet-list-bg {
  box-shadow: 0px 0px 4px 0px #00000026;
  background-color: #FDF3CE;
  border-radius: 30px;
  padding: 20px 40px;
  margin-top: 20px;
}

.nellaiappar-temple .special-rituals-here {
  color: #8C1E2B;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.13px;
  text-align: left;
}

.nellaiappar-temple .lakshmi-vishnu-img {
  width: 250px;
  height: 250px;
  margin-top: 10px;
}

.nellaiappar-temple .ta-center {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/modules/TemplePage/NellaiapparTemple/style.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,qCAAqC;EACrC,yBAAyB;EACzB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,qCAAqC;EACrC,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".nellaiappar-temple {\n  padding: 15px 30px 30px;\n  box-shadow: 0px 0px 4px 0px #00000026;\n  background-color: #FFFFFF;\n  margin-top: 30px;\n  margin-left: 72px;\n  margin-right: 72px;\n  border-radius: 15px;\n  margin-bottom: 50px;\n}\n\n.nellaiappar-temple .location-icon {\n  width: 18px;\n  height: 23px;\n  margin-bottom: 5px;\n}\n\n.nellaiappar-temple .location-label {\n  color: #120226;\n  font-size: 17px;\n  font-weight: 400;\n  line-height: 40px;\n  text-align: left;\n  margin-left: 5px;\n}\n\n.nellaiappar-temple .temple-description {\n  color: #000000;\n  font-size: 20px;\n  font-weight: 400;\n  line-height: 26px;\n  text-align: left;\n  margin-top: 30px;\n}\n\n.nellaiappar-temple .mb-inherit {\n  margin-bottom: inherit;\n}\n\n.nellaiappar-temple .bullet-list-bg {\n  box-shadow: 0px 0px 4px 0px #00000026;\n  background-color: #FDF3CE;\n  border-radius: 30px;\n  padding: 20px 40px;\n  margin-top: 20px;\n}\n\n.nellaiappar-temple .special-rituals-here {\n  color: #8C1E2B;\n  font-size: 24px;\n  font-weight: 600;\n  line-height: 28.13px;\n  text-align: left;\n}\n\n.nellaiappar-temple .lakshmi-vishnu-img {\n  width: 250px;\n  height: 250px;\n  margin-top: 10px;\n}\n\n.nellaiappar-temple .ta-center {\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
